import { OptiscanUserViewModelModule_empty } from "../Optiscan.Models/Security.js";
import { DefaultActions_terminate, DefaultActions_Types_UpdateArgs$3, DefaultActions_runUpdate, DefaultActions_create, DefaultActions_Types_ChildModel$2, Child$1 } from "../Common/Terminate.js";
import { Msg, Model, childAbsentStrategy } from "./UserProfileTypes.js";
import { Cmd_map, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { publicAuthApi, securedUserManagementApi } from "../Api.js";
import { success, error } from "../Optiscan.SharedUI/Toast.js";
import { Common_EmailAddress_create_Z721C83C5, Security_AuthErrorMessage_get_describe, Common_EmailAddress_value_Z39D6D3C1, Security_UserInfo$2_toViewModel_Z3F1C2E28 } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { tryTerminate, update as update_1, init as init_1 } from "../EmailChange/EmailChangeState.js";
import { InitArgs } from "../EmailChange/EmailChangeTypes.js";

export function init() {
    return [new Model(OptiscanUserViewModelModule_empty, new DefaultActions_Types_ChildModel$2(new Child$1(1), childAbsentStrategy, "Email Change"), false), Cmd_none()];
}

export function update(token, userInfo, msg, model) {
    let matchValue, emailChange, matchValue_1;
    let pattern_matching_result, exn, maybeUserInfo, setter, e, user, e_1, msg_6;
    if (msg.tag === 1) {
        pattern_matching_result = 1;
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 2;
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 8;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
            maybeUserInfo = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 4;
        setter = msg.fields[0];
    }
    else if (msg.tag === 5) {
        pattern_matching_result = 5;
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 8;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else if (msg.tag === 7) {
        pattern_matching_result = 7;
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 3) {
            pattern_matching_result = 9;
        }
        else {
            pattern_matching_result = 10;
        }
    }
    else if (msg.tag === 9) {
        pattern_matching_result = 11;
        user = msg.fields[0];
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 12;
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 13;
        }
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].chainedMsg.tag === 0) {
            pattern_matching_result = 16;
            msg_6 = msg.fields[0];
        }
        else {
            pattern_matching_result = 15;
        }
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 17;
    }
    else {
        pattern_matching_result = 0;
        exn = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(exn)));
            return [model, Cmd_none()];
        }
        case 1: {
            return [model, Cmd_OfFunc_result(new Msg(2))];
        }
        case 2: {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedUserManagementApi(token).getCurrentUserInfo, void 0, (arg0) => (new Msg(3, arg0)), (arg0_1) => (new Msg(0, arg0_1)))];
        }
        case 3: {
            if (maybeUserInfo == null) {
                return [model, error("Unable to find User Info")];
            }
            else {
                return [new Model(Security_UserInfo$2_toViewModel_Z3F1C2E28(maybeUserInfo), model.EmailChange, false), Cmd_none()];
            }
        }
        case 4: {
            return [new Model(setter(model.UserInfo), model.EmailChange, true), Cmd_none()];
        }
        case 5: {
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedUserManagementApi(token).editUser, model.UserInfo, (arg0_2) => (new Msg(6, arg0_2)), (arg0_3) => (new Msg(0, arg0_3)))];
        }
        case 6: {
            return [model, Cmd_batch(ofArray([success("Successfully updated Profile Information"), Cmd_OfFunc_result(new Msg(2))]))];
        }
        case 7: {
            return [model, Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, publicAuthApi.requestPasswordResetTokenEmail, Common_EmailAddress_value_Z39D6D3C1(userInfo.email), (arg0_4) => (new Msg(0, arg0_4))), success("An email has been sent to the specified address")]))];
        }
        case 8: {
            return [model, error(Security_AuthErrorMessage_get_describe()(e))];
        }
        case 9: {
            return [model, Cmd_OfFunc_result(new Msg(2))];
        }
        case 10: {
            return [model, Cmd_none()];
        }
        case 11: {
            const createdEmailChange = init_1(new InitArgs(user));
            const patternInput = DefaultActions_create(createdEmailChange[0], createdEmailChange[1], model.EmailChange);
            return [new Model(model.UserInfo, patternInput[0], model.HasChanged), Cmd_map((arg0_5) => (new Msg(12, arg0_5)), patternInput[1])];
        }
        case 12: {
            return [model, (matchValue = model.EmailChange.child, (matchValue.tag === 0) ? ((emailChange = matchValue.fields[0], (matchValue_1 = [Common_EmailAddress_create_Z721C83C5(emailChange.CurrentEmail), Common_EmailAddress_create_Z721C83C5(emailChange.NewEmail)], (matchValue_1[0].tag === 1) ? error("The users current email is invalid. Please try reloading the page") : ((matchValue_1[1].tag === 1) ? error("The new email is invalid. Please enter a valid email address.") : Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, publicAuthApi.requestEmailChangeTokenEmail, [emailChange.CurrentEmail, emailChange.NewEmail], (arg0_6) => (new Msg(11, arg0_6)), (arg0_7) => (new Msg(0, arg0_7))))))) : error("An email change component has not been created yet"))];
        }
        case 13: {
            return [model, Cmd_batch(ofArray([success("A confirmation email has been sent to the users current email address"), Cmd_OfFunc_result(new Msg(13))]))];
        }
        case 14: {
            return [model, error(Security_AuthErrorMessage_get_describe()(e_1))];
        }
        case 15: {
            return [model, Cmd_none()];
        }
        case 16: {
            const patternInput_1 = DefaultActions_runUpdate(new DefaultActions_Types_UpdateArgs$3((msg_7, model_2) => {
                if (msg_7.tag === 1) {
                    return [model_2, Cmd_none()];
                }
                else {
                    return update_1(void 0, msg_7.fields[0], model_2);
                }
            }, model), model.EmailChange, msg_6);
            return [new Model(model.UserInfo, patternInput_1[0], model.HasChanged), Cmd_map((arg0_8) => (new Msg(12, arg0_8)), patternInput_1[1])];
        }
        case 17: {
            const patternInput_2 = DefaultActions_terminate(tryTerminate, model.EmailChange);
            return [new Model(model.UserInfo, patternInput_2[0], model.HasChanged), Cmd_batch(ofArray([Cmd_map((arg0_9) => (new Msg(12, arg0_9)), patternInput_2[1]), Cmd_OfFunc_result(new Msg(2))]))];
        }
    }
}

