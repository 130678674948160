import { update, init } from "../TelepathologyCommon/TelepathologyState.js";
import { bind, map, toArray, some, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { PatientViewModel_get_empty } from "../Optiscan.Models/View/Patient.js";
import { TelepathologyApi, TelepathologyDependency } from "../TelepathologyCommon/TelepathologyTypes.js";
import { Cmd_none, Cmd_OfPromise_either, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Internal_BranchMsgChild$2, Internal_BranchModelChild$2, Internal_StateTreeRoot$6, Internal_StateTreeModel$1, NavigationMsg$1, StateTreeNode$6, StateTreeMsg$4 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { view } from "../TelepathologyCommon/TelepathologyView.js";
import { guestApi } from "../Api.js";
import { SignInMsg, SignInModel } from "./TelepathologyGuestTypes.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { tail, head, isEmpty, ofArray, singleton as singleton_1, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { empty as empty_1, append, singleton, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { label } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Common_InputElement_ref } from "../Common/Common.js";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { GuestSignInRequest } from "../Optiscan.Models/View/GuestLink.js";
import { error as error_2 } from "../Optiscan.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { updateGlobalConfig, mkCredentials } from "../Common/AwsCommon.js";
import { ofList } from "../fable_modules/fable-library.3.7.12/Map.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { Internal_combineChildIndexPathFromKeyPathMap, NavigationMsg$1_destructiveCast_Z33BEA92B, defaultConfig, StateTree$3_$ctor_4E60E31B } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Cmd_map as Cmd_map_1, Cmd_batch, Cmd_none as Cmd_none_1 } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { equals, partialApply } from "../fable_modules/fable-library.3.7.12/Util.js";

export function telepathologyNode() {
    return new StateTreeNode$6((deps) => {
        const patternInput = init(new TelepathologyDependency(defaultArg(deps.patient, PatientViewModel_get_empty()), deps.session));
        return [patternInput[0], Cmd_map((arg0) => (new StateTreeMsg$4(0, arg0)), patternInput[1])];
    }, (model_1, dispatch) => view(model_1, (arg) => {
        dispatch(new StateTreeMsg$4(0, arg));
    }, [], []), (msg, token, deps_1, model_2) => {
        const patternInput_1 = update(msg, new TelepathologyApi(guestApi(deps_1.authToken).flagSessionImage, guestApi(deps_1.authToken).getCurrentAnatomicalRegion), deps_1.credentials, deps_1.kvsConfiguration, model_2);
        return [patternInput_1[0], Cmd_map((arg0_2) => (new StateTreeMsg$4(0, arg0_2)), patternInput_1[1])];
    });
}

export function signinNode() {
    return new StateTreeNode$6((_arg1) => [new SignInModel(void 0, void 0, void 0, void 0, void 0, void 0, false, void 0, "", ""), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => content(empty(), toList(delay(() => {
        let props, children_2;
        return model.loading ? singleton((props = [new HTMLAttr(64, "is-loading"), ["style", {
            marginTop: "5em",
        }]], react.createElement("div", keyValueList(props, 1)))) : singleton(container(empty(), ofArray([h3(empty())(singleton_1("Guest Login")), (children_2 = [div(empty(), ofArray([label(empty(), singleton_1("Name")), div_1(empty(), singleton_1(input(ofArray([new Option(1, new IInputType(0)), new Option(14, (e) => {
            Common_InputElement_ref(model.name, e);
        }), new Option(13, (e_1) => {
            dispatch(new StateTreeMsg$4(0, new SignInMsg(8, e_1.currentTarget.value)));
        })]))))])), div(empty(), ofArray([label(empty(), singleton_1("Password")), div_1(empty(), singleton_1(input(ofArray([new Option(1, new IInputType(1)), new Option(15, singleton_1(new HTMLAttr(54, "current-password"))), new Option(14, (e_2) => {
            Common_InputElement_ref(model.password, e_2);
        }), new Option(13, (e_3) => {
            dispatch(new StateTreeMsg$4(0, new SignInMsg(9, e_3.currentTarget.value)));
        })]))))])), button(singleton_1(new Option_1(18, (_arg2) => {
            dispatch(new StateTreeMsg$4(0, new SignInMsg(2)));
        })), singleton_1("Join Session!"))], react.createElement("form", {}, ...children_2))])));
    }))), (msg_2, code, deps, model_1) => {
        if (msg_2.tag === 2) {
            return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, true, model_1.authToken, model_1.name, ""), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, guestApi(model_1.authToken).signIn, new GuestSignInRequest(code, model_1.name, model_1.password), (arg) => (new StateTreeMsg$4(0, new SignInMsg(4, arg))), (arg_1) => (new StateTreeMsg$4(0, new SignInMsg(0, arg_1))))];
        }
        else if (msg_2.tag === 4) {
            if (msg_2.fields[0].tag === 0) {
                return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, model_1.loading, msg_2.fields[0].fields[0], model_1.name, model_1.password), Cmd_OfAsyncWith_either((x_1) => {
                    Cmd_OfAsync_start(x_1);
                }, guestApi(msg_2.fields[0].fields[0]).getKvsConfiguration, void 0, (arg_3) => (new StateTreeMsg$4(0, new SignInMsg(3, arg_3))), (arg_4) => (new StateTreeMsg$4(0, new SignInMsg(0, arg_4))))];
            }
            else {
                console.error(some(`Error: ${msg_2.fields[0].fields[0]}`));
                return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, false, model_1.authToken, model_1.name, model_1.password), error_2(`Error: ${ErrorMessage_get_describe()(msg_2.fields[0].fields[0])}`)];
            }
        }
        else if (msg_2.tag === 3) {
            return [new SignInModel(model_1.identityConfiguration, model_1.credentials, msg_2.fields[0], model_1.awsToken, model_1.patient, model_1.session, model_1.loading, model_1.authToken, model_1.name, model_1.password), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, guestApi(model_1.authToken).getSessionInformation, void 0, (arg_6) => (new StateTreeMsg$4(0, new SignInMsg(1, arg_6))), (arg_7) => (new StateTreeMsg$4(0, new SignInMsg(0, arg_7))))];
        }
        else if (msg_2.tag === 1) {
            if (msg_2.fields[0].tag === 0) {
                return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, msg_2.fields[0].fields[0][0], msg_2.fields[0].fields[0][1], model_1.loading, model_1.authToken, model_1.name, model_1.password), Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, guestApi(model_1.authToken).getIdentityClientConfiguration, void 0, (arg_9) => (new StateTreeMsg$4(0, new SignInMsg(5, arg_9))), (arg_10) => (new StateTreeMsg$4(0, new SignInMsg(0, arg_10))))];
            }
            else {
                console.error(some(`Error: ${msg_2.fields[0].fields[0]}`));
                return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, false, model_1.authToken, model_1.name, model_1.password), error_2(`Failed to get session information. Error: ${ErrorMessage_get_describe()(msg_2.fields[0].fields[0])}`)];
            }
        }
        else if (msg_2.tag === 5) {
            return [new SignInModel(msg_2.fields[0], model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, model_1.loading, model_1.authToken, model_1.name, model_1.password), Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, guestApi(model_1.authToken).getIdentity, void 0, (arg_12) => (new StateTreeMsg$4(0, new SignInMsg(6, arg_12))), (arg_13) => (new StateTreeMsg$4(0, new SignInMsg(0, arg_13))))];
        }
        else if (msg_2.tag === 6) {
            const matchValue_1 = model_1.identityConfiguration;
            if (matchValue_1 != null) {
                const config_2 = matchValue_1;
                if (msg_2.fields[0].tag === 1) {
                    const err_2 = msg_2.fields[0].fields[0];
                    console.error(some(`Error: ${err_2}`));
                    return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, false, model_1.authToken, model_1.name, model_1.password), error_2(`Failed to get permission. Error: ${ErrorMessage_get_describe()(err_2)}`)];
                }
                else {
                    const result = msg_2.fields[0].fields[0];
                    const credentials = mkCredentials(config_2.GuestIdentityPoolId, result.IdentityId, config_2.Region, ofList(singleton_1([config_2.GuestAuthority, result.Token])));
                    updateGlobalConfig(config_2.Region, credentials);
                    return [new SignInModel(model_1.identityConfiguration, credentials, model_1.kvsConfiguration, result, model_1.patient, model_1.session, model_1.loading, model_1.authToken, model_1.name, model_1.password), Cmd_OfPromise_either((credentials_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (credentials_1.getPromise().then(() => (Promise.resolve(credentials_1)))))), credentials, (arg_15) => (new StateTreeMsg$4(0, new SignInMsg(7, arg_15))), (arg_16) => (new StateTreeMsg$4(0, new SignInMsg(0, arg_16))))];
                }
            }
            else {
                return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, false, model_1.authToken, model_1.name, model_1.password), Cmd_none()];
            }
        }
        else if (msg_2.tag === 7) {
            return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "Telepathology")))];
        }
        else if (msg_2.tag === 8) {
            return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, model_1.loading, model_1.authToken, msg_2.fields[0], model_1.password), Cmd_none()];
        }
        else if (msg_2.tag === 9) {
            return [new SignInModel(model_1.identityConfiguration, model_1.credentials, model_1.kvsConfiguration, model_1.awsToken, model_1.patient, model_1.session, model_1.loading, model_1.authToken, model_1.name, msg_2.fields[0]), Cmd_none()];
        }
        else {
            console.error(some(`Error: ${msg_2.fields[0]}`));
            return [model_1, Cmd_none()];
        }
    });
}

export const stateTree = StateTree$3_$ctor_4E60E31B((() => {
    const current_3 = signinNode();
    let child;
    const current = telepathologyNode();
    const config = defaultConfig();
    const maybeAutoMessage = config.MaybeSendMessageOnNavigation;
    const key_2 = "Telepathology";
    const current_2 = current;
    child = (new Internal_StateTreeRoot$6((_arg2, deps, maybeCachedModel, _arg1) => {
        const matchValue = [maybeCachedModel, config.ReInitOnNavigation, maybeAutoMessage];
        let pattern_matching_result, model, model_1, msg;
        if (matchValue[0] != null) {
            if (matchValue[1]) {
                pattern_matching_result = 2;
            }
            else if (matchValue[2] != null) {
                pattern_matching_result = 1;
                model_1 = matchValue[0];
                msg = matchValue[2];
            }
            else {
                pattern_matching_result = 0;
                model = matchValue[0];
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model, Cmd_none_1()];
            }
            case 1: {
                return [model_1, Cmd_OfFunc_result(msg)];
            }
            case 2: {
                const patternInput = current_2.Init(deps);
                return [new Internal_StateTreeModel$1(patternInput[0], false), Cmd_batch(toList(delay(() => append(singleton(patternInput[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage))))))))];
            }
        }
    }, (_arg3, dispatch, model_2) => current_2.View(model_2.NodeModel, dispatch), (_arg4, msg_3_1, pass, deps_1, model_3) => {
        switch (msg_3_1.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_1.fields[0]}`));
                return [model_3, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3, Cmd_none_1(), void 0, some(msg_3_1.fields[0])];
            }
            case 4: {
                return [model_3, Cmd_none_1(), msg_3_1.fields[0], void 0];
            }
            default: {
                const patternInput_1 = current_2.Update(msg_3_1.fields[0], pass, deps_1, model_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1[0], model_3.Initialized), patternInput_1[1], void 0, void 0];
            }
        }
    }, (msg_4, deps_2, model_4) => {
        const patternInput_2 = config.UpdateInbound(msg_4, deps_2, model_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2[0], model_4.Initialized), patternInput_2[1]];
    }, key_2, ofList(singleton_1([singleton_1(key_2), singleton_1(0)]))));
    const config_1 = defaultConfig();
    const keepCachedChildren_1 = config_1.KeepCachedChildren;
    const child_2 = child;
    let current_7;
    const config_2 = config_1;
    const maybeAutoMessage_1 = config_2.MaybeSendMessageOnNavigation;
    const key_6 = "SignIn";
    const current_6 = current_3;
    current_7 = (new Internal_StateTreeRoot$6((_arg2_1, deps_3, maybeCachedModel_1, _arg1_1) => {
        const matchValue_1 = [maybeCachedModel_1, config_2.ReInitOnNavigation, maybeAutoMessage_1];
        let pattern_matching_result_1, model_5, model_1_1, msg_5;
        if (matchValue_1[0] != null) {
            if (matchValue_1[1]) {
                pattern_matching_result_1 = 2;
            }
            else if (matchValue_1[2] != null) {
                pattern_matching_result_1 = 1;
                model_1_1 = matchValue_1[0];
                msg_5 = matchValue_1[2];
            }
            else {
                pattern_matching_result_1 = 0;
                model_5 = matchValue_1[0];
            }
        }
        else {
            pattern_matching_result_1 = 2;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return [model_5, Cmd_none_1()];
            }
            case 1: {
                return [model_1_1, Cmd_OfFunc_result(msg_5)];
            }
            case 2: {
                const patternInput_3 = current_6.Init(deps_3);
                return [new Internal_StateTreeModel$1(patternInput_3[0], false), Cmd_batch(toList(delay(() => append(singleton(patternInput_3[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_1))))))))];
            }
        }
    }, (_arg3_1, dispatch_1, model_2_1) => current_6.View(model_2_1.NodeModel, dispatch_1), (_arg4_1, msg_3_2, pass_1, deps_1_1, model_3_1) => {
        switch (msg_3_2.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_2.fields[0]}`));
                return [model_3_1, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_1.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_1, Cmd_none_1(), void 0, some(msg_3_2.fields[0])];
            }
            case 4: {
                return [model_3_1, Cmd_none_1(), msg_3_2.fields[0], void 0];
            }
            default: {
                const patternInput_1_1 = current_6.Update(msg_3_2.fields[0], pass_1, deps_1_1, model_3_1.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_1[0], model_3_1.Initialized), patternInput_1_1[1], void 0, void 0];
            }
        }
    }, (msg_4_1, deps_2_1, model_4_1) => {
        const patternInput_2_1 = config_2.UpdateInbound(msg_4_1, deps_2_1, model_4_1.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_1[0], model_4_1.Initialized), patternInput_2_1[1]];
    }, key_6, ofList(singleton_1([singleton_1(key_6), singleton_1(0)]))));
    return new Internal_StateTreeRoot$6((indexPath, deps_4, maybeCachedModel_2, _arg1_2) => {
        const maybeCachedCurrentModel = bind((cachedModel) => cachedModel.MaybeCurrentModel, maybeCachedModel_2);
        const maybeCachedChildModel = bind((cachedModel_1) => cachedModel_1.MaybeChildModel, maybeCachedModel_2);
        const matchValue_2 = [indexPath, maybeCachedCurrentModel];
        let pattern_matching_result_2, currentModel_1, xs;
        if (!isEmpty(matchValue_2[0])) {
            if (head(matchValue_2[0]) === 0) {
                if (isEmpty(tail(matchValue_2[0]))) {
                    pattern_matching_result_2 = 0;
                }
                else if (matchValue_2[1] != null) {
                    pattern_matching_result_2 = 1;
                    currentModel_1 = matchValue_2[1];
                    xs = tail(matchValue_2[0]);
                }
                else {
                    pattern_matching_result_2 = 0;
                }
            }
            else {
                pattern_matching_result_2 = 2;
            }
        }
        else {
            pattern_matching_result_2 = 2;
        }
        switch (pattern_matching_result_2) {
            case 0: {
                const patternInput_4 = current_7.Init(indexPath, deps_4, maybeCachedCurrentModel, false);
                return [new Internal_BranchModelChild$2(patternInput_4[0], keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map_1((arg0) => (new Internal_BranchMsgChild$2(0, arg0)), patternInput_4[1])];
            }
            case 1: {
                const patternInput_1_2 = child_2.Init(xs, currentModel_1.NodeModel, maybeCachedChildModel, keepCachedChildren_1);
                return [new Internal_BranchModelChild$2(currentModel_1, patternInput_1_2[0]), Cmd_map_1((arg0_2) => (new Internal_BranchMsgChild$2(1, arg0_2)), patternInput_1_2[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_2, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1, dispatch_2, model_6) => {
        let pattern_matching_result_3, xs_1;
        if (!isEmpty(indexPath_1)) {
            if (head(indexPath_1) === 0) {
                if (isEmpty(tail(indexPath_1))) {
                    pattern_matching_result_3 = 0;
                }
                else {
                    pattern_matching_result_3 = 1;
                    xs_1 = tail(indexPath_1);
                }
            }
            else {
                pattern_matching_result_3 = 2;
            }
        }
        else {
            pattern_matching_result_3 = 2;
        }
        switch (pattern_matching_result_3) {
            case 0: {
                return defaultArg(map(partialApply(1, current_7.View, [indexPath_1, (arg) => {
                    dispatch_2(new Internal_BranchMsgChild$2(0, arg));
                }]), model_6.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children = toList(delay(() => append(config_1.VisibleToChildren ? singleton(defaultArg(map(partialApply(1, current_7.View, [indexPath_1, (arg_1) => {
                    dispatch_2(new Internal_BranchMsgChild$2(0, arg_1));
                }]), model_6.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton(defaultArg(map(partialApply(1, child_2.View, [xs_1, (arg_2) => {
                    dispatch_2(new Internal_BranchMsgChild$2(1, arg_2));
                }]), model_6.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2, branchMsg, pass_2, deps_1_2, model_1_2) => {
        let newNodeModel;
        const matchValue_1_1 = [branchMsg, indexPath_2];
        let pattern_matching_result_4, msg_8, xs_2, msg_1_2, xs_3;
        if (matchValue_1_1[0].tag === 1) {
            if (!isEmpty(matchValue_1_1[1])) {
                if (head(matchValue_1_1[1]) === 0) {
                    pattern_matching_result_4 = 1;
                    msg_1_2 = matchValue_1_1[0].fields[0];
                    xs_3 = tail(matchValue_1_1[1]);
                }
                else {
                    pattern_matching_result_4 = 2;
                }
            }
            else {
                pattern_matching_result_4 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_1[1])) {
            if (head(matchValue_1_1[1]) === 0) {
                pattern_matching_result_4 = 0;
                msg_8 = matchValue_1_1[0].fields[0];
                xs_2 = tail(matchValue_1_1[1]);
            }
            else {
                pattern_matching_result_4 = 2;
            }
        }
        else {
            pattern_matching_result_4 = 2;
        }
        switch (pattern_matching_result_4) {
            case 0: {
                const matchValue_2_1 = model_1_2.MaybeCurrentModel;
                if (matchValue_2_1 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_2, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2 = matchValue_2_1;
                    const patternInput_2_2 = current_7.Update(indexPath_2, msg_8, pass_2, deps_1_2, currentModel_2);
                    const newCurrentModel = patternInput_2_2[0];
                    const maybeOutboundMsg = patternInput_2_2[3];
                    const maybeNavMsg = patternInput_2_2[2];
                    const currentCmd_1 = patternInput_2_2[1];
                    let pattern_matching_result_5;
                    if (msg_8.tag === 2) {
                        if (!equals(xs_2, empty())) {
                            pattern_matching_result_5 = 0;
                        }
                        else {
                            pattern_matching_result_5 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_5 = 1;
                    }
                    switch (pattern_matching_result_5) {
                        case 0: {
                            const patternInput_3_1 = child_2.Init(xs_2, currentModel_2.NodeModel, model_1_2.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel, patternInput_3_1[0]), Cmd_batch(ofArray([Cmd_map_1((arg0_8) => (new Internal_BranchMsgChild$2(0, arg0_8)), currentCmd_1), Cmd_map_1((arg0_9) => (new Internal_BranchMsgChild$2(1, arg0_9)), patternInput_3_1[1])])), maybeNavMsg, maybeOutboundMsg];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel, model_1_2.MaybeChildModel), Cmd_map_1((arg0_11) => (new Internal_BranchMsgChild$2(0, arg0_11)), currentCmd_1), maybeNavMsg, maybeOutboundMsg];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3 = [model_1_2.MaybeCurrentModel, model_1_2.MaybeChildModel];
                let pattern_matching_result_6, childModel_1, currentModel_3;
                if (matchValue_3[0] != null) {
                    if (matchValue_3[1] != null) {
                        pattern_matching_result_6 = 0;
                        childModel_1 = matchValue_3[1];
                        currentModel_3 = matchValue_3[0];
                    }
                    else {
                        pattern_matching_result_6 = 1;
                    }
                }
                else {
                    pattern_matching_result_6 = 1;
                }
                switch (pattern_matching_result_6) {
                    case 0: {
                        const patternInput_4_1 = child_2.Update(xs_3, msg_1_2, pass_2, currentModel_3.NodeModel, childModel_1);
                        const maybeNavMsg_1 = patternInput_4_1[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1 != null) ? ((maybeNavMsg_1.tag === 2) ? ((newNodeModel = maybeNavMsg_1.fields[0], map((model_2_2) => (new Internal_StateTreeModel$1(newNodeModel, model_2_2.Initialized)), model_1_2.MaybeCurrentModel))) : model_1_2.MaybeCurrentModel) : model_1_2.MaybeCurrentModel, patternInput_4_1[0]), Cmd_map_1((arg0_13) => (new Internal_BranchMsgChild$2(1, arg0_13)), patternInput_4_1[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1), patternInput_4_1[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_2, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_2, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_3, deps_2_2, model_3_2) => {
        let patternInput_6;
        const matchValue_4 = model_3_2.MaybeCurrentModel;
        if (matchValue_4 == null) {
            patternInput_6 = [model_3_2.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5 = current_7.UpdateInbound(msg_2_3, deps_2_2, matchValue_4);
            patternInput_6 = [patternInput_5[0], patternInput_5[1]];
        }
        let patternInput_8;
        const matchValue_5 = [model_3_2.MaybeChildModel, model_3_2.MaybeCurrentModel];
        let pattern_matching_result_7, childModel_2, currentModel_5;
        if (matchValue_5[0] != null) {
            if (matchValue_5[1] != null) {
                pattern_matching_result_7 = 0;
                childModel_2 = matchValue_5[0];
                currentModel_5 = matchValue_5[1];
            }
            else {
                pattern_matching_result_7 = 1;
            }
        }
        else {
            pattern_matching_result_7 = 1;
        }
        switch (pattern_matching_result_7) {
            case 0: {
                const patternInput_7 = child_2.UpdateInbound(msg_2_3, currentModel_5.NodeModel, childModel_2);
                patternInput_8 = [patternInput_7[0], patternInput_7[1]];
                break;
            }
            case 1: {
                patternInput_8 = [model_3_2.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6[0], patternInput_8[0]), Cmd_batch(ofArray([Cmd_map_1((arg0_14) => (new Internal_BranchMsgChild$2(0, arg0_14)), patternInput_6[1]), Cmd_map_1((arg0_15) => (new Internal_BranchMsgChild$2(1, arg0_15)), patternInput_8[1])]))];
    }, current_7.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_7.LastKey, child_2.IndexPathFromKeyPath, current_7.IndexPathFromKeyPath));
})());

