import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { sortBy, ofArray, singleton as singleton_1, map, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";
import { content as content_1, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Msg } from "./DashboardTypes.js";
import { FormField_labeledField } from "../Optiscan.SharedUI/Forms.js";
import { toString } from "../fable_modules/fable-library.3.7.12/Types.js";
import { compare } from "../fable_modules/fable-library.3.7.12/Date.js";

export function view(model, dispatch) {
    return content(empty(), toList(delay(() => {
        let children_4;
        return append(singleton((children_4 = [react.createElement("h1", {}, "Dashboard"), react.createElement("p", {}, "Welcome to your InVivage Pathology Portal")], react.createElement("div", {}, ...children_4))), delay(() => {
            let children_8;
            return model.loadingSessions ? singleton(react.createElement("div", {
                className: "is-loading",
            })) : singleton((children_8 = toList(delay(() => map((scheduledSession) => card(singleton_1(new Common_GenericOption(0, "embed-card")), ofArray([header(empty(), ofArray([Header_title(empty(), singleton_1("Live Consultation")), Header_icon(empty(), singleton_1(button(ofArray([new Option(18, (_arg1) => {
                dispatch(new Msg(2, scheduledSession));
            }), new Option(16, (scheduledSession.sessionState === 0) ? true : (scheduledSession.sessionState === 3))]), singleton_1("Join Live Session"))))])), content_1(empty(), ofArray([FormField_labeledField("ScheduledSession", "Session Date/Time", toString(scheduledSession.startTime), []), FormField_labeledField("ScheduledSession", "Session Name", scheduledSession.sessionName, []), FormField_labeledField("ScheduledSession", "Site Name", scheduledSession.siteName, []), FormField_labeledField("ScheduledSession", "Patient ID", scheduledSession.patientId, [])]))])), sortBy((session) => session.startTime, model.scheduledSessions, {
                Compare: compare,
            })))), react.createElement("div", {
                className: "scheduled-card-container",
            }, ...children_8)));
        }));
    })));
}

