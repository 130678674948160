import { AsyncResult_map, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotNullOrEmpty } from "../../../fable_modules/Webbler.Models.1.2.6/Validation.fs.js";
import { empty as empty_1, ofArray, item as item_1 } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { PatientNoteEditModel_get_patientNote_, tryGetSessionForNote, getSessionNameOrDefault, PatientNoteEditModel } from "./PatientNotesTypes.js";
import { Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { StateTreeNode$6, StateTreeMsg$4, NavigationMsg$1 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { crudEditUpdate, CrudButton_save, CrudEditMsg$2, CrudButton_cancel, CrudButton_edit, crudModal } from "../../../Optiscan.SharedUI/Crud.js";
import { FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_deselectable, FormField_onChangeSet, FormField_textArea, FormField_labeledField } from "../../../Optiscan.SharedUI/Forms.js";
import { Option } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { toString } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { Common_Name_display_4140EA02 } from "../../../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { empty, singleton, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { Permission, isAllowed } from "../../../Optiscan.Models/Security.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { uncurry } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Optic_Set, Optic_Set_op_HatEquals_2170E4F5, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { PatientNoteViewModel, PatientNoteViewModel_get_empty, PatientNoteViewModel_get_maybeSessionId_, PatientNoteViewModel_get_note_ } from "../../../Optiscan.Models/View/PatientNote.js";
import { Functions_flip } from "../../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { SessionViewModel_get_sessionId_, SessionViewModel_get_sessionName_ } from "../../../Optiscan.Models/View/Session.js";
import { map } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { securedApi } from "../../../Api.js";
import { Common_eraseExceptionToError } from "../../../Common/Common.js";
import { ErrorMessage_get_describe } from "../../../Optiscan.Models/ErrorMessage.js";

export function canSaveEditedNote(note) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Note cannot be null or empty", note.note), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result))));
}

export function noteDetailState(currentUser) {
    return new StateTreeNode$6((deps) => {
        const matchValue = deps.patientNotesCrudModel.MaybeSelected;
        return (matchValue == null) ? [new PatientNoteEditModel(item_1(0, deps.patientNotesCrudModel.Items), deps.sessions), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))] : [new PatientNoteEditModel(matchValue, deps.sessions), Cmd_OfFunc_result(new StateTreeMsg$4(2))];
    }, (model, dispatch) => crudModal("Details", dispatch, ofArray([FormField_labeledField("PatientNote", "Note", model.patientNote.note, [new Option(10, "note-field")]), FormField_labeledField("PatientNote", "Date Added", toString(model.patientNote.dateCreated), []), FormField_labeledField("PatientNote", "Created By", Common_Name_display_4140EA02(model.patientNote.createdByName), []), FormField_labeledField("PatientNote", "Session", getSessionNameOrDefault(tryGetSessionForNote(model.patientNote, model.sessions)), [])]), toList(delay(() => append(isAllowed(new Permission(34))(currentUser.roles) ? singleton(CrudButton_edit(dispatch)) : empty(), delay(() => singleton(CrudButton_cancel(dispatch))))))), (msg_4, tupledArg, deps_1, model_1) => [model_1, Cmd_none()]);
}

export function noteEditState() {
    return new StateTreeNode$6((deps) => [new PatientNoteEditModel(deps.patientNote, deps.sessions), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => crudModal("Edit", dispatch, toList(delay(() => {
        let f, optic, l;
        const setter = (arg_1) => {
            dispatch(new StateTreeMsg$4(0, new CrudEditMsg$2(0, arg_1)));
        };
        return append(singleton(FormField_textArea("Patient Note", model.patientNote.note, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = ((l = PatientNoteEditModel_get_patientNote_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), PatientNoteViewModel_get_note_())(l))), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "PatientNote", "Note")([])), delay(() => {
            let optic_2, f1_3, f1_2, mapping, optic_4, f2_2, optic_6, l_2;
            return singleton(FormField_ReactSelect_deselectable(model.sessions, [], (optic_2 = SessionViewModel_get_sessionName_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target)), tryGetSessionForNote(model.patientNote, model.sessions), new FormField_ReactSelect_CallbackSingle$1(0, (f1_3 = ((f1_2 = ((mapping = ((optic_4 = SessionViewModel_get_sessionId_(), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_4)(target_2))), (option) => map(mapping, option))), (f2_2 = ((optic_6 = ((l_2 = PatientNoteEditModel_get_patientNote_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), PatientNoteViewModel_get_maybeSessionId_())(l_2))), (value_2) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_6)(value_2))), (arg_2) => f2_2(f1_2(arg_2))))), (arg_3) => {
                setter(f1_3(arg_3));
            })), "Session", "Note", []));
        }));
    })), ofArray([CrudButton_save((x) => x, canSaveEditedNote(model.patientNote), dispatch), CrudButton_cancel(dispatch)])), (msg_2, tupledArg, deps_1, newModel) => crudEditUpdate((x_1) => x_1, (model_1) => securedApi(tupledArg[0]).updateNote(model_1.patientNote), Common_eraseExceptionToError, "Note edited.", ErrorMessage_get_describe(), msg_2, newModel));
}

export function noteCreateState(currentUser) {
    return new StateTreeNode$6((deps) => [new PatientNoteEditModel(PatientNoteViewModel_get_empty(), deps.sessions), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => crudModal("Create", dispatch, toList(delay(() => {
        let f, optic, l;
        const setter = (arg_1) => {
            dispatch(new StateTreeMsg$4(0, new CrudEditMsg$2(0, arg_1)));
        };
        return append(singleton(FormField_textArea("Patient Note", model.patientNote.note, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = ((l = PatientNoteEditModel_get_patientNote_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), PatientNoteViewModel_get_note_())(l))), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "PatientNote", "Note")([])), delay(() => {
            let optic_2, f1_3, f1_2, mapping, optic_4, f2_2, optic_6, l_2;
            return singleton(FormField_ReactSelect_deselectable(model.sessions, [], (optic_2 = SessionViewModel_get_sessionName_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target)), tryGetSessionForNote(model.patientNote, model.sessions), new FormField_ReactSelect_CallbackSingle$1(0, (f1_3 = ((f1_2 = ((mapping = ((optic_4 = SessionViewModel_get_sessionId_(), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_4)(target_2))), (option) => map(mapping, option))), (f2_2 = ((optic_6 = ((l_2 = PatientNoteEditModel_get_patientNote_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), PatientNoteViewModel_get_maybeSessionId_())(l_2))), (value_2) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_6)(value_2))), (arg_2) => f2_2(f1_2(arg_2))))), (arg_3) => {
                setter(f1_3(arg_3));
            })), "Session", "Note", []));
        }));
    })), ofArray([CrudButton_save((x) => x, canSaveEditedNote(model.patientNote), dispatch), CrudButton_cancel(dispatch)])), (msg_2, tupledArg, deps_1, newModel) => crudEditUpdate((x_1) => x_1, (model_1) => {
        let vm;
        const inputRecord = model_1.patientNote;
        vm = (new PatientNoteViewModel(inputRecord.noteId, deps_1.patient.id, inputRecord.note, inputRecord.dateCreated, currentUser.id, inputRecord.createdByName, inputRecord.maybeSessionId));
        return AsyncResult_map(() => {
        }, securedApi(tupledArg[0]).createNote(vm));
    }, Common_eraseExceptionToError, "Note created.", ErrorMessage_get_describe(), msg_2, newModel));
}

