import { compare, toString } from "../../../fable_modules/fable-library.3.7.12/Date.js";
import { ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemListSortable, Sort_SortableColumn$1, Sort_Compare$1 } from "../../../Optiscan.SharedUI/Sort.js";
import { Common_Name_display_4140EA02 } from "../../../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { comparePrimitives, compare as compare_1 } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { tryGetSessionForNote, getSessionNameOrDefault } from "./PatientNotesTypes.js";
import { empty, singleton, ofArray } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { content, Header_icon, Header_title, header, card } from "../../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Common_GenericOption } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { defaultSearchComponent } from "../../../Optiscan.SharedUI/Search.js";
import { PatientDetailsMsg } from "../PatientDetailsTypes.js";
import { StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Permission, isAllowed } from "../../../Optiscan.Models/Security.js";
import { CrudButton_create } from "../../../Optiscan.SharedUI/Crud.js";
import * as react from "react";

export function notesTableColumns(sessions) {
    return ofArray([new Sort_SortableColumn$1("Date Created", (pnvm) => toString(pnvm.dateCreated, "yyyy-MM-dd HH:mm"), new Sort_Compare$1(0, (pnvm1, pnvm2) => compare(pnvm1.dateCreated, pnvm2.dateCreated))), new Sort_SortableColumn$1("Created By", (pnvm_1) => Common_Name_display_4140EA02(pnvm_1.createdByName), new Sort_Compare$1(0, (pnvm1_1, pnvm2_1) => compare_1(pnvm1_1.createdByName, pnvm2_1.createdByName))), new Sort_SortableColumn$1("Note", (pnvm_2) => pnvm_2.note, new Sort_Compare$1(0, (pnvm1_2, pnvm2_2) => comparePrimitives(pnvm1_2.note, pnvm2_2.note))), new Sort_SortableColumn$1("Session", (pnvm_3) => getSessionNameOrDefault(tryGetSessionForNote(pnvm_3, sessions)), new Sort_Compare$1(0, (pnvm1_3, pnvm2_3) => comparePrimitives(getSessionNameOrDefault(tryGetSessionForNote(pnvm1_3, sessions)), getSessionNameOrDefault(tryGetSessionForNote(pnvm2_3, sessions)))))]);
}

export function notesTableCard(model, dispatch, currentUser) {
    let children;
    return card(ofArray([new Common_GenericOption(0, "embed-card"), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
    }]))]), ofArray([header(empty(), toList(delay(() => append(singleton_1(Header_title(empty(), singleton("Patient Notes"))), delay(() => append(singleton_1(Header_icon(empty(), singleton(defaultSearchComponent("Patient Notes", model.patientNotesCrudModel.SearchInfo, (arg_1) => {
        dispatch(new StateTreeMsg$4(0, new PatientDetailsMsg(10, arg_1)));
    }, (arg_3) => {
        dispatch(new StateTreeMsg$4(0, new PatientDetailsMsg(11, arg_3)));
    })))), delay(() => (isAllowed(new Permission(33))(currentUser.roles) ? singleton_1(Header_icon(empty(), singleton(CrudButton_create("Note", dispatch)))) : empty_1())))))))), content(empty(), singleton((children = [Sort_itemListSortable("Notes", notesTableColumns(model.sessions))((item) => ((_arg1) => {
        dispatch(new StateTreeMsg$4(0, new PatientDetailsMsg(8, item)));
    }))(ListDisplayModification_ListDisplayModification$2_applyListModifications(model.patientNotesCrudModel.ListModifications, model.patientNotesCrudModel.Items))((_arg2) => empty_1())(model.patientNotesCrudModel.SortInfo)((sortInfo) => ((_arg3) => {
        dispatch(new StateTreeMsg$4(0, new PatientDetailsMsg(9, sortInfo)));
    }))], react.createElement("div", {
        className: "table-container",
    }, ...children))))]));
}

