import { Record, Union } from "../fable-library.3.7.12/Types.js";
import { obj_type, int32_type, class_type, record_type, tuple_type, lambda_type, unit_type, option_type, bool_type, union_type, list_type, string_type } from "../fable-library.3.7.12/Reflection.js";
import { Cmd_none } from "../Fable.Elmish.3.1.0/cmd.fs.js";
import { Helpers_nothing } from "../Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { FSharpMap__TryFind, add, FSharpMap__ContainsKey, fold } from "../fable-library.3.7.12/Map.js";
import { empty, tail, head, isEmpty, cons, singleton, append } from "../fable-library.3.7.12/List.js";
import { defaultArg } from "../fable-library.3.7.12/Option.js";

export class NavigationMsg$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NavigateTo", "NavigateToParent", "NavigateToParentWith", "NavigateToFirstAncestor", "NavigateToChild", "NavigateToSibling"];
    }
}

export function NavigationMsg$1$reflection(gen0) {
    return union_type("Webbler.StateTree.Core.StateTree.NavigationMsg`1", [gen0], NavigationMsg$1, () => [[["keyPath", list_type(string_type)]], [], [["dependencies", gen0]], [["key", string_type]], [["key", string_type]], [["key", string_type]]]);
}

export function NavigationMsg$1_destructiveCast_Z33BEA92B(fromMsg) {
    switch (fromMsg.tag) {
        case 1:
        case 2: {
            return new NavigationMsg$1(1);
        }
        case 3: {
            return new NavigationMsg$1(3, fromMsg.fields[0]);
        }
        case 4: {
            return new NavigationMsg$1(4, fromMsg.fields[0]);
        }
        case 5: {
            return new NavigationMsg$1(5, fromMsg.fields[0]);
        }
        default: {
            return new NavigationMsg$1(0, fromMsg.fields[0]);
        }
    }
}

export class StateTreeMsg$4 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NodeMsg", "NodeError", "Initialized", "OutboundMsg", "Navigation"];
    }
}

export function StateTreeMsg$4$reflection(gen0, gen1, gen2, gen3) {
    return union_type("Webbler.StateTree.Core.StateTree.StateTreeMsg`4", [gen0, gen1, gen2, gen3], StateTreeMsg$4, () => [[["Item", gen1]], [["Item", gen3]], [], [["Item", gen2]], [["Item", NavigationMsg$1$reflection(gen0)]]]);
}

export class StateTreeConfig$6 extends Record {
    constructor(VisibleToChildren, ReInitOnNavigation, MaybeSendMessageOnNavigation, KeepCachedChildren, UpdateInbound) {
        super();
        this.VisibleToChildren = VisibleToChildren;
        this.ReInitOnNavigation = ReInitOnNavigation;
        this.MaybeSendMessageOnNavigation = MaybeSendMessageOnNavigation;
        this.KeepCachedChildren = KeepCachedChildren;
        this.UpdateInbound = UpdateInbound;
    }
}

export function StateTreeConfig$6$reflection(gen0, gen1, gen2, gen3, gen4, gen5) {
    return record_type("Webbler.StateTree.Core.StateTree.StateTreeConfig`6", [gen0, gen1, gen2, gen3, gen4, gen5], StateTreeConfig$6, () => [["VisibleToChildren", bool_type], ["ReInitOnNavigation", bool_type], ["MaybeSendMessageOnNavigation", option_type(StateTreeMsg$4$reflection(gen0, gen2, gen4, gen5))], ["KeepCachedChildren", bool_type], ["UpdateInbound", lambda_type(gen3, lambda_type(gen0, lambda_type(gen1, tuple_type(gen1, list_type(lambda_type(lambda_type(StateTreeMsg$4$reflection(gen0, gen2, gen4, gen5), unit_type), unit_type))))))]]);
}

export function defaultConfig() {
    return new StateTreeConfig$6(false, false, void 0, false, (_arg2, _arg1, model) => [model, Cmd_none()]);
}

export class StateTreeNode$6 extends Record {
    constructor(Init, View, Update) {
        super();
        this.Init = Init;
        this.View = View;
        this.Update = Update;
    }
}

export function StateTreeNode$6$reflection(gen0, gen1, gen2, gen3, gen4, gen5) {
    return record_type("Webbler.StateTree.Core.StateTree.StateTreeNode`6", [gen0, gen1, gen2, gen3, gen4, gen5], StateTreeNode$6, () => [["Init", lambda_type(gen0, tuple_type(gen1, list_type(lambda_type(lambda_type(StateTreeMsg$4$reflection(gen0, gen2, gen3, gen4), unit_type), unit_type))))], ["View", lambda_type(gen1, lambda_type(lambda_type(StateTreeMsg$4$reflection(gen0, gen2, gen3, gen4), unit_type), class_type("Fable.React.ReactElement")))], ["Update", lambda_type(gen2, lambda_type(gen5, lambda_type(gen0, lambda_type(gen1, tuple_type(gen1, list_type(lambda_type(lambda_type(StateTreeMsg$4$reflection(gen0, gen2, gen3, gen4), unit_type), unit_type)))))))]]);
}

export function StateTreeNode$6_get_empty() {
    return new StateTreeNode$6(() => [void 0, Cmd_none()], (_arg2, _arg1) => Helpers_nothing, (_arg6, _arg5, _arg4, _arg3) => [void 0, Cmd_none()]);
}

export class Internal_StateTreeModel$1 extends Record {
    constructor(NodeModel, Initialized) {
        super();
        this.NodeModel = NodeModel;
        this.Initialized = Initialized;
    }
}

export function Internal_StateTreeModel$1$reflection(gen0) {
    return record_type("Webbler.StateTree.Core.StateTree.Internal.StateTreeModel`1", [gen0], Internal_StateTreeModel$1, () => [["NodeModel", gen0], ["Initialized", bool_type]]);
}

export class Internal_StateTreeRoot$6 extends Record {
    constructor(Init, View, Update, UpdateInbound, LastKey, IndexPathFromKeyPath) {
        super();
        this.Init = Init;
        this.View = View;
        this.Update = Update;
        this.UpdateInbound = UpdateInbound;
        this.LastKey = LastKey;
        this.IndexPathFromKeyPath = IndexPathFromKeyPath;
    }
}

export function Internal_StateTreeRoot$6$reflection(gen0, gen1, gen2, gen3, gen4, gen5) {
    return record_type("Webbler.StateTree.Core.StateTree.Internal.StateTreeRoot`6", [gen0, gen1, gen2, gen3, gen4, gen5], Internal_StateTreeRoot$6, () => [["Init", lambda_type(list_type(int32_type), lambda_type(gen0, lambda_type(option_type(gen1), lambda_type(bool_type, tuple_type(gen1, list_type(lambda_type(lambda_type(gen2, unit_type), unit_type)))))))], ["View", lambda_type(list_type(int32_type), lambda_type(lambda_type(gen2, unit_type), lambda_type(gen1, class_type("Fable.React.ReactElement"))))], ["Update", lambda_type(list_type(int32_type), lambda_type(gen2, lambda_type(gen5, lambda_type(gen0, lambda_type(gen1, tuple_type(gen1, list_type(lambda_type(lambda_type(gen2, unit_type), unit_type)), option_type(NavigationMsg$1$reflection(gen0)), option_type(gen4)))))))], ["UpdateInbound", lambda_type(gen3, lambda_type(gen0, lambda_type(gen1, tuple_type(gen1, list_type(lambda_type(lambda_type(gen2, unit_type), unit_type))))))], ["LastKey", string_type], ["IndexPathFromKeyPath", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [list_type(string_type), list_type(int32_type)])]]);
}

export class Internal_BranchModelChild$2 extends Record {
    constructor(MaybeCurrentModel, MaybeChildModel) {
        super();
        this.MaybeCurrentModel = MaybeCurrentModel;
        this.MaybeChildModel = MaybeChildModel;
    }
}

export function Internal_BranchModelChild$2$reflection(gen0, gen1) {
    return record_type("Webbler.StateTree.Core.StateTree.Internal.BranchModelChild`2", [gen0, gen1], Internal_BranchModelChild$2, () => [["MaybeCurrentModel", option_type(gen0)], ["MaybeChildModel", option_type(gen1)]]);
}

export class Internal_BranchMsgChild$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CurrentMsgC", "ChildMsgC"];
    }
}

export function Internal_BranchMsgChild$2$reflection(gen0, gen1) {
    return union_type("Webbler.StateTree.Core.StateTree.Internal.BranchMsgChild`2", [gen0, gen1], Internal_BranchMsgChild$2, () => [[["Item", gen0]], [["Item", gen1]]]);
}

export class Internal_BranchModelSibling$2 extends Record {
    constructor(MaybeCurrentModel, MaybeSiblingModel) {
        super();
        this.MaybeCurrentModel = MaybeCurrentModel;
        this.MaybeSiblingModel = MaybeSiblingModel;
    }
}

export function Internal_BranchModelSibling$2$reflection(gen0, gen1) {
    return record_type("Webbler.StateTree.Core.StateTree.Internal.BranchModelSibling`2", [gen0, gen1], Internal_BranchModelSibling$2, () => [["MaybeCurrentModel", option_type(gen0)], ["MaybeSiblingModel", option_type(gen1)]]);
}

export class Internal_BranchMsgSibling$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CurrentMsgS", "SiblingMsgS"];
    }
}

export function Internal_BranchMsgSibling$2$reflection(gen0, gen1) {
    return union_type("Webbler.StateTree.Core.StateTree.Internal.BranchMsgSibling`2", [gen0, gen1], Internal_BranchMsgSibling$2, () => [[["Item", gen0]], [["Item", gen1]]]);
}

export class Internal_BranchModelChildAndSibling$3 extends Record {
    constructor(MaybeCurrentModel, MaybeSiblingModel, MaybeChildModel) {
        super();
        this.MaybeCurrentModel = MaybeCurrentModel;
        this.MaybeSiblingModel = MaybeSiblingModel;
        this.MaybeChildModel = MaybeChildModel;
    }
}

export function Internal_BranchModelChildAndSibling$3$reflection(gen0, gen1, gen2) {
    return record_type("Webbler.StateTree.Core.StateTree.Internal.BranchModelChildAndSibling`3", [gen0, gen1, gen2], Internal_BranchModelChildAndSibling$3, () => [["MaybeCurrentModel", option_type(gen0)], ["MaybeSiblingModel", option_type(gen2)], ["MaybeChildModel", option_type(gen1)]]);
}

export class Internal_BranchMsgChildAndSibling$3 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CurrentMsgCS", "SiblingMsgCS", "ChildMsgCS"];
    }
}

export function Internal_BranchMsgChildAndSibling$3$reflection(gen0, gen1, gen2) {
    return union_type("Webbler.StateTree.Core.StateTree.Internal.BranchMsgChildAndSibling`3", [gen0, gen1, gen2], Internal_BranchMsgChildAndSibling$3, () => [[["Item", gen0]], [["Item", gen2]], [["Item", gen1]]]);
}

export function Internal_combineChildIndexPathFromKeyPathMap(currentKey, childMap, currentMap) {
    return fold((newMap, key, indexPath) => {
        if (FSharpMap__ContainsKey(newMap, key)) {
            return newMap;
        }
        else {
            return add(append(key, singleton(currentKey)), cons(0, indexPath), newMap);
        }
    }, currentMap, childMap);
}

export function Internal_combineSiblingIndexPathFromKeyPathMap(siblingMap, currentMap) {
    return fold((newMap, key, indexPath) => {
        if (FSharpMap__ContainsKey(newMap, key)) {
            return newMap;
        }
        else if (!isEmpty(indexPath)) {
            return add(key, cons(head(indexPath) + 1, tail(indexPath)), newMap);
        }
        else {
            return add(key, singleton(1), newMap);
        }
    }, currentMap, siblingMap);
}

export class ErasedModel extends Record {
    constructor(value, cursor) {
        super();
        this.value = value;
        this.cursor = cursor;
    }
}

export function ErasedModel$reflection() {
    return record_type("Webbler.StateTree.Core.StateTree.ErasedModel", [], ErasedModel, () => [["value", obj_type], ["cursor", list_type(string_type)]]);
}

export function ErasedModel_get_empty() {
    return new ErasedModel(null, empty());
}

export class ErasedMsg$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Erased", "Inbound", "Outbound"];
    }
}

export function ErasedMsg$2$reflection(gen0, gen1) {
    return union_type("Webbler.StateTree.Core.StateTree.ErasedMsg`2", [gen0, gen1], ErasedMsg$2, () => [[["Item", obj_type]], [["Item", gen0]], [["Item", gen1]]]);
}

export class StateTree$3 {
    constructor(erasedRoot) {
        this.erasedRoot = erasedRoot;
    }
}

export function StateTree$3$reflection(gen0, gen1, gen2) {
    return class_type("Webbler.StateTree.Core.StateTree.StateTree`3", [gen0, gen1, gen2], StateTree$3);
}

export function StateTree$3_$ctor_4E60E31B(erasedRoot) {
    return new StateTree$3(erasedRoot);
}

export function StateTree$3__get_root(_) {
    return _.erasedRoot;
}

export function StateTree$3__getIndexPathFromCursor_1334CEF1(this$, cursor) {
    return defaultArg(FSharpMap__TryFind(StateTree$3__get_root(this$).IndexPathFromKeyPath, cursor), singleton(0));
}

