import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { tuple_type, unit_type, union_type, record_type, bool_type, class_type, list_type, option_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { SiteViewModel$reflection } from "../Optiscan.Models/View/Site.js";
import { CrudListModel$1$reflection } from "../Optiscan.SharedUI/Crud.js";
import { AccountViewModel$reflection } from "../Optiscan.Models/View/Account.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Sort_SortInfo$1$reflection } from "../Optiscan.SharedUI/Sort.js";
import { Notification$reflection } from "../Optiscan.Models/Api/Api.js";
import { StateTree$3$reflection, ErasedModel$reflection, ErasedMsg$2$reflection } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { OptiscanUserData$reflection, UserRole$reflection } from "../Optiscan.Models/Security.js";
import { Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";

export class SearchModel extends Record {
    constructor(searchString, searchResults, authorizedSites, targetSite, addingSite) {
        super();
        this.searchString = searchString;
        this.searchResults = searchResults;
        this.authorizedSites = authorizedSites;
        this.targetSite = targetSite;
        this.addingSite = addingSite;
    }
}

export function SearchModel$reflection() {
    return record_type("Client.AuthorizedSites.Types.SearchModel", [], SearchModel, () => [["searchString", option_type(string_type)], ["searchResults", list_type(SiteViewModel$reflection())], ["authorizedSites", list_type(SiteViewModel$reflection())], ["targetSite", option_type(class_type("System.Guid"))], ["addingSite", bool_type]]);
}

export class ListModel extends Record {
    constructor(crudList, accounts, sites, selectedAccount, selectedSite, loadingAccounts, loadingSites) {
        super();
        this.crudList = crudList;
        this.accounts = accounts;
        this.sites = sites;
        this.selectedAccount = selectedAccount;
        this.selectedSite = selectedSite;
        this.loadingAccounts = loadingAccounts;
        this.loadingSites = loadingSites;
    }
}

export function ListModel$reflection() {
    return record_type("Client.AuthorizedSites.Types.ListModel", [], ListModel, () => [["crudList", CrudListModel$1$reflection(SiteViewModel$reflection())], ["accounts", list_type(AccountViewModel$reflection())], ["sites", list_type(SiteViewModel$reflection())], ["selectedAccount", option_type(AccountViewModel$reflection())], ["selectedSite", option_type(SiteViewModel$reflection())], ["loadingAccounts", bool_type], ["loadingSites", bool_type]]);
}

export function ListModel_get_crudList_() {
    return [(m) => m.crudList, (v) => ((m_1) => (new ListModel(v, m_1.accounts, m_1.sites, m_1.selectedAccount, m_1.selectedSite, m_1.loadingAccounts, m_1.loadingSites)))];
}

export class DetailModel extends Record {
    constructor(item, targetSite) {
        super();
        this.item = item;
        this.targetSite = targetSite;
    }
}

export function DetailModel$reflection() {
    return record_type("Client.AuthorizedSites.Types.DetailModel", [], DetailModel, () => [["item", SiteViewModel$reflection()], ["targetSite", option_type(class_type("System.Guid"))]]);
}

export class ListMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AuthSitesGetItems", "AuthSitesGetItemsResult", "AuthSitesSelectItem", "AuthSitesUpdateSortInfo", "AuthSitesUpdateSearchInfo", "AuthSitesTriggerSearch", "GetAuthorizedSitesForSite", "GetAccountsResult", "GetSites", "GetSitesResult", "SetSelectedAccount", "SetSelectedSite"];
    }
}

export function ListMsg$reflection() {
    return union_type("Client.AuthorizedSites.Types.ListMsg", [], ListMsg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["item", SiteViewModel$reflection()]], [["sortInfo", Sort_SortInfo$1$reflection(SiteViewModel$reflection())]], [["searchInfo", option_type(string_type)]], [["searchInfo", option_type(string_type)]], [["siteId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AccountViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(AccountViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["accountId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["maybeAccount", option_type(AccountViewModel$reflection())]], [["maybeSite", option_type(SiteViewModel$reflection())]]]);
}

export class SearchAndAddMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetSearchString", "SearchForSites", "SearchForSitesResult", "AddAuthorizedSite", "AddAuthorizedSiteResult", "GetAuthorizedSitesForSite", "GetAuthorizedSitesForSiteResult"];
    }
}

export function SearchAndAddMsg$reflection() {
    return union_type("Client.AuthorizedSites.Types.SearchAndAddMsg", [], SearchAndAddMsg, () => [[["Item", option_type(string_type)]], [["Item", unit_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["authorizedSiteId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["siteId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

export class ConfirmationMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RemoveAuthorizedSite", "RemoveAuthorizedSiteResult"];
    }
}

export function ConfirmationMsg$reflection() {
    return union_type("Client.AuthorizedSites.Types.ConfirmationMsg", [], ConfirmationMsg, () => [[["authorizedSiteId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

export class AuthSitesInbound extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AuthSitesUpdated"];
    }
}

export function AuthSitesInbound$reflection() {
    return union_type("Client.AuthorizedSites.Types.AuthSitesInbound", [], AuthSitesInbound, () => [[["siteId", class_type("System.Guid")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "Notification", "AuthorizedSitesTreeMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.AuthorizedSites.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Notification$reflection()]], [["Item", ErasedMsg$2$reflection(AuthSitesInbound$reflection(), unit_type)]]]);
}

export class Model extends Record {
    constructor(authorizedSitesModel, stateTree) {
        super();
        this.authorizedSitesModel = authorizedSitesModel;
        this.stateTree = stateTree;
    }
}

export function Model$reflection() {
    return record_type("Client.AuthorizedSites.Types.Model", [], Model, () => [["authorizedSitesModel", ErasedModel$reflection()], ["stateTree", option_type(StateTree$3$reflection(AuthSitesInbound$reflection(), unit_type, tuple_type(string_type, Security_UserInfo$2$reflection(UserRole$reflection(), OptiscanUserData$reflection()))))]]);
}

