import { StateTree$3__getIndexPathFromCursor_1334CEF1, StateTree$3__get_root } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { stateTree } from "./TelepathologyGuestTree.js";
import { cons, skip, tryFindIndex, tail, isEmpty, empty, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { ErasedMsg$2, ErasedModel } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Cmd_batch, Cmd_none as Cmd_none_1, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Msg, Model } from "./TelepathologyGuestTypes.js";
import { Cmd_none, Cmd_map as Cmd_map_1 } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { value as value_9, defaultArg, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library.3.7.12/Map.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export function init() {
    let patternInput_1;
    const root = StateTree$3__get_root(stateTree);
    const patternInput = root.Init(singleton(0), void 0, void 0, false);
    patternInput_1 = [new ErasedModel((new ErasedModel(patternInput[0], empty())).value, singleton(root.LastKey)), Cmd_map((arg00_1) => (new ErasedMsg$2(0, arg00_1)), patternInput[1])];
    return [new Model(patternInput_1[0], void 0), Cmd_map_1((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1])];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1: {
            return [model, Cmd_none()];
        }
        case 2: {
            const matchValue = model.maybeCode;
            if (matchValue == null) {
                console.error(some("Got a guest telepathology tree message without a token, ignoring"));
                return [model, Cmd_none()];
            }
            else {
                let patternInput_3;
                const arg10 = matchValue;
                const msg_1 = msg.fields[0];
                const model_1 = model.telepathologyGuestTreeModel;
                const root = StateTree$3__get_root(stateTree);
                switch (msg_1.tag) {
                    case 1: {
                        const patternInput_2 = root.UpdateInbound(msg_1.fields[0], void 0, model_1.value);
                        patternInput_3 = [new ErasedModel(patternInput_2[0], model_1.cursor), Cmd_map((arg00_3) => (new ErasedMsg$2(0, arg00_3)), patternInput_2[1])];
                        break;
                    }
                    case 2: {
                        patternInput_3 = [model_1, Cmd_none_1()];
                        break;
                    }
                    default: {
                        const patternInput = root.Update(defaultArg(FSharpMap__TryFind(root.IndexPathFromKeyPath, model_1.cursor), singleton(0)), msg_1.fields[0], arg10, void 0, model_1.value);
                        const maybeOutboundMsg = patternInput[3];
                        const maybeNavMsg = patternInput[2];
                        const innerModel = patternInput[0];
                        const innerCmd = patternInput[1];
                        let newCursor;
                        let pattern_matching_result, keyPath, key, key_1, key_2;
                        if (maybeNavMsg != null) {
                            if (maybeNavMsg.tag === 1) {
                                pattern_matching_result = 2;
                            }
                            else if (maybeNavMsg.tag === 2) {
                                pattern_matching_result = 2;
                            }
                            else if (maybeNavMsg.tag === 3) {
                                pattern_matching_result = 3;
                                key = maybeNavMsg.fields[0];
                            }
                            else if (maybeNavMsg.tag === 4) {
                                pattern_matching_result = 4;
                                key_1 = maybeNavMsg.fields[0];
                            }
                            else if (maybeNavMsg.tag === 5) {
                                pattern_matching_result = 5;
                                key_2 = maybeNavMsg.fields[0];
                            }
                            else {
                                pattern_matching_result = 1;
                                keyPath = maybeNavMsg.fields[0];
                            }
                        }
                        else {
                            pattern_matching_result = 0;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                newCursor = model_1.cursor;
                                break;
                            }
                            case 1: {
                                newCursor = keyPath;
                                break;
                            }
                            case 2: {
                                const matchValue_1 = model_1.cursor;
                                let pattern_matching_result_1, xs;
                                if (!isEmpty(matchValue_1)) {
                                    if (isEmpty(tail(matchValue_1))) {
                                        pattern_matching_result_1 = 0;
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                        xs = tail(matchValue_1);
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 0;
                                }
                                switch (pattern_matching_result_1) {
                                    case 0: {
                                        console.error(some("Could not find parent node"));
                                        newCursor = model_1.cursor;
                                        break;
                                    }
                                    case 1: {
                                        newCursor = xs;
                                        break;
                                    }
                                }
                                break;
                            }
                            case 3: {
                                const matchValue_1_1 = tryFindIndex((y) => (key === y), model_1.cursor);
                                if (matchValue_1_1 == null) {
                                    console.error(some(`Could not find ancestor node ${key}`));
                                    newCursor = model_1.cursor;
                                }
                                else {
                                    newCursor = skip(matchValue_1_1, model_1.cursor);
                                }
                                break;
                            }
                            case 4: {
                                const childKey = cons(key_1, model_1.cursor);
                                const matchValue_2 = FSharpMap__TryFind(root.IndexPathFromKeyPath, childKey);
                                if (matchValue_2 == null) {
                                    console.error(some(`Could not find child node ${key_1}`));
                                    newCursor = model_1.cursor;
                                }
                                else {
                                    newCursor = childKey;
                                }
                                break;
                            }
                            case 5: {
                                const matchValue_3 = model_1.cursor;
                                newCursor = ((!isEmpty(matchValue_3)) ? cons(key_2, tail(matchValue_3)) : singleton(key_2));
                                break;
                            }
                        }
                        if (!equals(newCursor, model_1.cursor)) {
                            const patternInput_1 = root.Init(StateTree$3__getIndexPathFromCursor_1334CEF1(stateTree, newCursor), void 0, some(innerModel), false);
                            patternInput_3 = [new ErasedModel(patternInput_1[0], newCursor), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_map((arg00_1) => (new ErasedMsg$2(0, arg00_1)), innerCmd)), delay(() => append(singleton_1(Cmd_map((arg00_1_1) => (new ErasedMsg$2(0, arg00_1_1)), patternInput_1[1])), delay(() => {
                                if (maybeOutboundMsg != null) {
                                    return singleton_1(Cmd_OfFunc_result(new ErasedMsg$2(2, value_9(maybeOutboundMsg))));
                                }
                                else {
                                    return empty_1();
                                }
                            })))))))];
                        }
                        else {
                            patternInput_3 = [new ErasedModel(innerModel, model_1.cursor), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_map((arg00_2) => (new ErasedMsg$2(0, arg00_2)), innerCmd)), delay(() => {
                                if (maybeOutboundMsg != null) {
                                    return singleton_1(Cmd_OfFunc_result(new ErasedMsg$2(2, value_9(maybeOutboundMsg))));
                                }
                                else {
                                    return empty_1();
                                }
                            })))))];
                        }
                    }
                }
                return [new Model(patternInput_3[0], model.maybeCode), Cmd_map_1((arg0_5) => (new Msg(2, arg0_5)), patternInput_3[1])];
            }
        }
        default: {
            return [model, Cmd_none()];
        }
    }
}

