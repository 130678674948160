import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, int32_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class FlaggedImageViewModel extends Record {
    constructor(sessionId, frameNumber) {
        super();
        this.sessionId = sessionId;
        this.frameNumber = (frameNumber | 0);
    }
}

export function FlaggedImageViewModel$reflection() {
    return record_type("Optiscan.Models.View.FlaggedImage.FlaggedImageViewModel", [], FlaggedImageViewModel, () => [["sessionId", class_type("System.Guid")], ["frameNumber", int32_type]]);
}

