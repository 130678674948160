"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TelestatsHelpers_1 = require("./TelestatsHelpers");
function getIntMetadataAtOffset(frameData, offset) {
    // Fun bit magic to convert little endian byte array to an unsinged integer
    var shift = -8; // start at -8 because += works like ++i not i++
    return frameData
        .slice(offset * 4, (offset * 4) + 4)
        .reduce(function (acc, v) { return acc + (v << (shift += 8)); }, 0);
}
function getInt64MetadataAtOffset(frameData, offset) {
    // Fun bit magic to convert little endian byte array to an unsinged integer
    var shift = -8n; // start at -8 because += works like ++i not i++
    return frameData
        .slice(offset * 4, (offset * 4) + 8)
        .reduce(function (acc, v) { return acc + (BigInt(v) << (shift += 8n)); }, 0n);
}
function checkImageReceivedTimeout(taskId) {
    var webRTCOverlay = document.getElementById("webrtc-overlay");
    webRTCOverlay.classList.add("receiving-images");
    if (taskId !== null) {
        clearTimeout(taskId);
    }
    taskId = setTimeout(function () {
        taskId = null;
        webRTCOverlay.classList.remove("receiving-images");
    }, 2000);
    return taskId;
}
function streamDataChannelToTextureCallback(canvas, viewerContext, onDroppedFrame, debugTeleStats) {
    //const canvas = viewerContext.current.canvas;
    var canvasContext = canvas.getContext("2d");
    var SEGMENT_SIZE = 16000;
    var SEGMENT_METADATA_SIZE = 24;
    viewerContext.current.currentFrame = 0;
    viewerContext.current.foundFirstFrame = false;
    viewerContext.current.dataStarted = false;
    viewerContext.current.stopped = false;
    var bufferedFrames = [];
    // For checking if we're receiving images
    var taskTimeoutId = null;
    // Telemetry Statistics
    var telestats = TelestatsHelpers_1.TelestatsHelpers.createTelestatsObject();
    if (debugTeleStats) {
        var downloadCsvButton = document.getElementById("csv-download");
        downloadCsvButton.onclick = function (_) { return TelestatsHelpers_1.TelestatsHelpers.toCsv(telestats); };
    }
    var startTime = Date.now();
    if (debugTeleStats)
        telestats = TelestatsHelpers_1.TelestatsHelpers.setStartTime(telestats, startTime);
    return function (frameSegment) {
        if (!viewerContext.current.stopped) {
            var frameData_1 = new Uint8Array(frameSegment.data);
            var frameNumber = getIntMetadataAtOffset(frameData_1, 0);
            var fileSize = getIntMetadataAtOffset(frameData_1, 1);
            var segmentCount = getIntMetadataAtOffset(frameData_1, 2);
            var segmentNumber = getIntMetadataAtOffset(frameData_1, 3);
            var timestamp = getInt64MetadataAtOffset(frameData_1, 4);
            // This is needed so we don't count the first frame as dropped
            if (!viewerContext.current.foundFirstFrame) {
                viewerContext.current.currentFrame = frameNumber - 1;
                viewerContext.current.foundFirstFrame = true;
            }
            if (debugTeleStats) {
                var segmentLogMessage = "Frame #: ".concat(frameNumber, " | Segment #: ").concat(segmentNumber + 1, "/").concat(segmentCount, " | Timestamp: ").concat(Date.now() - startTime, " ms | Latency: ").concat(BigInt(Date.now()) - timestamp, " ms");
                telestats = TelestatsHelpers_1.TelestatsHelpers.trySetFirstFrame(telestats, frameNumber);
                telestats = TelestatsHelpers_1.TelestatsHelpers.gatherMetrics(telestats, frameNumber, segmentNumber, segmentCount, fileSize, timestamp);
                telestats = TelestatsHelpers_1.TelestatsHelpers.dolog(telestats, console.info, segmentLogMessage);
            }
            var foundFrame = false;
            viewerContext.current.dataStarted = true;
            bufferedFrames =
                bufferedFrames
                    .map(function (bufferedFrame) {
                    if (bufferedFrame.frameNumber == frameNumber) {
                        foundFrame = true;
                        (new Uint8Array(bufferedFrame.buffer)).set(frameData_1.slice(SEGMENT_METADATA_SIZE), segmentNumber * (SEGMENT_SIZE - SEGMENT_METADATA_SIZE));
                        bufferedFrame.segmentsReceived = bufferedFrame.segmentsReceived + 1;
                    }
                    return bufferedFrame;
                });
            if (foundFrame == false) {
                var newFrame = {
                    frameNumber: frameNumber,
                    segmentCount: segmentCount,
                    segmentsReceived: 1,
                    timestamp: timestamp,
                    buffer: new ArrayBuffer(fileSize)
                };
                (new Uint8Array(newFrame.buffer)).set(frameData_1.slice(SEGMENT_METADATA_SIZE), segmentNumber * (SEGMENT_SIZE - SEGMENT_METADATA_SIZE));
                bufferedFrames.push(newFrame);
            }
            bufferedFrames =
                bufferedFrames
                    .filter(function (bufferedFrame) {
                    if (bufferedFrame.frameNumber < viewerContext.current.currentFrame) {
                        // If we are already ahead of this frame then dispose of it
                        return false;
                    }
                    else if (bufferedFrame.segmentsReceived == bufferedFrame.segmentCount) {
                        var droppedFrameCount_1 = bufferedFrame.frameNumber - viewerContext.current.currentFrame;
                        var renderTime_1 = BigInt(0);
                        var frameRendered_1 = false;
                        var frameDropReason_1 = "missing frame data";
                        createImageBitmap(new Blob([bufferedFrame.buffer], { type: "image/jpeg" }))
                            .then(function (bitmap) {
                            // TODO: We need to be able to dynamically handle different aspect ratios, but we don't actually know the aspect ratio untill this point
                            // (https://prolucid.atlassian.net/browse/OTP-432)
                            canvas.width = bitmap.width;
                            canvas.height = bitmap.height;
                            canvasContext.drawImage(bitmap, 0, 0);
                            renderTime_1 = BigInt(Date.now());
                            frameRendered_1 = true;
                        }).catch(function (error) {
                            frameDropReason_1 = "render error";
                            if (debugTeleStats) {
                                telestats = TelestatsHelpers_1.TelestatsHelpers.dolog(telestats, console.error, "Render Error: ".concat(error));
                            }
                            else {
                                console.error("Render Error: ".concat(error));
                            }
                            droppedFrameCount_1++;
                        }).finally(function () {
                            if (frameRendered_1) {
                                window.dispatchEvent(new Event("teleKeepAlive"));
                                taskTimeoutId = checkImageReceivedTimeout(taskTimeoutId);
                                var frameLogMessage = "Frame #: ".concat(bufferedFrame.frameNumber, " | Timestamp: ").concat(Date.now() - startTime, " ms | Latency: ").concat(renderTime_1 - timestamp, " ms");
                                if (debugTeleStats) {
                                    telestats = TelestatsHelpers_1.TelestatsHelpers.adjustForRenderTime(telestats, bufferedFrame.frameNumber, renderTime_1);
                                    telestats = TelestatsHelpers_1.TelestatsHelpers.dolog(telestats, console.info, frameLogMessage);
                                }
                                else {
                                    console.info(frameLogMessage);
                                }
                            }
                            if (droppedFrameCount_1 > 1) {
                                var frameDropMsg = "Dropped ".concat(droppedFrameCount_1 - 1, " frame(s) due to ").concat(frameDropReason_1);
                                // Telestats has a more detailed log message
                                if (!debugTeleStats) {
                                    console.error(frameDropMsg);
                                }
                                onDroppedFrame(frameDropMsg);
                            }
                            viewerContext.current.currentFrame = bufferedFrame.frameNumber;
                        });
                        return false;
                    }
                    else {
                        return true;
                    }
                });
        }
    };
}
// Take JPEG images streamed over a WebRTC data channel and copy them to a Vtk.js texture
function setupDataChannels(canvas, dataChannels, peerConnection, viewerContext, droppedFrameCallback, controlChannelCallback, debugTeleStats) {
    var handleDataChannel = function (channel) {
        if (channel.label === "controlChannel") {
            channel.onmessage = function (message) {
                controlChannelCallback(JSON.parse(message.data));
            };
        }
        else if (channel.label === "videoChannel") {
            channel.binaryType = "arraybuffer";
            channel.onmessage = streamDataChannelToTextureCallback(canvas, viewerContext, droppedFrameCallback, debugTeleStats);
        }
    };
    // There is still a very small chance that a data channel comes in inbetween setting up the react element and
    // setting up the ondatachannel callback. This will only ever happen if the user had the page unfocused and
    // tabs back into the page at the exact right time which is extremely unlikely and not worth worrying about
    // Handle any future data channels
    peerConnection.ondatachannel = function (event) {
        console.log(event);
        handleDataChannel(event.channel);
    };
    // Handle any existing data channels
    for (var _i = 0, dataChannels_1 = dataChannels; _i < dataChannels_1.length; _i++) {
        var channel = dataChannels_1[_i];
        handleDataChannel(channel);
    }
}
exports.default = { setupDataChannels: setupDataChannels };
