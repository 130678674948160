import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { TelepathologyMsg$reflection } from "../../TelepathologyCommon/TelepathologyTypes.js";

export class PatientTelepathologyMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "TelepathologyMsg", "StopViewer", "StopStream"];
    }
}

export function PatientTelepathologyMsg$reflection() {
    return union_type("Client.Patients.Telepathology.Types.PatientTelepathologyMsg", [], PatientTelepathologyMsg, () => [[["Item", class_type("System.Exception")]], [["Item", TelepathologyMsg$reflection()]], [["Item", string_type]], []]);
}

