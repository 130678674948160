import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { float64_type, array_type, option_type, union_type, bool_type, class_type, record_type, obj_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { keyValueList } from "../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { isArrayLike } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { get_UTF8 } from "../../fable_modules/fable-library.3.7.12/Encoding.js";

export class CredentialsParams extends Record {
    constructor(IdentityPoolId, IdentityId, Logins, region) {
        super();
        this.IdentityPoolId = IdentityPoolId;
        this.IdentityId = IdentityId;
        this.Logins = Logins;
        this.region = region;
    }
}

export function CredentialsParams$reflection() {
    return record_type("Fable.Helpers.Aws.CredentialsParams", [], CredentialsParams, () => [["IdentityPoolId", string_type], ["IdentityId", string_type], ["Logins", obj_type], ["region", string_type]]);
}

export function CredentialsParams_get_create() {
    return (value) => value;
}

export class CognitoSetupParams extends Record {
    constructor(region, accessKeyId, secretAccessKey) {
        super();
        this.region = region;
        this.accessKeyId = accessKeyId;
        this.secretAccessKey = secretAccessKey;
    }
}

export function CognitoSetupParams$reflection() {
    return record_type("Fable.Helpers.Aws.CognitoSetupParams", [], CognitoSetupParams, () => [["region", string_type], ["accessKeyId", string_type], ["secretAccessKey", string_type]]);
}

export function CognitoSetupParams_get_create() {
    return (value) => value;
}

export class Config extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Region", "Credentials", "AccessKeyId", "SecretAccessKey", "SessionToken", "Endpoint", "CorrectClockSkew"];
    }
}

export function Config$reflection() {
    return union_type("Fable.Helpers.Aws.Config", [], Config, () => [[["Item", string_type]], [["Item", class_type("Fable.Import.Aws.Credentials.Credentials")]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]]]);
}

export function Config_get_create() {
    return (arg) => keyValueList(arg, 1);
}

export class S3_ApiParams extends Record {
    constructor(ApiVersion, Bucket) {
        super();
        this.ApiVersion = ApiVersion;
        this.Bucket = Bucket;
    }
}

export function S3_ApiParams$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ApiParams", [], S3_ApiParams, () => [["ApiVersion", string_type], ["Bucket", string_type]]);
}

export function S3_ApiParams_get_create() {
    return (value) => value;
}

export class S3_GetObjectRequestParams extends Record {
    constructor(Bucket, Key) {
        super();
        this.Bucket = Bucket;
        this.Key = Key;
    }
}

export function S3_GetObjectRequestParams$reflection() {
    return record_type("Fable.Helpers.Aws.S3.GetObjectRequestParams", [], S3_GetObjectRequestParams, () => [["Bucket", string_type], ["Key", obj_type]]);
}

export function S3_GetObjectRequestParams_get_create() {
    return (value) => value;
}

export class S3_ListObjectsRequestParams extends Record {
    constructor(Bucket, Delimiter, Prefix) {
        super();
        this.Bucket = Bucket;
        this.Delimiter = Delimiter;
        this.Prefix = Prefix;
    }
}

export function S3_ListObjectsRequestParams$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ListObjectsRequestParams", [], S3_ListObjectsRequestParams, () => [["Bucket", string_type], ["Delimiter", string_type], ["Prefix", string_type]]);
}

export function S3_ListObjectsRequestParams_get_create() {
    return (value) => value;
}

export class S3_ListObjectsV2RequestParams extends Record {
    constructor(Bucket, Delimiter, Prefix, StartAfter) {
        super();
        this.Bucket = Bucket;
        this.Delimiter = Delimiter;
        this.Prefix = Prefix;
        this.StartAfter = StartAfter;
    }
}

export function S3_ListObjectsV2RequestParams$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ListObjectsV2RequestParams", [], S3_ListObjectsV2RequestParams, () => [["Bucket", string_type], ["Delimiter", string_type], ["Prefix", string_type], ["StartAfter", string_type]]);
}

export function S3_ListObjectsV2RequestParams_get_create() {
    return (value) => value;
}

export class S3_DeleteObjectRequestParams extends Record {
    constructor(Bucket, Key) {
        super();
        this.Bucket = Bucket;
        this.Key = Key;
    }
}

export function S3_DeleteObjectRequestParams$reflection() {
    return record_type("Fable.Helpers.Aws.S3.DeleteObjectRequestParams", [], S3_DeleteObjectRequestParams, () => [["Bucket", string_type], ["Key", string_type]]);
}

export function S3_DeleteObjectRequestParams_get_create() {
    return (value) => value;
}

export class S3_PutObjectRequestParams extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ACL", "Body", "Bucket", "Key", "ServerSideEncryption", "StorageClass"];
    }
}

export function S3_PutObjectRequestParams$reflection() {
    return union_type("Fable.Helpers.Aws.S3.PutObjectRequestParams", [], S3_PutObjectRequestParams, () => [[["Item", string_type]], [["Item", obj_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function S3_PutObjectRequestParams_get_create() {
    return (arg) => keyValueList(arg, 0);
}

export function S3_bodyToString(body) {
    if (isArrayLike(body)) {
        const a = get_UTF8().getString(body);
        return Promise.resolve(a);
    }
    else if (body instanceof Blob) {
        return body.text();
    }
    else if ((typeof body) === "string") {
        return Promise.resolve(body);
    }
    else {
        return Promise.reject(new Error("Buffer not supported"));
    }
}

export function S3_bodyToBlob(body) {
    if (isArrayLike(body)) {
        const arr = new Uint8Array(body);
        const a = new Blob([arr.buffer], {
            type: "application/octet-stream",
        });
        return Promise.resolve(a);
    }
    else if (body instanceof Blob) {
        return Promise.resolve(body);
    }
    else if ((typeof body) === "string") {
        return Promise.reject(new Error("String not supported"));
    }
    else {
        return Promise.reject(new Error("Buffer not supported"));
    }
}

export class KinesisVideo_KinesisClientConfigParams extends Record {
    constructor(Region, AccessKeyId, SecretAccessKey, SessionToken, Endpoint, CorrectClockSkew) {
        super();
        this.Region = Region;
        this.AccessKeyId = AccessKeyId;
        this.SecretAccessKey = SecretAccessKey;
        this.SessionToken = SessionToken;
        this.Endpoint = Endpoint;
        this.CorrectClockSkew = CorrectClockSkew;
    }
}

export function KinesisVideo_KinesisClientConfigParams$reflection() {
    return record_type("Fable.Helpers.Aws.KinesisVideo.KinesisClientConfigParams", [], KinesisVideo_KinesisClientConfigParams, () => [["Region", string_type], ["AccessKeyId", string_type], ["SecretAccessKey", string_type], ["SessionToken", string_type], ["Endpoint", string_type], ["CorrectClockSkew", bool_type]]);
}

export function KinesisVideo_KinesisClientConfigParams_get_createKinesisVideoClient() {
    return (value) => value;
}

export function KinesisVideo_KinesisClientConfigParams_get_createKinesisSignalingChannelsClient() {
    return (value) => value;
}

export class KinesisVideo_DescribeSignalingChannelParams extends Record {
    constructor(ChannelName, ChannelARN) {
        super();
        this.ChannelName = ChannelName;
        this.ChannelARN = ChannelARN;
    }
}

export function KinesisVideo_DescribeSignalingChannelParams$reflection() {
    return record_type("Fable.Helpers.Aws.KinesisVideo.DescribeSignalingChannelParams", [], KinesisVideo_DescribeSignalingChannelParams, () => [["ChannelName", option_type(string_type)], ["ChannelARN", option_type(string_type)]]);
}

export function KinesisVideo_DescribeSignalingChannelParams_get_create() {
    return (value) => value;
}

export class KinesisVideo_SingleMasterChannelEndpointConfigurationParams extends Record {
    constructor(Protocols, Role) {
        super();
        this.Protocols = Protocols;
        this.Role = Role;
    }
}

export function KinesisVideo_SingleMasterChannelEndpointConfigurationParams$reflection() {
    return record_type("Fable.Helpers.Aws.KinesisVideo.SingleMasterChannelEndpointConfigurationParams", [], KinesisVideo_SingleMasterChannelEndpointConfigurationParams, () => [["Protocols", array_type(string_type)], ["Role", string_type]]);
}

export function KinesisVideo_SingleMasterChannelEndpointConfigurationParams_get_create() {
    return (value) => value;
}

export class KinesisVideo_GetSignalingChannelEndpointParams extends Record {
    constructor(ChannelARN, SingleMasterChannelEndpointConfiguration) {
        super();
        this.ChannelARN = ChannelARN;
        this.SingleMasterChannelEndpointConfiguration = SingleMasterChannelEndpointConfiguration;
    }
}

export function KinesisVideo_GetSignalingChannelEndpointParams$reflection() {
    return record_type("Fable.Helpers.Aws.KinesisVideo.GetSignalingChannelEndpointParams", [], KinesisVideo_GetSignalingChannelEndpointParams, () => [["ChannelARN", option_type(string_type)], ["SingleMasterChannelEndpointConfiguration", class_type("Fable.Import.Aws.KinesisVideo.SingleMasterChannelEndpointConfiguration")]]);
}

export function KinesisVideo_GetSignalingChannelEndpointParams_get_create() {
    return (value) => value;
}

export class KinesisVideo_GetIceServerConfigParams extends Record {
    constructor(ChannelARN, ClientId, Service, Username) {
        super();
        this.ChannelARN = ChannelARN;
        this.ClientId = ClientId;
        this.Service = Service;
        this.Username = Username;
    }
}

export function KinesisVideo_GetIceServerConfigParams$reflection() {
    return record_type("Fable.Helpers.Aws.KinesisVideo.GetIceServerConfigParams", [], KinesisVideo_GetIceServerConfigParams, () => [["ChannelARN", string_type], ["ClientId", option_type(string_type)], ["Service", option_type(string_type)], ["Username", option_type(string_type)]]);
}

export function KinesisVideo_GetIceServerConfigParams_get_create() {
    return (value) => value;
}

export class KinesisVideo_SignalingClientCredentials extends Record {
    constructor(accessKeyId, secretAccessKey, sessionToken) {
        super();
        this.accessKeyId = accessKeyId;
        this.secretAccessKey = secretAccessKey;
        this.sessionToken = sessionToken;
    }
}

export function KinesisVideo_SignalingClientCredentials$reflection() {
    return record_type("Fable.Helpers.Aws.KinesisVideo.SignalingClientCredentials", [], KinesisVideo_SignalingClientCredentials, () => [["accessKeyId", string_type], ["secretAccessKey", string_type], ["sessionToken", string_type]]);
}

export function KinesisVideo_SignalingClientCredentials_get_create() {
    return (value) => value;
}

export class KinesisVideo_SignalingClientParams extends Record {
    constructor(channelARN, channelEndpoint, clientId, credentials, region, role, systemClockOffset) {
        super();
        this.channelARN = channelARN;
        this.channelEndpoint = channelEndpoint;
        this.clientId = clientId;
        this.credentials = credentials;
        this.region = region;
        this.role = role;
        this.systemClockOffset = systemClockOffset;
    }
}

export function KinesisVideo_SignalingClientParams$reflection() {
    return record_type("Fable.Helpers.Aws.KinesisVideo.SignalingClientParams", [], KinesisVideo_SignalingClientParams, () => [["channelARN", string_type], ["channelEndpoint", string_type], ["clientId", option_type(string_type)], ["credentials", class_type("Fable.Import.Aws.Credentials.Credentials")], ["region", string_type], ["role", string_type], ["systemClockOffset", float64_type]]);
}

export function KinesisVideo_SignalingClientParams_get_create() {
    return (value) => value;
}

