import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class AccountViewModel extends Record {
    constructor(id, name, number, address, contactName, contactEmail, contactPhone) {
        super();
        this.id = id;
        this.name = name;
        this.number = number;
        this.address = address;
        this.contactName = contactName;
        this.contactEmail = contactEmail;
        this.contactPhone = contactPhone;
    }
}

export function AccountViewModel$reflection() {
    return record_type("Optiscan.Models.View.Account.AccountViewModel", [], AccountViewModel, () => [["id", class_type("System.Guid")], ["name", string_type], ["number", string_type], ["address", string_type], ["contactName", string_type], ["contactEmail", string_type], ["contactPhone", string_type]]);
}

export function AccountViewModel_get_empty() {
    return new AccountViewModel("00000000-0000-0000-0000-000000000000", "", "", "", "", "", "");
}

export function AccountViewModel_get_id_() {
    return [(m) => m.id, (v) => ((m_1) => (new AccountViewModel(v, m_1.name, m_1.number, m_1.address, m_1.contactName, m_1.contactEmail, m_1.contactPhone)))];
}

export function AccountViewModel_get_name_() {
    return [(m) => m.name, (v) => ((m_1) => (new AccountViewModel(m_1.id, v, m_1.number, m_1.address, m_1.contactName, m_1.contactEmail, m_1.contactPhone)))];
}

export function AccountViewModel_get_number_() {
    return [(m) => m.number, (v) => ((m_1) => (new AccountViewModel(m_1.id, m_1.name, v, m_1.address, m_1.contactName, m_1.contactEmail, m_1.contactPhone)))];
}

export function AccountViewModel_get_address_() {
    return [(m) => m.address, (v) => ((m_1) => (new AccountViewModel(m_1.id, m_1.name, m_1.number, v, m_1.contactName, m_1.contactEmail, m_1.contactPhone)))];
}

export function AccountViewModel_get_contactName_() {
    return [(m) => m.contactName, (v) => ((m_1) => (new AccountViewModel(m_1.id, m_1.name, m_1.number, m_1.address, v, m_1.contactEmail, m_1.contactPhone)))];
}

export function AccountViewModel_get_contactEmail_() {
    return [(m) => m.contactEmail, (v) => ((m_1) => (new AccountViewModel(m_1.id, m_1.name, m_1.number, m_1.address, m_1.contactName, v, m_1.contactPhone)))];
}

export function AccountViewModel_get_contactPhone_() {
    return [(m) => m.contactPhone, (v) => ((m_1) => (new AccountViewModel(m_1.id, m_1.name, m_1.number, m_1.address, m_1.contactName, m_1.contactEmail, v)))];
}

