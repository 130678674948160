import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, tuple_type, string_type, union_type, unit_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Notification$reflection } from "../Optiscan.Models/Api/Api.js";
import { CrudListInboundMsg$reflection } from "../Optiscan.SharedUI/Crud.js";
import { ErasedModel$reflection, StateTree$3$reflection, ErasedMsg$2$reflection } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { OptiscanUserData$reflection, UserRole$reflection } from "../Optiscan.Models/Security.js";
import { Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "Notification", "CrudTreeMessage"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Accounts.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Notification$reflection()]], [["Item", ErasedMsg$2$reflection(CrudListInboundMsg$reflection(), unit_type)]]]);
}

export class Model extends Record {
    constructor(stateTree, crudListModel) {
        super();
        this.stateTree = stateTree;
        this.crudListModel = crudListModel;
    }
}

export function Model$reflection() {
    return record_type("Client.Accounts.Types.Model", [], Model, () => [["stateTree", option_type(StateTree$3$reflection(CrudListInboundMsg$reflection(), unit_type, tuple_type(string_type, Security_UserInfo$2$reflection(UserRole$reflection(), OptiscanUserData$reflection()))))], ["crudListModel", ErasedModel$reflection()]]);
}

export class AccountsDetailMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ViewSites"];
    }
}

export function AccountsDetailMsg$reflection() {
    return union_type("Client.Accounts.Types.AccountsDetailMsg", [], AccountsDetailMsg, () => [[]]);
}

