import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { option_type, bool_type, list_type, record_type, union_type, unit_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Notification$reflection } from "../Optiscan.Models/Api/Api.js";
import { CrudEditMsg$2$reflection, CrudListInboundMsg$reflection } from "../Optiscan.SharedUI/Crud.js";
import { ErasedModel$reflection, ErasedMsg$2$reflection } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Security_UserViewModel$2_get_userData_, Security_UserViewModel$2_get_roles_, Common_Name_get_family_, Common_Name_get_given_, Security_UserViewModel$2_get_name_, Security_UserViewModel$2_get_email_, Security_UserViewModel$2$reflection, Security_AuthErrorMessage_get_describe, Security_AuthErrorMessage$reflection } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { OptiscanUserData_get_siteIds_, OptiscanUserData_get_accountIds_, OptiscanUserData$reflection, UserRole$reflection } from "../Optiscan.Models/Security.js";
import { AccountViewModel$reflection } from "../Optiscan.Models/View/Account.js";
import { SiteViewModel$reflection } from "../Optiscan.Models/View/Site.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "Notification", "CrudTreeMessage"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Users.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Notification$reflection()]], [["Item", ErasedMsg$2$reflection(CrudListInboundMsg$reflection(), unit_type)]]]);
}

export class Model extends Record {
    constructor(crudListModel) {
        super();
        this.crudListModel = crudListModel;
    }
}

export function Model$reflection() {
    return record_type("Client.Users.Types.Model", [], Model, () => [["crudListModel", ErasedModel$reflection()]]);
}

export class UserDetailMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LockoutUser", "LockoutUserResult"];
    }
}

export function UserDetailMsg$reflection() {
    return union_type("Client.Users.Types.UserDetailMsg", [], UserDetailMsg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]]]);
}

export class ChangeEmailError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AuthError", "CurrentEmailInvalid", "NewEmailInvalid"];
    }
}

export function ChangeEmailError$reflection() {
    return union_type("Client.Users.Types.ChangeEmailError", [], ChangeEmailError, () => [[["Item", Security_AuthErrorMessage$reflection()]], [], []]);
}

export function ChangeEmailError_describe_2CEEC529(err) {
    switch (err.tag) {
        case 1: {
            return "The user\u0027s current email is invalid. Please try reloading the page.";
        }
        case 2: {
            return "The new email is invalid. Please enter a valid email address.";
        }
        default: {
            return Security_AuthErrorMessage_get_describe()(err.fields[0]);
        }
    }
}

export class UserEditModel extends Record {
    constructor(user, selectableRoles, loadingAccounts, accounts, loadingSites, sites) {
        super();
        this.user = user;
        this.selectableRoles = selectableRoles;
        this.loadingAccounts = loadingAccounts;
        this.accounts = accounts;
        this.loadingSites = loadingSites;
        this.sites = sites;
    }
}

export function UserEditModel$reflection() {
    return record_type("Client.Users.Types.UserEditModel", [], UserEditModel, () => [["user", Security_UserViewModel$2$reflection(UserRole$reflection(), OptiscanUserData$reflection())], ["selectableRoles", list_type(UserRole$reflection())], ["loadingAccounts", bool_type], ["accounts", list_type(AccountViewModel$reflection())], ["loadingSites", bool_type], ["sites", list_type(SiteViewModel$reflection())]]);
}

export function UserEditModel_get_user_() {
    return [(model) => model.user, (user) => ((model_1) => (new UserEditModel(user, model_1.selectableRoles, model_1.loadingAccounts, model_1.accounts, model_1.loadingSites, model_1.sites)))];
}

export function UserEditModel_get_email_() {
    const l = UserEditModel_get_user_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Security_UserViewModel$2_get_email_())(l);
}

export function UserEditModel_get_givenName_() {
    let l_2;
    const l = UserEditModel_get_user_();
    l_2 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Security_UserViewModel$2_get_name_())(l);
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_given_())(l_2);
}

export function UserEditModel_get_familyName_() {
    let l_2;
    const l = UserEditModel_get_user_();
    l_2 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Security_UserViewModel$2_get_name_())(l);
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_family_())(l_2);
}

export function UserEditModel_get_roles_() {
    const l = UserEditModel_get_user_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Security_UserViewModel$2_get_roles_())(l);
}

export function UserEditModel_get_accountIds_() {
    let l_2;
    const l = UserEditModel_get_user_();
    l_2 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Security_UserViewModel$2_get_userData_())(l);
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), OptiscanUserData_get_accountIds_())(l_2);
}

export function UserEditModel_get_siteIds_() {
    let l_2;
    const l = UserEditModel_get_user_();
    l_2 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Security_UserViewModel$2_get_userData_())(l);
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), OptiscanUserData_get_siteIds_())(l_2);
}

export class UserEditMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CrudEditMsg", "Initialize", "SetAccount", "GetAccountsResult", "GetSitesResult"];
    }
}

export function UserEditMsg$reflection() {
    return union_type("Client.Users.Types.UserEditMsg", [], UserEditMsg, () => [[["Item", CrudEditMsg$2$reflection(UserEditModel$reflection(), Security_AuthErrorMessage$reflection())]], [], [["maybeAccount", option_type(AccountViewModel$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AccountViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(AccountViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

