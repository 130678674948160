import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { StateTree$3__getIndexPathFromCursor_1334CEF1, StateTree$3__get_root } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { stateTree } from "./UsersTree.js";
import { Msg } from "./UsersTypes.js";
import { ErasedMsg$2 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";

export function view(model, dispatch) {
    let model_1;
    return content(empty(), singleton((model_1 = model.crudListModel, StateTree$3__get_root(stateTree).View(StateTree$3__getIndexPathFromCursor_1334CEF1(stateTree, model_1.cursor), (arg) => {
        ((arg_1) => {
            dispatch(new Msg(3, arg_1));
        })(new ErasedMsg$2(0, arg));
    }, model_1.value))));
}

