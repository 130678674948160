import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { unit_type, lambda_type, class_type, record_type, string_type, option_type, list_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemListSortable, ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_SortInfo$1, Sort_SortDirection, Sort_SortableColumn$1_toSortableColumnView_45D2C9EC, ListDisplayModification_ListDisplayModification$2$reflection, Sort_SortInfo$1$reflection } from "./Sort.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { StateTreeNode$6, StateTreeMsg$4, NavigationMsg$1 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { sortWith, item as item_2, length, exists, filter, ofArray, empty, singleton as singleton_1 } from "../fable_modules/fable-library.3.7.12/List.js";
import { Common_GenericOption, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option as Option_1, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { value as value_1, some, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.12/String.js";
import { equals, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { content, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { defaultSearchComponent } from "./Search.js";
import * as react from "react";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { success, error } from "./Toast.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";

export class CrudListModification extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Sort", "Search"];
    }
}

export function CrudListModification$reflection() {
    return union_type("Optiscan.SharedUI.Crud.CrudListModification", [], CrudListModification, () => [[], []]);
}

export class CrudListModel$1 extends Record {
    constructor(Items, MaybeSelected, SortInfo, SearchInfo, ListModifications) {
        super();
        this.Items = Items;
        this.MaybeSelected = MaybeSelected;
        this.SortInfo = SortInfo;
        this.SearchInfo = SearchInfo;
        this.ListModifications = ListModifications;
    }
}

export function CrudListModel$1$reflection(gen0) {
    return record_type("Optiscan.SharedUI.Crud.CrudListModel`1", [gen0], CrudListModel$1, () => [["Items", list_type(gen0)], ["MaybeSelected", option_type(gen0)], ["SortInfo", Sort_SortInfo$1$reflection(gen0)], ["SearchInfo", option_type(string_type)], ["ListModifications", list_type(ListDisplayModification_ListDisplayModification$2$reflection(gen0, CrudListModification$reflection()))]]);
}

export function CrudListModel$1_get_Items_() {
    return [(m) => m.Items, (v) => ((m_1) => (new CrudListModel$1(v, m_1.MaybeSelected, m_1.SortInfo, m_1.SearchInfo, m_1.ListModifications)))];
}

export function CrudListModel$1_get_MaybeSelected_() {
    return [(m) => m.MaybeSelected, (v) => ((m_1) => (new CrudListModel$1(m_1.Items, v, m_1.SortInfo, m_1.SearchInfo, m_1.ListModifications)))];
}

export function CrudListModel$1_get_SortInfo_() {
    return [(m) => m.SortInfo, (v) => ((m_1) => (new CrudListModel$1(m_1.Items, m_1.MaybeSelected, v, m_1.SearchInfo, m_1.ListModifications)))];
}

export function CrudListModel$1_get_SearchInfo_() {
    return [(m) => m.SearchInfo, (v) => ((m_1) => (new CrudListModel$1(m_1.Items, m_1.MaybeSelected, m_1.SortInfo, v, m_1.ListModifications)))];
}

export function CrudListModel$1_get_ListModifications_() {
    return [(m) => m.ListModifications, (v) => ((m_1) => (new CrudListModel$1(m_1.Items, m_1.MaybeSelected, m_1.SortInfo, m_1.SearchInfo, v)))];
}

export class CrudListMsg$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetItems", "GetItemsResult", "SelectItem", "UpdateSortInfo", "UpdateSearchInfo", "TriggerSearch"];
    }
}

export function CrudListMsg$1$reflection(gen0) {
    return union_type("Optiscan.SharedUI.Crud.CrudListMsg`1", [gen0], CrudListMsg$1, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(gen0), string_type], FSharpResult$2, () => [[["ResultValue", list_type(gen0)]], [["ErrorValue", string_type]]])]], [["item", gen0]], [["sortInfo", Sort_SortInfo$1$reflection(gen0)]], [["searchInfo", option_type(string_type)]], [["searchInfo", option_type(string_type)]]]);
}

export class CrudListInboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdatedItems"];
    }
}

export function CrudListInboundMsg$reflection() {
    return union_type("Optiscan.SharedUI.Crud.CrudListInboundMsg", [], CrudListInboundMsg, () => [[]]);
}

export class CrudDetailMsg {
    constructor() {
    }
}

export function CrudDetailMsg$reflection() {
    return class_type("Optiscan.SharedUI.Crud.CrudDetailMsg", void 0, CrudDetailMsg);
}

export class CrudEditMsg$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetValueEdit", "Save", "SaveResult"];
    }
}

export function CrudEditMsg$2$reflection(gen0, gen1) {
    return union_type("Optiscan.SharedUI.Crud.CrudEditMsg`2", [gen0, gen1], CrudEditMsg$2, () => [[["setter", lambda_type(gen0, gen0)]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, gen1], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", gen1]]])]]]);
}

export class CrudCreateMsg$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetValueCreate", "Create", "CreateResult"];
    }
}

export function CrudCreateMsg$2$reflection(gen0, gen1) {
    return union_type("Optiscan.SharedUI.Crud.CrudCreateMsg`2", [gen0, gen1], CrudCreateMsg$2, () => [[["setter", lambda_type(gen0, gen0)]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, gen1], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", gen1]]])]]]);
}

export class CrudDeleteMsg$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Delete", "DeleteResult"];
    }
}

export function CrudDeleteMsg$2$reflection(gen0, gen1) {
    return union_type("Optiscan.SharedUI.Crud.CrudDeleteMsg`2", [gen0, gen1], CrudDeleteMsg$2, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, gen1], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", gen1]]])]]]);
}

export function CrudButton_navigateToChild(child, text, style, dispatch) {
    return button(toList(delay(() => append(singleton(new Option(18, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(4, child)));
    })), delay(() => style)))), singleton_1(text));
}

export function CrudButton_navigateToParent(text, style, dispatch) {
    return button(toList(delay(() => append(singleton(new Option(18, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })), delay(() => style)))), singleton_1(text));
}

export function CrudButton_nodeMsg(msg, text, style, dispatch) {
    return button(toList(delay(() => append(singleton(new Option(18, (_arg1) => {
        dispatch(new StateTreeMsg$4(0, msg));
    })), delay(() => style)))), singleton_1(text));
}

export function CrudButton_create(itemName, dispatch) {
    return CrudButton_navigateToChild("create", `Create ${itemName}`, [new Option(0, new Color_IColor(4))], dispatch);
}

export function CrudButton_edit(dispatch) {
    return CrudButton_navigateToChild("edit", "Edit", [new Option(0, new Color_IColor(4))], dispatch);
}

export function CrudButton_delete(dispatch) {
    return CrudButton_navigateToChild("delete", "Delete", [new Option(0, new Color_IColor(8))], dispatch);
}

export function CrudButton_back(dispatch) {
    return CrudButton_navigateToParent("Back", [new Option(19, "is-secondary")], dispatch);
}

export function CrudButton_cancel(dispatch) {
    return CrudButton_navigateToParent("Cancel", [new Option(19, "is-secondary")], dispatch);
}

export function CrudButton_save(mapMsg, validated, dispatch) {
    if (validated.tag === 1) {
        return CrudButton_nodeMsg(mapMsg(new CrudEditMsg$2(1)), "Save", [new Option(0, new Color_IColor(6)), new Option(16, true), new Option(17, singleton_1(new HTMLAttr(158, validated.fields[0])))], dispatch);
    }
    else {
        return CrudButton_nodeMsg(mapMsg(new CrudEditMsg$2(1)), "Save", [new Option(0, new Color_IColor(6))], dispatch);
    }
}

export function crudModal(title, dispatch, body, footer) {
    return modal(singleton_1(new Option_1(1, true)), ofArray([background(singleton_1(new Common_GenericOption(1, singleton_1(new DOMAttr(40, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(3, "list")));
    })))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton_1(title)), Card_body(empty(), body), Card_foot(empty(), footer)]))]));
}

export function allColumnSearchModifier(searchInfo, fields, crudItems) {
    return filter((item_1) => {
        let _arg1, search_2;
        return defaultArg(map((search) => exists((column) => {
            let cellContents;
            const matchValue = column.mkCellContent(item_1);
            cellContents = ((matchValue === null) ? "" : matchValue.toLocaleLowerCase().trim());
            return cellContents.indexOf(search.toLocaleLowerCase().trim()) >= 0;
        }, fields), (_arg1 = searchInfo, (_arg1 != null) ? (isNullOrWhiteSpace(_arg1) ? ((search_2 = _arg1, void 0)) : ((_arg1 != null) ? _arg1 : (void 0))) : ((_arg1 != null) ? _arg1 : (void 0)))), true);
    }, crudItems);
}

export function crudListState(listTitle, itemName, columns, getItems, canCreate) {
    if (length(columns) > 0) {
        return new StateTreeNode$6((_arg1) => {
            let comparer;
            const initialSortInfo = new Sort_SortInfo$1(Sort_SortableColumn$1_toSortableColumnView_45D2C9EC(item_2(0, columns)), new Sort_SortDirection(0));
            return [new CrudListModel$1(empty(), void 0, initialSortInfo, void 0, singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer = Sort_SortInfo$1_getSortComparison_576B9F7E(initialSortInfo), (list) => sortWith(uncurry(2, comparer), list))))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new CrudListMsg$1(0)))];
        }, (model, dispatch) => {
            let children;
            return card(singleton_1(new Common_GenericOption(0, "table-card")), ofArray([header(empty(), toList(delay(() => append(singleton(Header_title(empty(), singleton_1(listTitle))), delay(() => append(singleton(Header_icon(empty(), singleton_1(defaultSearchComponent(listTitle, model.SearchInfo, (arg_1) => {
                dispatch(new StateTreeMsg$4(0, new CrudListMsg$1(4, arg_1)));
            }, (arg_3) => {
                dispatch(new StateTreeMsg$4(0, new CrudListMsg$1(5, arg_3)));
            })))), delay(() => (canCreate ? singleton(Header_icon(empty(), singleton_1(CrudButton_create(itemName, dispatch)))) : empty_1())))))))), content(empty(), singleton_1((children = [Sort_itemListSortable(itemName, columns)((item) => ((_arg2) => {
                dispatch(new StateTreeMsg$4(0, new CrudListMsg$1(2, item)));
            }))(ListDisplayModification_ListDisplayModification$2_applyListModifications(model.ListModifications, model.Items))((_arg3) => empty_1())(model.SortInfo)((sortInfo) => ((_arg4) => {
                dispatch(new StateTreeMsg$4(0, new CrudListMsg$1(3, sortInfo)));
            }))], react.createElement("div", {
                className: "table-container",
            }, ...children))))]));
        }, (msg_2, tupledArg, _arg5, model_1) => ((msg_2.tag === 1) ? ((msg_2.fields[0].tag === 1) ? [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(2)), error(msg_2.fields[0].fields[0])]))] : [new CrudListModel$1(msg_2.fields[0].fields[0], model_1.MaybeSelected, model_1.SortInfo, model_1.SearchInfo, model_1.ListModifications), Cmd_OfFunc_result(new StateTreeMsg$4(2))]) : ((msg_2.tag === 2) ? [new CrudListModel$1(model_1.Items, some(msg_2.fields[0]), model_1.SortInfo, model_1.SearchInfo, model_1.ListModifications), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "detail")))] : ((msg_2.tag === 3) ? [new CrudListModel$1(model_1.Items, model_1.MaybeSelected, msg_2.fields[0], model_1.SearchInfo, toList(delay(() => append(filter((modification) => (!equals(modification.Source, new CrudListModification(0))), model_1.ListModifications), delay(() => {
            let comparer_1;
            return singleton(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer_1 = Sort_SortInfo$1_getSortComparison_576B9F7E(msg_2.fields[0]), (list_2) => sortWith(uncurry(2, comparer_1), list_2))));
        }))))), Cmd_none()] : ((msg_2.tag === 4) ? [new CrudListModel$1(model_1.Items, model_1.MaybeSelected, model_1.SortInfo, msg_2.fields[0], model_1.ListModifications), Cmd_none()] : ((msg_2.tag === 5) ? [new CrudListModel$1(model_1.Items, model_1.MaybeSelected, model_1.SortInfo, msg_2.fields[0], toList(delay(() => append(filter((modification_1) => (!equals(modification_1.Source, new CrudListModification(1))), model_1.ListModifications), delay(() => singleton(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(1), 0, (crudItems) => allColumnSearchModifier(model_1.SearchInfo, columns, crudItems)))))))), Cmd_none()] : [model_1, Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, getItems, [tupledArg[0], tupledArg[1]], (arg_4) => (new StateTreeMsg$4(0, new CrudListMsg$1(1, arg_4))), (arg0_9) => (new StateTreeMsg$4(1, arg0_9)))]))))));
    }
    else {
        throw (new Error("Tried to create a crud list with no columns"));
    }
}

export function crudListInbound(msg, _arg1, model) {
    return [model, Cmd_OfFunc_result(new StateTreeMsg$4(0, new CrudListMsg$1(0)))];
}

export function crudDetailState() {
    return new StateTreeNode$6((deps) => {
        const matchValue = deps.MaybeSelected;
        return (matchValue == null) ? [item_2(0, deps.Items), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))] : [value_1(matchValue), Cmd_OfFunc_result(new StateTreeMsg$4(2))];
    }, (model, dispatch) => Helpers_nothing, (msg_4, tupledArg, deps_1, model_1) => [model_1, Cmd_none()]);
}

export function crudEditUpdate(mapMsg, saveItem, exceptionMapper, successToastMessage, errorToastMessageMap, msg, model) {
    if (msg.tag === 1) {
        return [model, Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, saveItem, model, (arg_1) => (new StateTreeMsg$4(0, mapMsg(new CrudEditMsg$2(2, arg_1)))), (arg_5) => (new StateTreeMsg$4(0, mapMsg(new CrudEditMsg$2(2, new FSharpResult$2(1, exceptionMapper(arg_5)))))))];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [model, Cmd_batch(ofArray([error(errorToastMessageMap(msg.fields[0].fields[0])), Cmd_OfFunc_result(new StateTreeMsg$4(1, msg.fields[0].fields[0]))]))];
        }
        else {
            return [model, Cmd_batch(ofArray([success(successToastMessage), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(3, "list")))]))];
        }
    }
    else {
        return [msg.fields[0](model), Cmd_none()];
    }
}

