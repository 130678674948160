import { date as date_2, minute, hour, day, month, year, create } from "../../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { toArray, map, defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { AsyncResult_map, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotNullOrEmpty } from "../../../fable_modules/Webbler.Models.1.2.6/Validation.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { Model_get_timeZone_, Model_get_time_, Model_get_date_, Model_get_sessionName_, Msg, Model } from "./PatientSessionsTypes.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { StateTreeNode$6, StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { crudEditUpdate, CrudButton_cancel, CrudButton_save, CrudEditMsg$2, crudModal } from "../../../Optiscan.SharedUI/Crud.js";
import { empty as empty_1, iterate, singleton, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single, FormField_onChangeDateOpticLocal, FormField_date, FormField_onChangeSet, FormField_text } from "../../../Optiscan.SharedUI/Forms.js";
import { tryFind, ofArray, empty } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { uncurry } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { rangeDouble } from "../../../fable_modules/fable-library.3.7.12/Range.js";
import { interpolate, toText } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { SelectPropsMulti$1 } from "../../../Optiscan.SharedUI/bindings/ReactSelectBind.js";
import { Option } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { error } from "../../../Optiscan.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../../../Optiscan.Models/ErrorMessage.js";
import { getCurrentTimezoneName } from "../../../../../src/Optiscan.Client/Patients/PatientDetails/PatientSessions/js/PatientSessionsHelper.tsx";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../../../Api.js";
import { SessionCreateViewModel } from "../../../Optiscan.Models/View/Session.js";
import { Common_eraseExceptionToError } from "../../../Common/Common.js";

export function trySetTime(time, date) {
    return create(year(date), month(date), day(date), defaultArg(map((s) => (~(~(s / 2))), time), 0), defaultArg(map((s_1) => {
        if ((s_1 % 2) === 0) {
            return 0;
        }
        else {
            return 30;
        }
    }, time), 0), 0, date.offset);
}

export function trySetTimeZone(timezone, date) {
    return create(year(date), month(date), day(date), hour(date), minute(date), 0, defaultArg(map((s) => s.offset, timezone), 0));
}

export function canSaveEditedSession(session) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Session name cannot be empty", session.sessionName), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (session.date == null) ? (new FSharpResult$2(1, "Start date cannot be empty")) : (new FSharpResult$2(0, void 0)), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (session.time == null) ? (new FSharpResult$2(1, "Start time cannot be empty")) : (new FSharpResult$2(0, void 0)), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (session.timeZone == null) ? (new FSharpResult$2(1, "Time zone cannot be empty")) : (new FSharpResult$2(0, void 0)), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result)))))));
}

export function sessionCreateState() {
    return new StateTreeNode$6((deps) => [new Model(void 0, "", void 0, void 0, void 0), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => crudModal("Schedule Session", dispatch, toList(delay(() => {
        let f, optic;
        const setter = (arg_2) => {
            dispatch(new StateTreeMsg$4(0, new Msg(2, new CrudEditMsg$2(0, arg_2))));
        };
        return append(singleton(FormField_text(model.sessionName, empty(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = Model_get_sessionName_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "PatientSession", "Session Name")([])), delay(() => {
            let optic_2;
            return append(singleton(FormField_date(model.date, [], [], FormField_onChangeDateOpticLocal(setter, (optic_2 = Model_get_date_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target)), uncurry(2, (maybeDateTimeOffset) => {
                iterate((offset) => {
                    dispatch(new StateTreeMsg$4(0, new Msg(0, date_2(offset))));
                }, toArray(maybeDateTimeOffset));
                return Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), Model_get_date_())(maybeDateTimeOffset);
            })), "PatientSession", "Start Date")([])), delay(() => {
                let f1_3, optic_6;
                return (model.date != null) ? append(singleton(FormField_ReactSelect_single(toList(rangeDouble(0, 1, 48)), [], (index) => (((index % 2) === 0) ? toText(interpolate("%02i%P():00", [~(~(index / 2))])) : toText(interpolate("%02i%P():30", [~(~(index / 2))]))), model.time, new FormField_ReactSelect_CallbackSingle$1(0, (f1_3 = ((optic_6 = Model_get_time_(), (value_4) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_6)(value_4))), (arg_3) => {
                    setter(f1_3(arg_3));
                })), "Start Time", true, "PatientSession", [])), delay(() => {
                    let f1_4, optic_8;
                    return singleton(FormField_ReactSelect_single(defaultArg(model.timeZones, empty()), [new SelectPropsMulti$1(11, model.timeZones == null)], (zone) => zone.label, model.timeZone, new FormField_ReactSelect_CallbackSingle$1(0, (f1_4 = ((optic_8 = Model_get_timeZone_(), (value_7) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_8)(value_7))), (arg_4) => {
                        setter(f1_4(arg_4));
                    })), "Time Zone", true, "PatientSession", toList(delay(() => ((model.timeZones == null) ? singleton(new Option(10, "is-loading")) : empty_1())))));
                })) : empty_1();
            }));
        }));
    })), ofArray([CrudButton_save((x) => x, canSaveEditedSession(model), (_arg1) => {
        if (_arg1.tag === 0) {
            dispatch(new StateTreeMsg$4(0, new Msg(2, _arg1.fields[0])));
        }
    }), CrudButton_cancel(dispatch)])), uncurry(4, (msg_2) => ((tupledArg) => {
        const token = tupledArg[0];
        return (deps_1) => ((newModel) => {
            if (msg_2.tag === 1) {
                if (msg_2.fields[0].tag === 1) {
                    return [newModel, error(ErrorMessage_get_describe()(msg_2.fields[0].fields[0]))];
                }
                else {
                    const currentTimezoneName = getCurrentTimezoneName();
                    return [new Model(msg_2.fields[0].fields[0], newModel.sessionName, newModel.date, newModel.time, tryFind((zone_1) => {
                        if (zone_1.standardName === currentTimezoneName) {
                            return true;
                        }
                        else {
                            return zone_1.daylightName === currentTimezoneName;
                        }
                    }, msg_2.fields[0].fields[0])), Cmd_none()];
                }
            }
            else {
                return (msg_2.tag === 2) ? crudEditUpdate((arg0_15) => (new Msg(2, arg0_15)), (model_1) => AsyncResult_map(() => {
                }, securedApi(token).createSession(new SessionCreateViewModel(deps_1.patient.id, model_1.sessionName, map((date_1) => trySetTimeZone(model_1.timeZone, date_1), map((date) => trySetTime(model_1.time, date), model_1.date))))), Common_eraseExceptionToError, "Session created.", ErrorMessage_get_describe(), msg_2.fields[0], newModel) : [new Model(void 0, newModel.sessionName, newModel.date, newModel.time, void 0), Cmd_OfAsyncWith_either((x_1) => {
                    Cmd_OfAsync_start(x_1);
                }, securedApi(token).getAdjustedTimezones, msg_2.fields[0], (arg_5) => (new StateTreeMsg$4(0, new Msg(1, arg_5))), (arg_6) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_6))))];
            }
        });
    })));
}

