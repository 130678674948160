import { Union, Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, lambda_type, record_type, option_type, string_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { GuestLinkResponse$reflection } from "../../../Optiscan.Models/View/GuestLink.js";
import { ErrorMessage$reflection } from "../../../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";

export class CreateModel extends Record {
    constructor(guestEmails, password, guestLink) {
        super();
        this.guestEmails = guestEmails;
        this.password = password;
        this.guestLink = guestLink;
    }
}

export function CreateModel$reflection() {
    return record_type("Client.Patients.PatientGuestLinks.Types.CreateModel", [], CreateModel, () => [["guestEmails", string_type], ["password", string_type], ["guestLink", option_type(string_type)]]);
}

export function CreateModel_get_guestEmails_() {
    return [(m) => m.guestEmails, (v) => ((m_1) => (new CreateModel(v, m_1.password, m_1.guestLink)))];
}

export function CreateModel_get_password_() {
    return [(m) => m.password, (v) => ((m_1) => (new CreateModel(m_1.guestEmails, v, m_1.guestLink)))];
}

export class CreateMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetValue", "CreateGuestLink", "CreateGuestLinkResult", "Cancel"];
    }
}

export function CreateMsg$reflection() {
    return union_type("Client.Patients.PatientGuestLinks.Types.CreateMsg", [], CreateMsg, () => [[["setter", lambda_type(CreateModel$reflection(), CreateModel$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GuestLinkResponse$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", GuestLinkResponse$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], []]);
}

export class ShowModel extends Record {
    constructor(guestLink) {
        super();
        this.guestLink = guestLink;
    }
}

export function ShowModel$reflection() {
    return record_type("Client.Patients.PatientGuestLinks.Types.ShowModel", [], ShowModel, () => [["guestLink", option_type(string_type)]]);
}

