import { Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Naming_UIElement, Naming_UIElement__get_asString, Naming_mkIdFromStrings } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { empty, singleton, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_1, div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_2, IInputType, input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { String_fromOption } from "../fable_modules/Webbler.Models.1.2.6/Extensions.fs.js";
import { defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { FormField_resetCall, FormField_handleStringUpdate } from "./Forms.js";
import { Option as Option_3, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";
import { Option as Option_4, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.12/String.js";

export class TableSearch extends Record {
    constructor(search, identifier) {
        super();
        this.search = search;
        this.identifier = identifier;
    }
}

export function TableSearch$reflection() {
    return record_type("Optiscan.SharedUI.Search.TableSearch", [], TableSearch, () => [["search", option_type(string_type)], ["identifier", string_type]]);
}

export function TableSearch_get_search_() {
    return [(m) => m.search, (v) => ((m_1) => (new TableSearch(v, m_1.identifier)))];
}

export function buttonTriggerSearchComponent(headerTitle, search, placeholder, searchButtonLabel, updateSearch, triggerSearch) {
    let _arg1;
    const searchBarId = Naming_mkIdFromStrings(ofArray([headerTitle, "Search Bar", Naming_UIElement__get_asString(new Naming_UIElement(0))]));
    return div(singleton(new Option(0)), ofArray([div_1(singleton(new Option_1(1)), ofArray([input(ofArray([new Option_2(1, new IInputType(0)), new Option_2(10, String_fromOption(search)), new Option_2(12, defaultArg(placeholder, "Search")), new Option_2(15, ofArray([new HTMLAttr(99, Naming_mkIdFromStrings(ofArray([headerTitle, "Search Bar", Naming_UIElement__get_asString(new Naming_UIElement(0))]))), new DOMAttr(9, (arg) => {
        updateSearch(FormField_handleStringUpdate(arg));
    })]))])), icon(singleton(new Option_3(1)), toList(delay(() => {
        const searchIcon = document.getElementById("app-container").classList.contains("theme-light") ? "search.svg" : "search-light.svg";
        return singleton_1(react.createElement("img", {
            src: searchIcon,
        }));
    })))])), div_1(empty(), singleton(button(singleton(new Option_4(17, ofArray([new HTMLAttr(99, Naming_mkIdFromStrings(ofArray([headerTitle, "Search Bar Cancel", Naming_UIElement__get_asString(new Naming_UIElement(0))]))), new DOMAttr(40, (_e) => {
        const value = FormField_resetCall(searchBarId);
        updateSearch(void 0);
        triggerSearch();
    })]))), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-times")), []))))))), div(singleton(new Option(11, singleton(["style", {
        marginLeft: "auto",
    }]))), singleton(div_1(empty(), singleton(button(ofArray([new Option_4(17, ofArray([new HTMLAttr(99, Naming_mkIdFromStrings(ofArray([headerTitle, "Search Bar Trigger", Naming_UIElement__get_asString(new Naming_UIElement(0))]))), new DOMAttr(40, (_e_1) => {
        triggerSearch();
    })])), new Option_4(16, (_arg1 = search, (_arg1 != null) ? isNullOrWhiteSpace(_arg1) : true))]), singleton(defaultArg(searchButtonLabel, "Search")))))))]));
}

export function defaultSearchComponent(headerTitle, search, updateSearch, triggerSearch) {
    const searchBarId = Naming_mkIdFromStrings(ofArray([headerTitle, "Search Bar", Naming_UIElement__get_asString(new Naming_UIElement(0))]));
    return div(singleton(new Option(0)), ofArray([div_1(singleton(new Option_1(1)), ofArray([input(ofArray([new Option_2(1, new IInputType(0)), new Option_2(10, String_fromOption(search)), new Option_2(12, "Search"), new Option_2(15, ofArray([new DOMAttr(17, (_e_1) => {
        triggerSearch(search);
    }), new HTMLAttr(99, Naming_mkIdFromStrings(ofArray([headerTitle, "Search Bar", Naming_UIElement__get_asString(new Naming_UIElement(0))]))), new DOMAttr(9, (arg) => {
        updateSearch(FormField_handleStringUpdate(arg));
    })]))])), icon(singleton(new Option_3(1)), toList(delay(() => {
        const searchIcon = document.getElementById("app-container").classList.contains("theme-light") ? "search.svg" : "search-light.svg";
        return singleton_1(react.createElement("img", {
            src: searchIcon,
        }));
    })))])), div_1(empty(), singleton(button(singleton(new Option_4(17, ofArray([new HTMLAttr(99, Naming_mkIdFromStrings(ofArray([headerTitle, "Search Bar Cancel", Naming_UIElement__get_asString(new Naming_UIElement(0))]))), new DOMAttr(40, (_e) => {
        const value = FormField_resetCall(searchBarId);
        updateSearch(void 0);
        triggerSearch(void 0);
    })]))), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-times")), [])))))))]));
}

