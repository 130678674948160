import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, class_type, union_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";

export class Terminate_TerminateError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Generic", "Custom", "UncancellableOperation"];
    }
}

export function Terminate_TerminateError$reflection() {
    return union_type("Optiscan.Models.Shared.Terminate.TerminateError", [], Terminate_TerminateError, () => [[], [["errorMessage", string_type]], [["operation", string_type]]]);
}

export function Terminate_TerminateError_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? _arg1.fields[0] : ((_arg1.tag === 2) ? toText(printf("Unable to cancel %s operation"))(_arg1.fields[0]) : "Generic termination error"));
}

export class AssignedSiteId extends Record {
    constructor(siteId, accountId) {
        super();
        this.siteId = siteId;
        this.accountId = accountId;
    }
}

export function AssignedSiteId$reflection() {
    return record_type("Optiscan.Models.Shared.AssignedSiteId", [], AssignedSiteId, () => [["siteId", class_type("System.Guid")], ["accountId", class_type("System.Guid")]]);
}

export function AssignedSiteId_get_siteId_() {
    return [(asid) => asid.siteId, (sid) => ((asid_1) => (new AssignedSiteId(sid, asid_1.accountId)))];
}

export function AssignedSiteId_get_accountId_() {
    return [(asid) => asid.accountId, (aid) => ((asid_1) => (new AssignedSiteId(asid_1.siteId, aid)))];
}

