import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { Common_Name_create, Common_Name$reflection } from "../../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { minValue } from "../../fable_modules/fable-library.3.7.12/DateOffset.js";

export class PatientNoteViewModel extends Record {
    constructor(noteId, patientId, note, dateCreated, createdById, createdByName, maybeSessionId) {
        super();
        this.noteId = noteId;
        this.patientId = patientId;
        this.note = note;
        this.dateCreated = dateCreated;
        this.createdById = createdById;
        this.createdByName = createdByName;
        this.maybeSessionId = maybeSessionId;
    }
}

export function PatientNoteViewModel$reflection() {
    return record_type("Optiscan.Models.View.PatientNote.PatientNoteViewModel", [], PatientNoteViewModel, () => [["noteId", class_type("System.Guid")], ["patientId", class_type("System.Guid")], ["note", string_type], ["dateCreated", class_type("System.DateTimeOffset")], ["createdById", class_type("System.Guid")], ["createdByName", Common_Name$reflection()], ["maybeSessionId", option_type(class_type("System.Guid"))]]);
}

export function PatientNoteViewModel_get_empty() {
    return new PatientNoteViewModel("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "", minValue(), "00000000-0000-0000-0000-000000000000", Common_Name_create("", ""), void 0);
}

export function PatientNoteViewModel_get_noteId_() {
    return [(m) => m.noteId, (v) => ((m_1) => (new PatientNoteViewModel(v, m_1.patientId, m_1.note, m_1.dateCreated, m_1.createdById, m_1.createdByName, m_1.maybeSessionId)))];
}

export function PatientNoteViewModel_get_patientId_() {
    return [(m) => m.patientId, (v) => ((m_1) => (new PatientNoteViewModel(m_1.noteId, v, m_1.note, m_1.dateCreated, m_1.createdById, m_1.createdByName, m_1.maybeSessionId)))];
}

export function PatientNoteViewModel_get_note_() {
    return [(m) => m.note, (v) => ((m_1) => (new PatientNoteViewModel(m_1.noteId, m_1.patientId, v, m_1.dateCreated, m_1.createdById, m_1.createdByName, m_1.maybeSessionId)))];
}

export function PatientNoteViewModel_get_dateCreated_() {
    return [(m) => m.dateCreated, (v) => ((m_1) => (new PatientNoteViewModel(m_1.noteId, m_1.patientId, m_1.note, v, m_1.createdById, m_1.createdByName, m_1.maybeSessionId)))];
}

export function PatientNoteViewModel_get_createdById_() {
    return [(m) => m.createdById, (v) => ((m_1) => (new PatientNoteViewModel(m_1.noteId, m_1.patientId, m_1.note, m_1.dateCreated, v, m_1.createdByName, m_1.maybeSessionId)))];
}

export function PatientNoteViewModel_get_createdByName_() {
    return [(m) => m.createdByName, (v) => ((m_1) => (new PatientNoteViewModel(m_1.noteId, m_1.patientId, m_1.note, m_1.dateCreated, m_1.createdById, v, m_1.maybeSessionId)))];
}

export function PatientNoteViewModel_get_maybeSessionId_() {
    return [(m) => m.maybeSessionId, (v) => ((m_1) => (new PatientNoteViewModel(m_1.noteId, m_1.patientId, m_1.note, m_1.dateCreated, m_1.createdById, m_1.createdByName, v)))];
}

