import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { PatientViewModel$reflection } from "../Optiscan.Models/View/Patient.js";
import { SessionViewModel$reflection } from "../Optiscan.Models/View/Session.js";
import { enum_type, array_type, list_type, string_type, lambda_type, union_type, unit_type, tuple_type, int32_type, class_type, record_type, option_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { AnatomicalRegionViewModel$reflection } from "../Optiscan.Models/View/AnatomicalRegion.js";
import { ControlMessage$reflection } from "./TelepathologyBindings.js";

export class TelepathologyDependency extends Record {
    constructor(patient, activeSession) {
        super();
        this.patient = patient;
        this.activeSession = activeSession;
    }
}

export function TelepathologyDependency$reflection() {
    return record_type("Client.Telepathology.Types.TelepathologyDependency", [], TelepathologyDependency, () => [["patient", PatientViewModel$reflection()], ["activeSession", option_type(SessionViewModel$reflection())]]);
}

export class TelepathologyApi extends Record {
    constructor(flagSessionImage, getCurrentAnatomicalRegion) {
        super();
        this.flagSessionImage = flagSessionImage;
        this.getCurrentAnatomicalRegion = getCurrentAnatomicalRegion;
    }
}

export function TelepathologyApi$reflection() {
    return record_type("Client.Telepathology.Types.TelepathologyApi", [], TelepathologyApi, () => [["flagSessionImage", lambda_type(tuple_type(class_type("System.Guid"), int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage$reflection()]]])]))], ["getCurrentAnatomicalRegion", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(AnatomicalRegionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(AnatomicalRegionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]))]]);
}

export class StreamStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Connecting", "Connected", "Paused", "Stopped", "Failed"];
    }
}

export function StreamStatus$reflection() {
    return union_type("Client.Telepathology.Types.StreamStatus", [], StreamStatus, () => [[], [], [], [], []]);
}

export class WebRTCState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IceCandidate", "Signaling", "PeerConnection"];
    }
}

export function WebRTCState$reflection() {
    return union_type("Client.Telepathology.Types.WebRTCState", [], WebRTCState, () => [[], [], []]);
}

export class TelepathologyModel extends Record {
    constructor(patient, sessionId, sessionRegion, videoClient, signalingChannelsClient, signalingClient, channelARN, endpoints, iceServers, peerConnection, anatomicalRegion, streamStatus, streamStatusCancellationTokenSource, lastKnownSessionState, dataChannels) {
        super();
        this.patient = patient;
        this.sessionId = sessionId;
        this.sessionRegion = sessionRegion;
        this.videoClient = videoClient;
        this.signalingChannelsClient = signalingChannelsClient;
        this.signalingClient = signalingClient;
        this.channelARN = channelARN;
        this.endpoints = endpoints;
        this.iceServers = iceServers;
        this.peerConnection = peerConnection;
        this.anatomicalRegion = anatomicalRegion;
        this.streamStatus = streamStatus;
        this.streamStatusCancellationTokenSource = streamStatusCancellationTokenSource;
        this.lastKnownSessionState = (lastKnownSessionState | 0);
        this.dataChannels = dataChannels;
    }
}

export function TelepathologyModel$reflection() {
    return record_type("Client.Telepathology.Types.TelepathologyModel", [], TelepathologyModel, () => [["patient", PatientViewModel$reflection()], ["sessionId", class_type("System.Guid")], ["sessionRegion", string_type], ["videoClient", option_type(class_type("Fable.Import.Aws.KinesisVideo.KinesisVideo"))], ["signalingChannelsClient", option_type(class_type("Fable.Import.Aws.KinesisVideoSignalingChannels.KinesisVideoSignalingChannels"))], ["signalingClient", option_type(class_type("Fable.Import.Aws.SignalingClient.SignalingClient"))], ["channelARN", string_type], ["endpoints", list_type(class_type("Fable.Import.Aws.KinesisVideo.ResourceEndpointListItem"))], ["iceServers", array_type(class_type("Browser.Types.RTCIceServer"))], ["peerConnection", option_type(class_type("Browser.Types.RTCPeerConnection", void 0, RTCPeerConnection))], ["anatomicalRegion", option_type(AnatomicalRegionViewModel$reflection())], ["streamStatus", StreamStatus$reflection()], ["streamStatusCancellationTokenSource", option_type(class_type("System.Threading.CancellationTokenSource"))], ["lastKnownSessionState", enum_type("Optiscan.Models.Shared.SessionState", int32_type, [["Created", 0], ["Active", 1], ["Completed", 2], ["Interrupted", 3], ["Paused", 4]])], ["dataChannels", list_type(class_type("Browser.Types.RTCDataChannel", void 0, RTCDataChannel))]]);
}

export class TelepathologyMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "WebRTCExnErrorMsg", "Initialize", "StartViewer", "GotDescribeSignalingChannelOutput", "GotSignalingChannelEndpointOutput", "GotIceServerConfig", "FlagImage", "GotControlMessage", "GotAnatomicalRegion", "StartUpdateStreamStatus", "UpdateStreamStatus", "CancelUpdateStreamStatus", "StreamErrorMsg", "StartConnectionTimeout", "ConnectionTimeoutFinished", "HandlePeerConnectionDataChannel", "HandlePeerConnectionStateChange", "HandleIceCandidateConnectionStateChange"];
    }
}

export function TelepathologyMsg$reflection() {
    return union_type("Client.Telepathology.Types.TelepathologyMsg", [], TelepathologyMsg, () => [[["Item", class_type("System.Exception")]], [["Item", class_type("System.Exception")]], [], [], [["Item", class_type("Fable.Import.Aws.KinesisVideo.DescribeSignalingChannelOutput")]], [["Item", class_type("Fable.Import.Aws.KinesisVideo.GetSignalingChannelEndpointOutput")]], [["Item", class_type("Fable.Import.Aws.KinesisVideoSignalingChannels.GetIceServerConfigResponse")]], [["frameNumber", int32_type]], [["Item", ControlMessage$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(AnatomicalRegionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(AnatomicalRegionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", StreamStatus$reflection()]], [["Item", StreamStatus$reflection()]], [], [["Item", string_type]], [], [], [["Item", class_type("Browser.Types.RTCDataChannel", void 0, RTCDataChannel)]], [["Item", string_type]], [["Item", string_type]]]);
}

