"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@cornerstonejs/core");
var tools_1 = require("@cornerstonejs/tools");
var react_1 = __importStar(require("react"));
var CornerstoneDicomImageLoader_1 = require("./CornerstoneDicomImageLoader");
var react_menu_1 = require("@szhsin/react-menu");
var ViewportType = core_1.Enums.ViewportType;
var createCameraPositionSynchronizer = tools_1.synchronizers.createCameraPositionSynchronizer, createVOISynchronizer = tools_1.synchronizers.createVOISynchronizer;
var cameraTools = [
    tools_1.PanTool.toolName,
    tools_1.ZoomTool.toolName,
    tools_1.WindowLevelTool.toolName,
];
var annotationTools = [
    tools_1.EllipticalROITool.toolName,
    tools_1.CircleROITool.toolName,
    tools_1.LengthTool.toolName,
    tools_1.ArrowAnnotateTool.toolName,
    tools_1.PlanarFreehandROITool.toolName
];
var toolGroupId = 'toolGroup';
var toolGroup = tools_1.ToolGroupManager.createToolGroup(toolGroupId);
function addTools() {
    var tools = __spreadArray(__spreadArray([], cameraTools, true), annotationTools, true);
    for (var i in tools) {
        toolGroup.addTool(tools[i]);
    }
    toolGroup === null || toolGroup === void 0 ? void 0 : toolGroup.setToolConfiguration(tools_1.ArrowAnnotateTool.toolName, {
        getTextCallback: function (callback) { return callback('   '); },
        changeTextCallback: function (data, eventData, callback) { return callback(data.data.text); },
    });
}
function disableCameraTools() {
    for (var i in cameraTools) {
        toolGroup.setToolDisabled(cameraTools[i]);
    }
}
function enableAnnotationTools() {
    for (var i in annotationTools) {
        toolGroup.setToolEnabled(annotationTools[i]);
    }
}
function activateTool(toolName) {
    toolGroup.setToolEnabled(toolName);
    toolGroup.setToolActive(toolName, {
        bindings: [
            {
                mouseButton: tools_1.Enums.MouseBindings.Primary, // Left Click
            },
        ],
    });
}
var initializedGlobally = false;
//const cameraSyncId = 'cameraSync';
function globallyInitializeCornerstone() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!initializedGlobally) return [3 /*break*/, 1];
                    return [2 /*return*/];
                case 1:
                    initializedGlobally = true;
                    // Globally initialize cornerstone
                    (0, CornerstoneDicomImageLoader_1.initCornerstoneDicomImageLoader)();
                    return [4 /*yield*/, (0, core_1.init)()];
                case 2:
                    _a.sent();
                    (0, tools_1.init)();
                    (0, tools_1.addTool)(tools_1.PanTool);
                    (0, tools_1.addTool)(tools_1.ZoomTool);
                    (0, tools_1.addTool)(tools_1.WindowLevelTool);
                    (0, tools_1.addTool)(tools_1.EllipticalROITool);
                    (0, tools_1.addTool)(tools_1.CircleROITool);
                    (0, tools_1.addTool)(tools_1.LengthTool);
                    (0, tools_1.addTool)(tools_1.ArrowAnnotateTool);
                    (0, tools_1.addTool)(tools_1.PlanarFreehandROITool);
                    addTools();
                    enableAnnotationTools();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
function initializeCornerstone(constants) {
    return __awaiter(this, void 0, void 0, function () {
        var cornerstoneObjects;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, globallyInitializeCornerstone()];
                case 1:
                    _a.sent();
                    cornerstoneObjects = {
                        renderingEngine: new core_1.RenderingEngine(constants.renderingEngineId)
                    };
                    addTools();
                    enableAnnotationTools();
                    return [2 /*return*/, cornerstoneObjects];
            }
        });
    });
}
function activatePanTool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.PanTool.toolName);
            return [2 /*return*/];
        });
    });
}
function activateZoomTool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.ZoomTool.toolName);
            return [2 /*return*/];
        });
    });
}
function activateWindowLevelTool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.WindowLevelTool.toolName);
            return [2 /*return*/];
        });
    });
}
function activateCircleROITool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.CircleROITool.toolName);
            return [2 /*return*/];
        });
    });
}
function activateEllipticalROITool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.EllipticalROITool.toolName);
            return [2 /*return*/];
        });
    });
}
function activateLengthTool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.LengthTool.toolName);
            return [2 /*return*/];
        });
    });
}
function activateArrowAnnotateTool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.ArrowAnnotateTool.toolName);
            return [2 /*return*/];
        });
    });
}
function activatePlanarFreehandROITool() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            disableCameraTools();
            enableAnnotationTools();
            activateTool(tools_1.PlanarFreehandROITool.toolName);
            return [2 /*return*/];
        });
    });
}
//function addCameraSyncronizer(constants: CornerstoneConstants) {
//    const cameraSynchronizer = SynchronizerManager.getSynchronizer(cameraSyncId);
//    if (cameraSynchronizer) {
//        cameraSynchronizer.add({
//            renderingEngineId: constants.renderingEngineId,
//            viewportId: constants.viewportId
//        });
//    }
//}
//function removeCameraSyncronizer(constants: CornerstoneConstants) {
//    const cameraSynchronizer = SynchronizerManager.getSynchronizer(cameraSyncId);
//    if (cameraSynchronizer) {
//        cameraSynchronizer.remove({
//            renderingEngineId: constants.renderingEngineId,
//            viewportId: constants.viewportId
//        });
//    }
//}
function setupCornerstoneViewport(constants, cornerstoneObjectsPromise, element, imageIds, annotations, initialFrame, loadedDicomFileNames) {
    return __awaiter(this, void 0, void 0, function () {
        var viewportInput, cornerstoneObjects, viewport, currentAnnotations, loadedDicomFileIndexes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    element.oncontextmenu = function (e) { return e.preventDefault(); };
                    viewportInput = {
                        viewportId: constants.viewportId,
                        element: element,
                        type: ViewportType.STACK,
                    };
                    return [4 /*yield*/, cornerstoneObjectsPromise];
                case 1:
                    cornerstoneObjects = _a.sent();
                    // Enable the DOM Element for use with Cornerstone
                    cornerstoneObjects.renderingEngine.enableElement(viewportInput);
                    viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
                    return [4 /*yield*/, viewport.setStack(imageIds, initialFrame)];
                case 2:
                    _a.sent();
                    viewport.render();
                    toolGroup.addViewport(constants.viewportId, constants.renderingEngineId);
                    currentAnnotations = tools_1.annotation.state.getAllAnnotations();
                    loadedDicomFileIndexes = loadedDicomFileNames.map(function (fileName) { return CornerstoneDicomImageLoader_1.fileToIndexMap.get(fileName); });
                    currentAnnotations.forEach(function (a) {
                        // If the annotation isn't in any of the loaded images then clear it
                        if (!loadedDicomFileIndexes.includes(a.metadata.referencedImageId)) {
                            tools_1.annotation.state.removeAnnotation(a.annotationUID);
                        }
                    });
                    annotations.forEach(function (a) {
                        if (tools_1.annotation.state.getAnnotation(a.annotationUID) == null) {
                            var fileName = a.metadata.referencedImageId;
                            var imageIndex = CornerstoneDicomImageLoader_1.fileToIndexMap.get(fileName);
                            a.metadata.referencedImageId = imageIndex;
                            tools_1.annotation.state.addAnnotation(a, element);
                        }
                    });
                    activatePanTool();
                    //addCameraSyncronizer(constants);
                    return [2 /*return*/, viewport];
            }
        });
    });
}
function teardownCornerstoneViewport(constants, cornerstoneObjectsPromise) {
    return __awaiter(this, void 0, void 0, function () {
        var cornerstoneObjects;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cornerstoneObjectsPromise];
                case 1:
                    cornerstoneObjects = _a.sent();
                    cornerstoneObjects.renderingEngine.disableElement(constants.viewportId);
                    //removeCameraSyncronizer(constants);
                    // Why are the arguments of this function in a different order from addViewport?
                    // also why is it pluralized?
                    toolGroup.removeViewports(constants.renderingEngineId, constants.viewportId);
                    return [2 /*return*/];
            }
        });
    });
}
function setFrame(constants, cornerstoneObjectsPromise, frameEvent) {
    return __awaiter(this, void 0, void 0, function () {
        var cornerstoneObjects, viewport;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(frameEvent.viewportIndex === constants.viewportIndex)) return [3 /*break*/, 3];
                    return [4 /*yield*/, cornerstoneObjectsPromise];
                case 1:
                    cornerstoneObjects = _a.sent();
                    viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
                    return [4 /*yield*/, viewport.setImageIdIndex(frameEvent.frameIndex)];
                case 2:
                    _a.sent();
                    viewport.render();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
function saveAnnotation(annotation, handler) {
    if ('onInterpolationComplete' in annotation) {
        delete annotation.onInterpolationComplete;
    }
    var annotationData = structuredClone(annotation);
    var imageIndex = annotationData.metadata.referencedImageId;
    var fileName = CornerstoneDicomImageLoader_1.indexToFileMap.get(imageIndex);
    var toolName = annotation.metadata.toolName == tools_1.PlanarFreehandROITool.toolName ? "Freehand ROI Tool" : annotation.metadata.toolName;
    //This is recalculated on load so we don't need to save it
    delete annotationData.data.cachedStats;
    annotationData.metadata.referencedImageId = fileName;
    handler({
        annotationUID: annotation.annotationUID,
        annotationData: JSON.stringify(annotationData),
        toolName: toolName
    });
}
function updateAnnotation(cornerstoneObjectsPromise, constants, annotation, handler) {
    return __awaiter(this, void 0, void 0, function () {
        var cornerstoneObjects, viewport, annotationData, imageIndex, fileName, toolName;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cornerstoneObjectsPromise];
                case 1:
                    cornerstoneObjects = _a.sent();
                    viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
                    viewport.render();
                    if ('onInterpolationComplete' in annotation) {
                        delete annotation.onInterpolationComplete;
                    }
                    annotationData = structuredClone(annotation);
                    imageIndex = annotationData.metadata.referencedImageId;
                    fileName = CornerstoneDicomImageLoader_1.indexToFileMap.get(imageIndex);
                    toolName = annotation.metadata.toolName == tools_1.PlanarFreehandROITool.toolName ? "Freehand ROI Tool" : annotation.metadata.toolName;
                    //This is recalculated on load so we don't need to save it
                    delete annotationData.data.cachedStats;
                    annotationData.metadata.referencedImageId = fileName;
                    handler({
                        annotationUID: annotation.annotationUID,
                        annotationData: JSON.stringify(annotationData),
                        toolName: toolName
                    });
                    return [2 /*return*/];
            }
        });
    });
}
;
function selectAnnotation(annotationUID) {
    try {
        tools_1.annotation.selection.deselectAnnotation(); //no arguments de-selects all annotations
        tools_1.annotation.selection.setAnnotationSelected(annotationUID, true);
        var targetAnnotation = tools_1.annotation.state.getAnnotation(annotationUID);
        var toolToEnable = targetAnnotation.metadata.toolName;
        disableCameraTools();
        enableAnnotationTools();
        activateTool(toolToEnable);
    }
    catch (e) {
        if (e instanceof Error) {
            console.error("Unable to select annotation: ".concat(e));
        }
    }
}
function removeAnnotation(constants, cornerstoneObjectsPromise, annotationUID) {
    return __awaiter(this, void 0, void 0, function () {
        var cornerstoneObjects, viewport;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cornerstoneObjectsPromise];
                case 1:
                    cornerstoneObjects = _a.sent();
                    viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
                    tools_1.annotation.state.removeAnnotation(annotationUID);
                    viewport.render();
                    return [2 /*return*/];
            }
        });
    });
}
function setAnnotationText(constants, cornerstoneObjectsPromise, eventDetail) {
    return __awaiter(this, void 0, Promise, function () {
        var cornerstoneObjects, viewport, targetAnnotation;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cornerstoneObjectsPromise];
                case 1:
                    cornerstoneObjects = _a.sent();
                    viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
                    targetAnnotation = tools_1.annotation.state.getAnnotation(eventDetail.annotationUID);
                    //Other annotations don't have text but setting it shouldn't cause any issues
                    targetAnnotation.data.text = eventDetail.annotationText;
                    viewport.render();
                    return [2 /*return*/, targetAnnotation];
            }
        });
    });
}
var HistoricalView = function (_a) {
    var viewportIndex = _a.viewportIndex, initialFrame = _a.initialFrame, dicomFiles = _a.dicomFiles, loadedDicomFileNames = _a.loadedDicomFileNames, onDicomFilesLoaded = _a.onDicomFilesLoaded, doStartPlayback = _a.doStartPlayback, doPausePlayback = _a.doPausePlayback, eventNames = _a.eventNames, toggleFlagged = _a.toggleFlagged, saveAnnotationHandler = _a.saveAnnotationHandler, updateAnnotationHandler = _a.updateAnnotationHandler, setSelectedAnnotation = _a.setSelectedAnnotation, annotations = _a.annotations, isSingleView = _a.isSingleView;
    var containerRef = (0, react_1.useRef)(null);
    var context = (0, react_1.useRef)(null);
    var constantsRef = (0, react_1.useRef)(null);
    var cornerstoneObjectsPromiseRef = (0, react_1.useRef)(null);
    var resizeObserverRef = (0, react_1.useRef)(new ResizeObserver((function (_) { return __awaiter(void 0, void 0, void 0, function () {
        var cornerstoneObjects;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cornerstoneObjectsPromiseRef.current];
                case 1:
                    cornerstoneObjects = _a.sent();
                    cornerstoneObjects.renderingEngine.resize();
                    return [2 /*return*/];
            }
        });
    }); })));
    var _b = (0, react_1.useState)(false), isOpen = _b[0], setOpen = _b[1];
    var _c = (0, react_1.useState)({ x: 0, y: 0 }), anchorPoint = _c[0], setAnchorPoint = _c[1];
    var parsedAnnotations = annotations.map(function (annotation) { return JSON.parse(annotation); });
    var loadDicoms = function (files) {
        var imageIds = (0, CornerstoneDicomImageLoader_1.addFilesToDicomLoader)(files);
        var viewportPromise = setupCornerstoneViewport(constantsRef.current, cornerstoneObjectsPromiseRef.current, containerRef.current, imageIds, parsedAnnotations, initialFrame, loadedDicomFileNames);
        viewportPromise.then(onDicomFilesLoaded);
        return viewportPromise;
    };
    var lastAnnotation = null;
    var setFrameEventHandler = function (e) { setFrame(constantsRef.current, cornerstoneObjectsPromiseRef.current, e.detail); };
    ;
    var selectAnnotationHandler = function (e) { selectAnnotation(e.detail); };
    var removeAnnotationHandler = function (e) { removeAnnotation(constantsRef.current, cornerstoneObjectsPromiseRef.current, e.detail); lastAnnotation = null; };
    var setSelectedAnnotationHandler = function (e) { return setSelectedAnnotation(e.detail.added[0]); };
    var setAnnotationTextHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var targetAnnotation;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setAnnotationText(constantsRef.current, cornerstoneObjectsPromiseRef.current, e.detail)];
                case 1:
                    targetAnnotation = _a.sent();
                    return [4 /*yield*/, updateAnnotation(cornerstoneObjectsPromiseRef.current, constantsRef.current, targetAnnotation, updateAnnotationHandler)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (!constantsRef.current)
            constantsRef.current = {
                viewportIndex: viewportIndex,
                viewportId: 'viewport' + viewportIndex,
                renderingEngineId: 'renderingEngine' + viewportIndex,
            };
        if (!cornerstoneObjectsPromiseRef.current)
            cornerstoneObjectsPromiseRef.current = initializeCornerstone(constantsRef.current);
        if (!context.current && dicomFiles && dicomFiles.length > 0) {
            var viewportPromise_1 = loadDicoms(dicomFiles);
            window.addEventListener(eventNames.SetFrame, setFrameEventHandler);
            window.addEventListener(eventNames.SelectAnnotation, selectAnnotationHandler);
            window.addEventListener(eventNames.RemoveAnnotation, removeAnnotationHandler);
            window.addEventListener(eventNames.SetAnnotationText, setAnnotationTextHandler);
            resizeObserverRef.current.observe(containerRef.current);
            var debounceTimeout_1;
            function handleSave(annotation) {
                if (annotationTools.includes(toolGroup.currentActivePrimaryToolName)) {
                    saveAnnotation(annotation, saveAnnotationHandler);
                    console.log("Annotation added: ".concat(annotation.annotationUID));
                }
            }
            function handleUpdateDebounce(annotation) {
                if (annotationTools.includes(toolGroup.currentActivePrimaryToolName)) {
                    updateAnnotation(cornerstoneObjectsPromiseRef.current, constantsRef.current, annotation, updateAnnotationHandler);
                    console.log("Annotation updated: ".concat(annotation.annotationUID));
                }
            }
            function handleAnnotationEventCompleted(e) {
                viewportPromise_1.then(function (viewport) {
                    viewport.render();
                    if (lastAnnotation && e.detail.annotation.annotationUID === lastAnnotation.annotationUID)
                        clearTimeout(debounceTimeout_1);
                    lastAnnotation = e.detail.annotation;
                    // This will trigger multiple times if more than one viewport is showing the same frame
                    // Good enough for now
                    // Deeply annoying because this event has the srcElement field, but it is null
                    if (e.detail.annotation.metadata.referencedImageId === viewport.getCurrentImageId()) {
                        handleSave(e.detail.annotation);
                    }
                });
            }
            core_1.eventTarget.addEventListener(tools_1.Enums.Events.ANNOTATION_COMPLETED, handleAnnotationEventCompleted);
            function handleAnnotationEventModified(e) {
                viewportPromise_1.then(function (viewport) { viewport.render(); });
                if (lastAnnotation && e.detail.annotation.annotationUID === lastAnnotation.annotationUID)
                    clearTimeout(debounceTimeout_1);
                lastAnnotation = e.detail.annotation;
                if (e.detail.viewportId === constantsRef.current.viewportId) {
                    handleUpdateDebounce(e.detail.annotation);
                }
            }
            core_1.eventTarget.addEventListener(tools_1.Enums.Events.ANNOTATION_MODIFIED, handleAnnotationEventModified);
            function handleAnnotationEventMouseUp(e) {
                viewportPromise_1.then(function (viewport) {
                    viewport.render();
                    clearTimeout(debounceTimeout_1);
                    if (lastAnnotation && e.target == viewport.canvas) {
                        handleUpdateDebounce(lastAnnotation);
                    }
                });
            }
            // this is a last resort since some changes (moving labels) don't trigger other events
            containerRef.current.addEventListener("mouseup", handleAnnotationEventMouseUp);
            core_1.eventTarget.addEventListener(tools_1.Enums.Events.ANNOTATION_SELECTION_CHANGE, setSelectedAnnotationHandler);
            //eventTarget.addEventListener(csToolsEnums.Events.ANNOTATION_REMOVED, (e) => { deleteAnnotationHandler(e.detail.annotation.annoitationUID) })
            context.current = {
                handleAnnotationEventCompleted: handleAnnotationEventCompleted,
                handleAnnotationEventModified: handleAnnotationEventModified,
                handleAnnotationEventMouseUp: handleAnnotationEventMouseUp,
                handleAnnotationSelectionChange: setSelectedAnnotationHandler
            };
        }
        return function () {
            if (context.current) {
                teardownCornerstoneViewport(constantsRef.current, cornerstoneObjectsPromiseRef.current);
                window.removeEventListener(eventNames.SetFrame, setFrameEventHandler);
                window.removeEventListener(eventNames.SelectAnnotation, selectAnnotationHandler);
                window.removeEventListener(eventNames.RemoveAnnotation, removeAnnotationHandler);
                window.removeEventListener(eventNames.SetAnnotationText, setAnnotationTextHandler);
                core_1.eventTarget.removeEventListener(tools_1.Enums.Events.ANNOTATION_COMPLETED, context.current.handleAnnotationEventCompleted);
                core_1.eventTarget.removeEventListener(tools_1.Enums.Events.ANNOTATION_MODIFIED, context.current.handleAnnotationEventModified);
                core_1.eventTarget.removeEventListener(tools_1.Enums.Events.ANNOTATION_SELECTION_CHANGE, context.current.handleAnnotationSelectionChange);
                containerRef.current.removeEventListener("mouseup", context.current.handleAnnotationEventMouseUp);
                resizeObserverRef.current.unobserve(containerRef.current);
                context.current = null;
            }
        };
    }, [containerRef, dicomFiles]);
    var contextMenu = function () {
        if (isSingleView) {
            return (react_1.default.createElement(react_menu_1.ControlledMenu, { menuClassName: "tool-menu", anchorPoint: anchorPoint, state: isOpen ? 'open' : 'closed', direction: "right", onClose: function () {
                    setOpen(false);
                } },
                react_1.default.createElement(react_menu_1.SubMenu, { label: "Playback Controls" },
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: doStartPlayback }, "Play"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: doPausePlayback }, "Pause")),
                react_1.default.createElement(react_menu_1.SubMenu, { label: "Camera Controls" },
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activatePanTool }, "Pan"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateZoomTool }, "Zoom Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateWindowLevelTool }, "Window Level Tool")),
                react_1.default.createElement(react_menu_1.SubMenu, { label: "Annotation Tools" },
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateCircleROITool }, "Circle ROI Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateEllipticalROITool }, "Elliptical ROI Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activatePlanarFreehandROITool }, "Freehand ROI Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateLengthTool }, "Length Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateArrowAnnotateTool }, "Notes/Arrow Tool")),
                react_1.default.createElement(react_menu_1.MenuItem, { onClick: toggleFlagged }, "Flag/Unflag Image")));
        }
        else {
            return (react_1.default.createElement(react_menu_1.ControlledMenu, { menuClassName: "tool-menu", anchorPoint: anchorPoint, state: isOpen ? 'open' : 'closed', direction: "right", onClose: function () {
                    setOpen(false);
                } },
                react_1.default.createElement(react_menu_1.SubMenu, { label: "Camera Controls" },
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activatePanTool }, "Pan"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateZoomTool }, "Zoom Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateWindowLevelTool }, "Window Level Tool")),
                react_1.default.createElement(react_menu_1.SubMenu, { label: "Annotation Tools" },
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateCircleROITool }, "Circle ROI Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateEllipticalROITool }, "Elliptical ROI Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activatePlanarFreehandROITool }, "Freehand ROI Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateLengthTool }, "Length Tool"),
                    react_1.default.createElement(react_menu_1.MenuItem, { onClick: activateArrowAnnotateTool }, "Notes/Arrow Tool"))));
        }
    };
    return (react_1.default.createElement("div", { style: { flex: '1', width: '100%' }, onContextMenu: function (e) {
            e.preventDefault();
            setAnchorPoint({ x: e.clientX, y: e.clientY });
            setOpen(true);
        } },
        react_1.default.createElement("div", { style: { height: '100%', width: '100%' }, id: "viewer", ref: containerRef }),
        contextMenu()));
};
exports.default = HistoricalView;
