import { Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { PatientNoteViewModel$reflection } from "../../../Optiscan.Models/View/PatientNote.js";
import { SessionViewModel$reflection } from "../../../Optiscan.Models/View/Session.js";
import { record_type, list_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { bind } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { tryFind } from "../../../fable_modules/fable-library.3.7.12/List.js";

export class PatientNoteEditModel extends Record {
    constructor(patientNote, sessions) {
        super();
        this.patientNote = patientNote;
        this.sessions = sessions;
    }
}

export function PatientNoteEditModel$reflection() {
    return record_type("Client.Patients.PatientDetails.PatientNotes.Types.PatientNoteEditModel", [], PatientNoteEditModel, () => [["patientNote", PatientNoteViewModel$reflection()], ["sessions", list_type(SessionViewModel$reflection())]]);
}

export function PatientNoteEditModel_get_patientNote_() {
    return [(m) => m.patientNote, (v) => ((m_1) => (new PatientNoteEditModel(v, m_1.sessions)))];
}

export function PatientNoteEditModel_get_sessions_() {
    return [(m) => m.sessions, (v) => ((m_1) => (new PatientNoteEditModel(m_1.patientNote, v)))];
}

export function tryGetSessionForNote(patientNote, sessions) {
    return bind((sessionId) => tryFind((session) => (session.sessionId === sessionId), sessions), patientNote.maybeSessionId);
}

export function getSessionNameOrDefault(maybeSession) {
    if (maybeSession != null) {
        return maybeSession.sessionName;
    }
    else {
        return "";
    }
}

