import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { unit_type, record_type, bool_type, option_type, string_type, union_type, tuple_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { PatientViewModel$reflection } from "../Optiscan.Models/View/Patient.js";
import { SessionViewModel$reflection } from "../Optiscan.Models/View/Session.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Notification$reflection, DeveloperIdentity$reflection, GuestIdentityClientConfiguration$reflection, KvsConfiguration$reflection } from "../Optiscan.Models/Api/Api.js";
import { ErasedModel$reflection, ErasedMsg$2$reflection } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";

export class SignInMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "GotSessionInformation", "SignIn", "GotKvsConfiguration", "GotAuthorizationToken", "GotIdentityConfiguration", "CreateIdentityResult", "GotCredentials", "SetName", "SetPassword"];
    }
}

export function SignInMsg$reflection() {
    return union_type("Client.Guest.Telepathology.Types.SignInMsg", [], SignInMsg, () => [[["Item", class_type("System.Exception")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(PatientViewModel$reflection(), SessionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", tuple_type(PatientViewModel$reflection(), SessionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [], [["Item", KvsConfiguration$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", GuestIdentityClientConfiguration$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DeveloperIdentity$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", DeveloperIdentity$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", class_type("Fable.Import.Aws.CognitoCredentials.CognitoIdentityCredentials")]], [["Item", string_type]], [["Item", string_type]]]);
}

export class SignInModel extends Record {
    constructor(identityConfiguration, credentials, kvsConfiguration, awsToken, patient, session, loading, authToken, name, password) {
        super();
        this.identityConfiguration = identityConfiguration;
        this.credentials = credentials;
        this.kvsConfiguration = kvsConfiguration;
        this.awsToken = awsToken;
        this.patient = patient;
        this.session = session;
        this.loading = loading;
        this.authToken = authToken;
        this.name = name;
        this.password = password;
    }
}

export function SignInModel$reflection() {
    return record_type("Client.Guest.Telepathology.Types.SignInModel", [], SignInModel, () => [["identityConfiguration", option_type(GuestIdentityClientConfiguration$reflection())], ["credentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))], ["kvsConfiguration", option_type(KvsConfiguration$reflection())], ["awsToken", option_type(DeveloperIdentity$reflection())], ["patient", option_type(PatientViewModel$reflection())], ["session", option_type(SessionViewModel$reflection())], ["loading", bool_type], ["authToken", option_type(string_type)], ["name", string_type], ["password", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Notification", "TelepathologyGuestTreeMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Guest.Telepathology.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Notification$reflection()]], [["Item", ErasedMsg$2$reflection(unit_type, unit_type)]]]);
}

export class Model extends Record {
    constructor(telepathologyGuestTreeModel, maybeCode) {
        super();
        this.telepathologyGuestTreeModel = telepathologyGuestTreeModel;
        this.maybeCode = maybeCode;
    }
}

export function Model$reflection() {
    return record_type("Client.Guest.Telepathology.Types.Model", [], Model, () => [["telepathologyGuestTreeModel", ErasedModel$reflection()], ["maybeCode", option_type(string_type)]]);
}

