import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { minValue } from "../../fable_modules/fable-library.3.7.12/DateOffset.js";

export class PatientViewModel extends Record {
    constructor(id, accountId, siteId, deviceId, dateCreated) {
        super();
        this.id = id;
        this.accountId = accountId;
        this.siteId = siteId;
        this.deviceId = deviceId;
        this.dateCreated = dateCreated;
    }
}

export function PatientViewModel$reflection() {
    return record_type("Optiscan.Models.View.Patient.PatientViewModel", [], PatientViewModel, () => [["id", class_type("System.Guid")], ["accountId", class_type("System.Guid")], ["siteId", class_type("System.Guid")], ["deviceId", string_type], ["dateCreated", class_type("System.DateTimeOffset")]]);
}

export function PatientViewModel_get_empty() {
    return new PatientViewModel("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "", minValue());
}

export function PatientViewModel_get_Id_() {
    return [(m) => m.id, (v) => ((m_1) => (new PatientViewModel(v, m_1.accountId, m_1.siteId, m_1.deviceId, m_1.dateCreated)))];
}

export function PatientViewModel_get_accountId_() {
    return [(m) => m.accountId, (v) => ((m_1) => (new PatientViewModel(m_1.id, v, m_1.siteId, m_1.deviceId, m_1.dateCreated)))];
}

export function PatientViewModel_get_siteId_() {
    return [(m) => m.siteId, (v) => ((m_1) => (new PatientViewModel(m_1.id, m_1.accountId, v, m_1.deviceId, m_1.dateCreated)))];
}

export function PatientViewModel_get_deviceId_() {
    return [(m) => m.deviceId, (v) => ((m_1) => (new PatientViewModel(m_1.id, m_1.accountId, m_1.siteId, v, m_1.dateCreated)))];
}

export function PatientViewModel_get_dateCreated_() {
    return [(m) => m.dateCreated, (v) => ((m_1) => (new PatientViewModel(m_1.id, m_1.accountId, m_1.siteId, m_1.deviceId, v)))];
}

