import { SessionAnnotationNoteState, FileMetadata, defaultFrameFile, LoadingState, SelectedSequence } from "../HistoricalCommon.js";
import { tryFind, filter, append as append_1, initialize, length, updateAt, tryItem, indexed, choose, item, concat, toArray, map as map_1, ofArray, singleton, empty } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { PatientHistoricalComparisonMsg, PatientHistoricalComparisonModel, ComparisonLayout, HistoricalViewport } from "./HistoricalComparisonTypes.js";
import { customEvent, Thumbnails_loadThumbnailFromS3, Dicoms_loadDicomFromS3, Dicoms_loadAndParseFileList, Dicoms_clearDicomCache } from "../HistoricalHelpers.js";
import { toArray as toArray_2, some, bind, flatten, map, defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { Cmd_ofSub, Cmd_OfPromise_either, Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_attempt, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { PatientsOutboundMsg } from "../../PatientSearchTypes.js";
import { columns } from "../../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, ISize, column } from "../../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Color_IColor, Screen } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_1, navbar } from "../../../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { Option as Option_2, button } from "../../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import * as react from "react";
import { FormField_ReactMenu_menuItem, FormField_ReactMenu_subMenu, FormField_ReactMenu_menu, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single } from "../../../Optiscan.SharedUI/Forms.js";
import { iterate, toArray as toArray_1, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { DOMAttr, HTMLAttr } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Helpers_nothing } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { SetFrameEvent, eventNames, IHistoricalViewProps } from "../HistoricalBindings.js";
import HistoricalView from "../../../../../src/Optiscan.Client/Patients/Historical/js/HistoricalView.tsx";
import { map as map_2, unzip, equalsWith } from "../../../fable_modules/fable-library.3.7.12/Array.js";
import { max, comparePrimitives, min, safeHash, uncurry, equals } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { MenuItemProps, SubMenuProps, MenuProps } from "../../../Optiscan.SharedUI/bindings/ReactMenuBind.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { List_distinct } from "../../../fable_modules/fable-library.3.7.12/Seq2.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { securedApi } from "../../../Api.js";
import { TelemetryAction, FileOperationContext } from "../../../Optiscan.Models/Api/Api.js";
import { isNullOrWhiteSpace, replace } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { Navigation_newUrl } from "../../../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../../../Pages.js";
import { SessionAnnotationViewModel } from "../../../Optiscan.Models/View/SessionAnnotationViewModel.js";
import { error } from "../../../Optiscan.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../../../Optiscan.Models/ErrorMessage.js";

export const emptyViewport = new HistoricalViewport(new SelectedSequence(0, 0, 0), 0, new LoadingState(0), [], empty());

export function patientHistoricalComparisonView(currentUser) {
    return new StateTreeNode$6((deps) => {
        Dicoms_clearDicomCache();
        return [new PatientHistoricalComparisonModel(deps.patient, defaultArg(map((s) => s.sessionId, deps.selectedSession), "00000000-0000-0000-0000-000000000000"), defaultArg(map((s_1) => s_1.sessionName, deps.selectedSession), "--"), new FileMetadata(singleton("Loading"), singleton(singleton("Loading")), singleton(singleton(singleton("Loading"))), singleton(singleton(singleton(singleton(defaultFrameFile)))), empty(), empty()), ofArray([emptyViewport, emptyViewport]), new ComparisonLayout(0, 1), empty(), true, false, void 0, new SessionAnnotationNoteState(2)), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(1))), Cmd_OfFunc_result(new StateTreeMsg$4(3, new PatientsOutboundMsg(0, true)))]))];
    }, (model, dispatch) => {
        let children, children_2, children_4, children_39;
        return columns(empty(), singleton(column(ofArray([new Option(0, new Screen(0), new ISize(16)), new Option(2, "full-page-content historical-column")]), ofArray([navbar(singleton(new Option_1(7, "columns tele-nav")), ofArray([(children = [button(singleton(new Option_2(18, (_arg1) => {
            Dicoms_clearDicomCache();
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(11)));
        })), singleton("Back"))], react.createElement("div", {
            className: "tele-nav-back-button navbar-item no-margin",
        }, ...children)), (children_2 = [button(ofArray([new Option_2(18, (_arg2) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(12)));
        }), new Option_2(0, new Color_IColor(6))]), singleton("Single Image"))], react.createElement("div", {
            className: "navbar-item",
        }, ...children_2)), (children_4 = [FormField_ReactSelect_single([new ComparisonLayout(0, 1), new ComparisonLayout(0, 2), new ComparisonLayout(0, 3), new ComparisonLayout(0, 4), new ComparisonLayout(1, 1), new ComparisonLayout(1, 2), new ComparisonLayout(1, 3), new ComparisonLayout(1, 4)], [], (_arg1_1) => ((_arg1_1.tag === 1) ? (`3x${_arg1_1.fields[0]}`) : (`2x${_arg1_1.fields[0]}`)), model.layout, new FormField_ReactSelect_CallbackSingle$1(0, (arg_2) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(13, defaultArg(arg_2, model.layout))));
        }), "Layout", false, "Historical", [])], react.createElement("div", {
            className: "column is-2 navbar-item",
        }, ...children_4))])), (children_39 = toList(delay(() => append(map_1((tupledArg) => {
            let props_10, children_9, children_29, children_21, children_19, children_13, children_15, children_17, children_27, children_25;
            const i_2 = tupledArg[0] | 0;
            const viewport = tupledArg[1];
            const matchValue = viewport.loadingState;
            switch (matchValue.tag) {
                case 0:
                case 1:
                case 3: {
                    let loadingClass;
                    const matchValue_1 = viewport.loadingState;
                    switch (matchValue_1.tag) {
                        case 0:
                        case 1: {
                            loadingClass = "is-loading";
                            break;
                        }
                        default: {
                            loadingClass = "";
                        }
                    }
                    const layoutClasses = (model.layout.tag === 1) ? "row-triple" : "row-double";
                    let layoutHeightClasses;
                    const matchValue_3 = model.layout;
                    let pattern_matching_result;
                    if (matchValue_3.tag === 1) {
                        if (matchValue_3.fields[0] === 1) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else if (matchValue_3.fields[0] === 1) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            layoutHeightClasses = "tall";
                            break;
                        }
                        case 1: {
                            layoutHeightClasses = "";
                            break;
                        }
                    }
                    const props_36 = [new HTMLAttr(64, `historical-container ${loadingClass} ${layoutClasses} ${layoutHeightClasses}`)];
                    const children_31 = [(props_10 = [["style", {
                        width: "100%",
                        flex: "1",
                        display: "flex",
                    }], new DOMAttr(64, (evn) => {
                        let tupledArg_1;
                        dispatch(new StateTreeMsg$4(0, (tupledArg_1 = [i_2, evn.deltaY], new PatientHistoricalComparisonMsg(10, tupledArg_1[0], tupledArg_1[1]))));
                    })], (children_9 = toList(delay(() => {
                        let props_6, testExpr;
                        const matchValue_4 = [viewport.loadedDicomFiles, model.loadingAnnotations];
                        let pattern_matching_result_1;
                        if ((testExpr = matchValue_4[0], (!equalsWith(equals, testExpr, null)) && (testExpr.length === 0))) {
                            pattern_matching_result_1 = 0;
                        }
                        else if (matchValue_4[1]) {
                            pattern_matching_result_1 = 0;
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                return singleton_1(Helpers_nothing);
                            }
                            case 1: {
                                const saveSpinnerHidden = (!model.savingAnnotations) ? "is-hidden" : "";
                                return append(singleton_1((props_6 = [new HTMLAttr(64, `save-spinner ${saveSpinnerHidden}`)], react.createElement("div", keyValueList(props_6, 1), "Saving..."))), delay(() => {
                                    let props_8, props_9;
                                    return singleton_1((props_8 = ofArray([new IHistoricalViewProps(0, i_2), new IHistoricalViewProps(1, viewport.frameIndex), new IHistoricalViewProps(2, matchValue_4[0]), new IHistoricalViewProps(3, toArray(concat(map_1((viewport_1) => {
                                        const s_5 = viewport_1.selectedSequence;
                                        return map_1((frame) => frame.filePath, item(s_5.sequenceIndex, item(s_5.siteIndex, item(s_5.locationIndex, model.fileMetadata.frames))));
                                    }, model.viewports)))), new IHistoricalViewProps(4, () => {
                                    }), new IHistoricalViewProps(5, () => {
                                    }), new IHistoricalViewProps(6, () => {
                                    }), new IHistoricalViewProps(7, eventNames), new IHistoricalViewProps(8, () => {
                                    }), new IHistoricalViewProps(9, (a) => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(14, i_2, a)));
                                    }), new IHistoricalViewProps(10, (a_1) => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(15, i_2, a_1)));
                                    }), new IHistoricalViewProps(13, toArray(choose((x_1) => {
                                        if (((x_1.sequenceIndex === viewport.selectedSequence.sequenceIndex) && (x_1.siteIndex === viewport.selectedSequence.siteIndex)) && (x_1.locationIndex === viewport.selectedSequence.locationIndex)) {
                                            return x_1.annotationData;
                                        }
                                        else {
                                            return void 0;
                                        }
                                    }, model.sessionAnnotations))), new IHistoricalViewProps(12, (a_2) => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(24, a_2)));
                                    }), new IHistoricalViewProps(14, false)]), (props_9 = keyValueList(props_8, 1), react.createElement(HistoricalView, props_9))));
                                }));
                            }
                        }
                    })), react.createElement("div", keyValueList(props_10, 1), ...children_9))), (children_29 = [(children_21 = [FormField_ReactMenu_menu(ofArray([new MenuProps(0, "end"), new MenuProps(1, "bottom"), new MenuProps(2, 8)]), singleton((children_19 = [react.createElement("div", {
                        className: "sequence-menu__label__item",
                    }, model.sessionName), (children_13 = [item(viewport.selectedSequence.locationIndex, model.fileMetadata.locationNames)], react.createElement("div", {
                        className: "sequence-menu__label__item",
                    }, ...children_13)), (children_15 = [item(viewport.selectedSequence.siteIndex, item(viewport.selectedSequence.locationIndex, model.fileMetadata.siteNames))], react.createElement("div", {
                        className: "sequence-menu__label__item",
                    }, ...children_15)), (children_17 = [item(viewport.selectedSequence.sequenceIndex, item(viewport.selectedSequence.siteIndex, item(viewport.selectedSequence.locationIndex, model.fileMetadata.sequenceNames)))], react.createElement("div", {
                        className: "sequence-menu__label__item",
                    }, ...children_17))], react.createElement("div", {
                        className: "sequence-menu__label",
                    }, ...children_19))), singleton(toArray(map_1((tupledArg_2) => {
                        const j = tupledArg_2[0] | 0;
                        const location = tupledArg_2[1];
                        return FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, location), new SubMenuProps(0, location)]), singleton(toArray(map_1((tupledArg_3) => {
                            const k = tupledArg_3[0] | 0;
                            const site = tupledArg_3[1];
                            return FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, site), new SubMenuProps(0, site)]), singleton(toArray(map_1((tupledArg_4) => {
                                const sequence = tupledArg_4[1];
                                return FormField_ReactMenu_menuItem(ofArray([new MenuItemProps(0, sequence), new MenuItemProps(1, () => {
                                    dispatch(new StateTreeMsg$4(0, ((tupledArg_5) => (new PatientHistoricalComparisonMsg(8, tupledArg_5[0], tupledArg_5[1])))([i_2, new SelectedSequence(j, k, tupledArg_4[0])])));
                                })]), singleton(sequence));
                            }, indexed(item(k, item(j, model.fileMetadata.sequenceNames)))))));
                        }, indexed(item(j, model.fileMetadata.siteNames))))));
                    }, indexed(model.fileMetadata.locationNames)))))], react.createElement("div", {
                        className: "react-menu__button__container",
                    }, ...children_21)), (children_27 = [(children_25 = toList(delay(() => (equals(viewport.loadingState, new LoadingState(3)) ? map_1((tupledArg_6) => {
                        const index_3 = tupledArg_6[0] | 0;
                        const children_23 = toList(delay(() => append(tupledArg_6[1].flagged ? singleton_1(react.createElement("img", {
                            src: "flag.svg",
                            className: "frame-flag",
                        })) : empty_1(), delay(() => {
                            let props_26;
                            return singleton_1((props_26 = toList(delay(() => append(singleton_1(new HTMLAttr(149, defaultArg(flatten(tryItem(index_3, viewport.thumbnailUrls)), "no-preview.png"))), delay(() => ((viewport.frameIndex === index_3) ? singleton_1(new HTMLAttr(64, "selected-frame")) : empty_1()))))), react.createElement("img", keyValueList(props_26, 1))));
                        }))));
                        return react.createElement("a", {
                            onClick: (_arg3) => {
                                let tupledArg_7;
                                dispatch(new StateTreeMsg$4(0, (tupledArg_7 = [i_2, index_3], new PatientHistoricalComparisonMsg(9, tupledArg_7[0], tupledArg_7[1]))));
                            },
                        }, ...children_23);
                    }, indexed(defaultArg(bind((list_14) => tryItem(viewport.selectedSequence.sequenceIndex, list_14), bind((list_13) => tryItem(viewport.selectedSequence.siteIndex, list_13), tryItem(viewport.selectedSequence.locationIndex, model.fileMetadata.frames))), singleton(defaultFrameFile)))) : empty_1()))), react.createElement("div", {
                        className: "multi-historical-timeline",
                    }, ...children_25))], react.createElement("div", {
                        className: "multi-historical-timeline-container",
                    }, ...children_27))], react.createElement("div", {
                        className: "multi-historical-footer",
                    }, ...children_29))];
                    return react.createElement("div", keyValueList(props_36, 1), ...children_31);
                }
                case 4: {
                    const children_35 = [react.createElement("h4", {
                        className: "title",
                    }, "Failed to load DICOM files")];
                    return react.createElement("div", {
                        className: "historical-container",
                    }, ...children_35);
                }
                default: {
                    throw (new Error("Match failure: Client.Patients.Historical.Common.LoadingState"));
                }
            }
        }, indexed(model.viewports)), delay(() => {
            let props_42;
            return singleton_1((props_42 = [["style", {
                height: "5rem",
            }]], react.createElement("div", keyValueList(props_42, 1))));
        })))), react.createElement("div", {
            className: "historical-grid",
        }, ...children_39))]))));
    }, uncurry(4, (msg_4) => ((tupledArg_8) => {
        const token = tupledArg_8[0];
        const maybeS3Ctx = tupledArg_8[3];
        return (deps_1) => ((model_1) => {
            let inputRecord, sequence_1, inputRecord_1, tupledArg_11, matchValue_6;
            let pattern_matching_result_2, e, parsedFileNamesList, err, selectedSequence_1, fileArray, filePathsArray, selectedSequence_2, err_1, selectedSequence_3, selectedSequence_4, i_4, selectedSequence_5, url_1, filePathsArray_1, selectedSequence_6, viewportIndex, frameIndex, viewportIndex_1, delta, viewportIndex_2, layout, annotation, viewportIndex_3, annotation_1, deletedAnnotationUID, annotation_2, viewportIndex_4, annotation_3, annotations, err_2, state, setter, annotationUID;
            if (msg_4.tag === 1) {
                pattern_matching_result_2 = 1;
            }
            else if (msg_4.tag === 2) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 3;
                    err = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 2;
                    parsedFileNamesList = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 3) {
                pattern_matching_result_2 = 4;
                selectedSequence_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 4) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 6;
                    err_1 = msg_4.fields[0].fields[0];
                    selectedSequence_3 = msg_4.fields[1];
                }
                else {
                    pattern_matching_result_2 = 5;
                    fileArray = msg_4.fields[0].fields[0][0];
                    filePathsArray = msg_4.fields[0].fields[0][1];
                    selectedSequence_2 = msg_4.fields[1];
                }
            }
            else if (msg_4.tag === 5) {
                pattern_matching_result_2 = 7;
                selectedSequence_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 6) {
                pattern_matching_result_2 = 8;
                i_4 = msg_4.fields[0];
                selectedSequence_5 = msg_4.fields[2];
                url_1 = msg_4.fields[1];
            }
            else if (msg_4.tag === 7) {
                pattern_matching_result_2 = 9;
                filePathsArray_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 8) {
                pattern_matching_result_2 = 10;
                selectedSequence_6 = msg_4.fields[1];
                viewportIndex = msg_4.fields[0];
            }
            else if (msg_4.tag === 9) {
                pattern_matching_result_2 = 11;
                frameIndex = msg_4.fields[1];
                viewportIndex_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 10) {
                pattern_matching_result_2 = 12;
                delta = msg_4.fields[1];
                viewportIndex_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 11) {
                pattern_matching_result_2 = 13;
            }
            else if (msg_4.tag === 12) {
                pattern_matching_result_2 = 14;
            }
            else if (msg_4.tag === 13) {
                pattern_matching_result_2 = 15;
                layout = msg_4.fields[0];
            }
            else if (msg_4.tag === 14) {
                pattern_matching_result_2 = 16;
                annotation = msg_4.fields[1];
                viewportIndex_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 17) {
                pattern_matching_result_2 = 17;
                annotation_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 18) {
                if (msg_4.fields[0].tag === 0) {
                    pattern_matching_result_2 = 18;
                    deletedAnnotationUID = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 28;
                }
            }
            else if (msg_4.tag === 15) {
                pattern_matching_result_2 = 19;
                annotation_2 = msg_4.fields[1];
                viewportIndex_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 16) {
                if (msg_4.fields[0].tag === 0) {
                    pattern_matching_result_2 = 20;
                    annotation_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 28;
                }
            }
            else if (msg_4.tag === 19) {
                pattern_matching_result_2 = 21;
            }
            else if (msg_4.tag === 20) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 23;
                    err_2 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 22;
                    annotations = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 22) {
                pattern_matching_result_2 = 24;
                state = msg_4.fields[0];
            }
            else if (msg_4.tag === 21) {
                pattern_matching_result_2 = 25;
                setter = msg_4.fields[0];
            }
            else if (msg_4.tag === 24) {
                pattern_matching_result_2 = 26;
                annotationUID = msg_4.fields[0];
            }
            else if (msg_4.tag === 23) {
                pattern_matching_result_2 = 27;
            }
            else {
                pattern_matching_result_2 = 0;
                e = msg_4.fields[0];
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    console.error(some(`Error: ${e}`));
                    return [model_1, Cmd_none()];
                }
                case 1: {
                    return (maybeS3Ctx == null) ? [model_1, Cmd_none()] : [model_1, Cmd_OfPromise_either((tupledArg_9) => Dicoms_loadAndParseFileList(tupledArg_9[0], tupledArg_9[1], tupledArg_9[2]), [model_1.patient.id, model_1.sessionId, maybeS3Ctx], (arg_4) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(2, new FSharpResult$2(0, arg_4)))), (arg_6) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(2, new FSharpResult$2(1, arg_6)))))];
                }
                case 2: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, parsedFileNamesList, map_1((viewport_2) => (new HistoricalViewport(viewport_2.selectedSequence, viewport_2.frameIndex, new LoadingState(1), viewport_2.loadedDicomFiles, viewport_2.thumbnailUrls)), model_1.viewports), model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_batch(map_1((selectedSequence) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(3, selectedSequence))), List_distinct(map_1((viewport_3) => viewport_3.selectedSequence, model_1.viewports), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })))];
                }
                case 3: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_4) => (new HistoricalViewport(viewport_4.selectedSequence, viewport_4.frameIndex, new LoadingState(4), viewport_4.loadedDicomFiles, viewport_4.thumbnailUrls)), model_1.viewports), model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, err)))];
                }
                case 4: {
                    if (maybeS3Ctx != null) {
                        const s3Ctx_2 = maybeS3Ctx;
                        return [model_1, Cmd_OfPromise_either(() => {
                            let pr_1;
                            const pr = map_1((frame_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const fullFilePath = `${model_1.patient.id}/${model_1.sessionId}/${frame_1.filePath}`;
                                return Dicoms_loadDicomFromS3(s3Ctx_2, fullFilePath).then((_arg2_1) => {
                                    const maybeBlob = _arg2_1;
                                    return Promise.resolve([new File(toArray_1(delay(() => {
                                        if (maybeBlob == null) {
                                            return empty_1();
                                        }
                                        else {
                                            return singleton_1(maybeBlob);
                                        }
                                    })), (`${frame_1.filePath}`)), fullFilePath]);
                                });
                            })), item(selectedSequence_1.sequenceIndex, item(selectedSequence_1.siteIndex, item(selectedSequence_1.locationIndex, model_1.fileMetadata.frames))));
                            pr_1 = (Promise.all(pr));
                            return pr_1.then(unzip);
                        }, void 0, (arg_8) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(4, new FSharpResult$2(0, arg_8), selectedSequence_1))), (arg_10) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(4, new FSharpResult$2(1, arg_10), selectedSequence_1))))];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 5: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_5) => {
                        if (equals(viewport_5.selectedSequence, selectedSequence_2) && (!equals(viewport_5.loadingState, new LoadingState(3)))) {
                            return new HistoricalViewport(viewport_5.selectedSequence, viewport_5.frameIndex, new LoadingState(3), fileArray, ofArray(map_2((_arg4) => (void 0), fileArray)));
                        }
                        else {
                            return viewport_5;
                        }
                    }, model_1.viewports), model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_3) => {
                        Cmd_OfAsync_start(x_3);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(0), ofArray(filePathsArray)), (arg_11) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_11)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(5, selectedSequence_2))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(19)))]))];
                }
                case 6: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_6) => {
                        if (equals(viewport_6.selectedSequence, selectedSequence_3)) {
                            return new HistoricalViewport(viewport_6.selectedSequence, viewport_6.frameIndex, new LoadingState(4), viewport_6.loadedDicomFiles, viewport_6.thumbnailUrls);
                        }
                        else {
                            return viewport_6;
                        }
                    }, model_1.viewports), model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, err_1)))];
                }
                case 7: {
                    if (maybeS3Ctx == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const s3Ctx_3 = maybeS3Ctx;
                        return [model_1, Cmd_ofSub((dispatch_1) => {
                            let pr_3;
                            const pr_2 = map_1((tupledArg_10) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const thumbPath = replace(`${model_1.patient.id}/${model_1.sessionId}/${tupledArg_10[1].filePath}`, ".dcm", "-thumbnail.jpg");
                                return Thumbnails_loadThumbnailFromS3(s3Ctx_3, thumbPath).then((_arg3_1) => {
                                    iterate((url) => {
                                        dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(6, tupledArg_10[0], url, selectedSequence_4)));
                                    }, toArray_2(_arg3_1));
                                    return Promise.resolve(thumbPath);
                                });
                            })), indexed(item(selectedSequence_4.sequenceIndex, item(selectedSequence_4.siteIndex, item(selectedSequence_4.locationIndex, model_1.fileMetadata.frames)))));
                            pr_3 = (Promise.all(pr_2));
                            void (pr_3.then((arg_14) => {
                                dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(7, arg_14)));
                            }));
                        })];
                    }
                }
                case 8: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_7) => {
                        if (equals(viewport_7.selectedSequence, selectedSequence_5)) {
                            return new HistoricalViewport(viewport_7.selectedSequence, viewport_7.frameIndex, viewport_7.loadingState, viewport_7.loadedDicomFiles, updateAt(i_4, url_1, viewport_7.thumbnailUrls));
                        }
                        else {
                            return viewport_7;
                        }
                    }, model_1.viewports), model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 9: {
                    return [model_1, Cmd_OfAsyncWith_attempt((x_4) => {
                        Cmd_OfAsync_start(x_4);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(1), ofArray(filePathsArray_1)), (arg_15) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_15))))];
                }
                case 10: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, updateAt(viewportIndex, (inputRecord = item(viewportIndex, model_1.viewports), new HistoricalViewport(selectedSequence_6, 0, new LoadingState(1), inputRecord.loadedDicomFiles, inputRecord.thumbnailUrls)), model_1.viewports), model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(3, selectedSequence_6)))];
                }
                case 11: {
                    const clampedIndex = min(comparePrimitives, (sequence_1 = item(viewportIndex_1, model_1.viewports).selectedSequence, -1 + length(item(sequence_1.sequenceIndex, item(sequence_1.siteIndex, item(sequence_1.locationIndex, model_1.fileMetadata.frames))))), max(comparePrimitives, 0, frameIndex)) | 0;
                    if (clampedIndex !== item(viewportIndex_1, model_1.viewports).frameIndex) {
                        customEvent(eventNames.SetFrame, new SetFrameEvent(viewportIndex_1, clampedIndex));
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, updateAt(viewportIndex_1, (inputRecord_1 = item(viewportIndex_1, model_1.viewports), new HistoricalViewport(inputRecord_1.selectedSequence, clampedIndex, inputRecord_1.loadingState, inputRecord_1.loadedDicomFiles, inputRecord_1.thumbnailUrls)), model_1.viewports), model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 12: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, (tupledArg_11 = [viewportIndex_2, item(viewportIndex_2, model_1.viewports).frameIndex + ((delta > 0) ? 1 : -1)], new PatientHistoricalComparisonMsg(9, tupledArg_11[0], tupledArg_11[1]))))];
                }
                case 13: {
                    return [model_1, Cmd_batch(ofArray([Navigation_newUrl(pageHash(new Page(6, model_1.patient.id, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))]))];
                }
                case 14: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(5, "Historical")))];
                }
                case 15: {
                    const newViewports = initialize((layout.tag === 1) ? (3 * layout.fields[0]) : (2 * layout.fields[0]), (i_5) => defaultArg(map((viewport_8) => (new HistoricalViewport(viewport_8.selectedSequence, viewport_8.frameIndex, new LoadingState(2), viewport_8.loadedDicomFiles, viewport_8.thumbnailUrls)), tryItem(i_5, model_1.viewports)), emptyViewport));
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, newViewports, layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_batch(map_1((selectedSequence_7) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(3, selectedSequence_7))), List_distinct(map_1((viewport_9) => viewport_9.selectedSequence, newViewports), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })))];
                }
                case 16: {
                    const viewport_10 = item(viewportIndex_3, model_1.viewports);
                    const vm = new SessionAnnotationViewModel(model_1.sessionId, annotation.annotationUID, annotation.annotationData, viewport_10.selectedSequence.locationIndex, viewport_10.selectedSequence.siteIndex, viewport_10.selectedSequence.sequenceIndex, void 0, annotation.toolName);
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, append_1(model_1.sessionAnnotations, singleton(vm)), model_1.loadingAnnotations, model_1.savingAnnotations, vm, new SessionAnnotationNoteState(0)), Cmd_none()];
                }
                case 17: {
                    return [model_1, Cmd_OfAsyncWith_either((x_9) => {
                        Cmd_OfAsync_start(x_9);
                    }, securedApi(token).deleteSessionAnnotation, [model_1.sessionId, annotation_1.annotationUID], (arg_17) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(18, arg_17))), (arg_18) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_18))))];
                }
                case 18: {
                    customEvent(eventNames.RemoveAnnotation, deletedAnnotationUID);
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, filter((sa) => (sa.annotationId !== deletedAnnotationUID), model_1.sessionAnnotations), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, new SessionAnnotationNoteState(2)), Cmd_none()];
                }
                case 19: {
                    const viewport_11 = item(viewportIndex_4, model_1.viewports);
                    let vm_1;
                    const matchValue_5 = tryFind((sa_1) => (sa_1.annotationId === annotation_2.annotationUID), model_1.sessionAnnotations);
                    if (matchValue_5 != null) {
                        const a_7 = matchValue_5;
                        vm_1 = (new SessionAnnotationViewModel(model_1.sessionId, annotation_2.annotationUID, annotation_2.annotationData, viewport_11.selectedSequence.locationIndex, viewport_11.selectedSequence.siteIndex, viewport_11.selectedSequence.sequenceIndex, a_7.note, a_7.annotationName));
                    }
                    else {
                        vm_1 = (new SessionAnnotationViewModel(model_1.sessionId, annotation_2.annotationUID, annotation_2.annotationData, viewport_11.selectedSequence.locationIndex, viewport_11.selectedSequence.siteIndex, viewport_11.selectedSequence.sequenceIndex, void 0, annotation_2.toolName));
                    }
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, true, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfAsyncWith_either((x_10) => {
                        Cmd_OfAsync_start(x_10);
                    }, securedApi(token).upsertSessionAnnotation, vm_1, (arg_20) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(16, arg_20))), (arg_21) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_21))))];
                }
                case 20: {
                    return [(matchValue_6 = tryFind((sa_2) => (sa_2.annotationId === annotation_3.annotationId), model_1.sessionAnnotations), (matchValue_6 == null) ? (new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, false, annotation_3, model_1.sessionAnnotationNoteState)) : (new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, false, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(19)))];
                }
                case 21: {
                    return [model_1, Cmd_OfAsyncWith_either((x_11) => {
                        Cmd_OfAsync_start(x_11);
                    }, securedApi(token).getAllSessionAnnotationsForSessionId, model_1.sessionId, (arg_23) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(20, arg_23))), (arg_24) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_24))))];
                }
                case 22: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, annotations, false, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 23: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, model_1.sessionAnnotations, false, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), error(ErrorMessage_get_describe()(err_2))];
                }
                case 24: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, state), Cmd_none()];
                }
                case 25: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, map(setter, model_1.selectedAnnotation), model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 26: {
                    const maybeAnnotationID = isNullOrWhiteSpace(annotationUID) ? (void 0) : annotationUID;
                    const node = document.getElementById(`${annotationUID}`);
                    if (equals(node, null)) {
                    }
                    else {
                        node.scrollIntoView();
                    }
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, tryFind((sa_3) => equals(sa_3.annotationId, maybeAnnotationID), model_1.sessionAnnotations), model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 27: {
                    const matchValue_8 = model_1.selectedAnnotation;
                    if (matchValue_8 == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const annotation_4 = matchValue_8;
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.layout, map_1((sa_4) => {
                            if (sa_4.annotationId === annotation_4.annotationId) {
                                return annotation_4;
                            }
                            else {
                                return sa_4;
                            }
                        }, model_1.sessionAnnotations), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, new SessionAnnotationNoteState(2)), Cmd_OfAsyncWith_either((x_12) => {
                            Cmd_OfAsync_start(x_12);
                        }, securedApi(token).upsertSessionAnnotation, annotation_4, (arg_26) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(16, arg_26))), (arg_27) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_27))))];
                    }
                }
                case 28: {
                    throw (new Error("Match failure: Client.Patients.Historical.Comparison.Types.PatientHistoricalComparisonMsg"));
                }
            }
        });
    })));
}

