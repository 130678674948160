import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withAuthorizationHeader } from "./fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { Security_SecuredAuthApi$1$reflection, Security_UserInfo$2$reflection, Security_PublicAuthApi$reflection, Route_builder } from "./fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { OptiscanUserData$reflection, UserRole$reflection } from "./Optiscan.Models/Security.js";
import { GuestApi$reflection, CustomUserManagementApi$reflection, SecuredApi$reflection } from "./Optiscan.Models/Api/Api.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.7.12/Option.js";

function withToken(t) {
    const token = toText(printf("Bearer %s"))(t);
    return (options) => RemotingModule_withAuthorizationHeader(token, options);
}

export const publicAuthApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Security_PublicAuthApi$reflection());

export function securedAuthApi(token) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, withToken(token)(RemotingModule_createApi())), Security_SecuredAuthApi$1$reflection(Security_UserInfo$2$reflection(UserRole$reflection(), OptiscanUserData$reflection())));
}

export function securedApi(token) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, withToken(token)(RemotingModule_createApi())), SecuredApi$reflection());
}

export function securedUserManagementApi(token) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, withToken(token)(RemotingModule_createApi())), CustomUserManagementApi$reflection());
}

export function guestApi(maybeToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, defaultArg(map(withToken, maybeToken), (x) => x)(RemotingModule_createApi())), GuestApi$reflection());
}

