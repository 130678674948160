import { ErasedMsg$2, ErasedModel, ErasedModel_get_empty } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Msg, Model } from "./DevicesTypes.js";
import { Cmd_map as Cmd_map_1, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { StateTree$3__getIndexPathFromCursor_1334CEF1, StateTree$3__get_root } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { stateTree } from "./DevicesTree.js";
import { cons, skip, tryFindIndex, tail, isEmpty, empty, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { Cmd_batch as Cmd_batch_1, Cmd_none as Cmd_none_1, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { CrudListInboundMsg } from "../Optiscan.SharedUI/Crud.js";
import { value as value_9, some, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library.3.7.12/Map.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";

export function init() {
    return [new Model(ErasedModel_get_empty()), Cmd_none()];
}

export function update(token, userInfo, msg, model) {
    if (msg.tag === 1) {
        let patternInput_1;
        const root = StateTree$3__get_root(stateTree);
        const patternInput = root.Init(singleton(0), void 0, void 0, false);
        patternInput_1 = [new ErasedModel((new ErasedModel(patternInput[0], empty())).value, singleton(root.LastKey)), Cmd_map((arg00_1) => (new ErasedMsg$2(0, arg00_1)), patternInput[1])];
        return [new Model(patternInput_1[0]), Cmd_batch(singleton(Cmd_map_1((arg0_1) => (new Msg(3, arg0_1)), patternInput_1[1])))];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 0) {
            return [model, Cmd_OfFunc_result(new Msg(3, new ErasedMsg$2(1, new CrudListInboundMsg(0))))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        let patternInput_3;
        const msg_4 = msg.fields[0];
        const model_3 = model.crudListModel;
        const root_1 = StateTree$3__get_root(stateTree);
        switch (msg_4.tag) {
            case 1: {
                const patternInput_2_1 = root_1.UpdateInbound(msg_4.fields[0], void 0, model_3.value);
                patternInput_3 = [new ErasedModel(patternInput_2_1[0], model_3.cursor), Cmd_map((arg00_3_1) => (new ErasedMsg$2(0, arg00_3_1)), patternInput_2_1[1])];
                break;
            }
            case 2: {
                patternInput_3 = [model_3, Cmd_none_1()];
                break;
            }
            default: {
                const patternInput_2 = root_1.Update(defaultArg(FSharpMap__TryFind(root_1.IndexPathFromKeyPath, model_3.cursor), singleton(0)), msg_4.fields[0], [token, userInfo], void 0, model_3.value);
                const maybeOutboundMsg = patternInput_2[3];
                const maybeNavMsg = patternInput_2[2];
                const innerModel = patternInput_2[0];
                const innerCmd = patternInput_2[1];
                let newCursor;
                let pattern_matching_result, keyPath, key, key_1, key_2;
                if (maybeNavMsg != null) {
                    if (maybeNavMsg.tag === 1) {
                        pattern_matching_result = 2;
                    }
                    else if (maybeNavMsg.tag === 2) {
                        pattern_matching_result = 2;
                    }
                    else if (maybeNavMsg.tag === 3) {
                        pattern_matching_result = 3;
                        key = maybeNavMsg.fields[0];
                    }
                    else if (maybeNavMsg.tag === 4) {
                        pattern_matching_result = 4;
                        key_1 = maybeNavMsg.fields[0];
                    }
                    else if (maybeNavMsg.tag === 5) {
                        pattern_matching_result = 5;
                        key_2 = maybeNavMsg.fields[0];
                    }
                    else {
                        pattern_matching_result = 1;
                        keyPath = maybeNavMsg.fields[0];
                    }
                }
                else {
                    pattern_matching_result = 0;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        newCursor = model_3.cursor;
                        break;
                    }
                    case 1: {
                        newCursor = keyPath;
                        break;
                    }
                    case 2: {
                        const matchValue = model_3.cursor;
                        let pattern_matching_result_1, xs;
                        if (!isEmpty(matchValue)) {
                            if (isEmpty(tail(matchValue))) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                                xs = tail(matchValue);
                            }
                        }
                        else {
                            pattern_matching_result_1 = 0;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                console.error(some("Could not find parent node"));
                                newCursor = model_3.cursor;
                                break;
                            }
                            case 1: {
                                newCursor = xs;
                                break;
                            }
                        }
                        break;
                    }
                    case 3: {
                        const matchValue_1 = tryFindIndex((y) => (key === y), model_3.cursor);
                        if (matchValue_1 == null) {
                            console.error(some(`Could not find ancestor node ${key}`));
                            newCursor = model_3.cursor;
                        }
                        else {
                            newCursor = skip(matchValue_1, model_3.cursor);
                        }
                        break;
                    }
                    case 4: {
                        const childKey = cons(key_1, model_3.cursor);
                        const matchValue_2 = FSharpMap__TryFind(root_1.IndexPathFromKeyPath, childKey);
                        if (matchValue_2 == null) {
                            console.error(some(`Could not find child node ${key_1}`));
                            newCursor = model_3.cursor;
                        }
                        else {
                            newCursor = childKey;
                        }
                        break;
                    }
                    case 5: {
                        const matchValue_3 = model_3.cursor;
                        newCursor = ((!isEmpty(matchValue_3)) ? cons(key_2, tail(matchValue_3)) : singleton(key_2));
                        break;
                    }
                }
                if (!equals(newCursor, model_3.cursor)) {
                    const patternInput_1_1 = root_1.Init(StateTree$3__getIndexPathFromCursor_1334CEF1(stateTree, newCursor), void 0, some(innerModel), false);
                    patternInput_3 = [new ErasedModel(patternInput_1_1[0], newCursor), Cmd_batch_1(toList(delay(() => append(singleton_1(Cmd_map((arg00_3) => (new ErasedMsg$2(0, arg00_3)), innerCmd)), delay(() => append(singleton_1(Cmd_map((arg00_1_1) => (new ErasedMsg$2(0, arg00_1_1)), patternInput_1_1[1])), delay(() => {
                        if (maybeOutboundMsg != null) {
                            return singleton_1(Cmd_OfFunc_result(new ErasedMsg$2(2, value_9(maybeOutboundMsg))));
                        }
                        else {
                            return empty_1();
                        }
                    })))))))];
                }
                else {
                    patternInput_3 = [new ErasedModel(innerModel, model_3.cursor), Cmd_batch_1(toList(delay(() => append(singleton_1(Cmd_map((arg00_2_1) => (new ErasedMsg$2(0, arg00_2_1)), innerCmd)), delay(() => {
                        if (maybeOutboundMsg != null) {
                            return singleton_1(Cmd_OfFunc_result(new ErasedMsg$2(2, value_9(maybeOutboundMsg))));
                        }
                        else {
                            return empty_1();
                        }
                    })))))];
                }
            }
        }
        return [new Model(patternInput_3[0]), Cmd_map_1((arg0_9) => (new Msg(3, arg0_9)), patternInput_3[1])];
    }
    else {
        console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
        return [model, Cmd_none()];
    }
}

