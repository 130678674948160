"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TelestatsHelpers = void 0;
exports.TelestatsHelpers = (function () {
    var createTelestatsObject = function () {
        return {
            startTime: Date.now(),
            currentFrameNum: 0,
            framesReceived: 0,
            foundFirstFrame: false,
            averageLatency: 0,
            droppedFrames: 0,
            duplicateSegments: 0,
            frames: [],
            log: ""
        };
    };
    var doLog = function (telestats, logger, message) {
        logger(message);
        telestats.log = telestats.log + "\n" + message;
        return telestats;
    };
    var formatBytes = function (bytes) {
        if (bytes === 0)
            return "0 Bytes";
        // those are some big frames 🖼️
        var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
    };
    var getLatencyMap = function (telestats) {
        return telestats.frames.map(function (frame) { return frame === undefined ? undefined : frame.latency; });
    };
    //Formula taken from https://obkio.com/blog/how-to-measure-jitter/
    var calcJitter = function (telestats, latencyMap) {
        var count = latencyMap
            .map(function (latency) {
            return Math.pow((latency - telestats.averageLatency), 2);
        })
            .reduce(function (acc, v) { return acc + v; }, 0);
        return Math.sqrt((1.0 / telestats.framesReceived) * count);
    };
    var calcAverageLatency = function (latencyMap) {
        var sum = latencyMap.reduce(function (acc, v) { return acc + v; }, 0);
        var count = latencyMap.reduce(function (acc, _) { return acc + 1; }, 0); // Sparse arrays have to be counted like this
        return sum / count;
    };
    var updateMetrics = function (telestats, frameNumber, timestamp) {
        telestats.frames[frameNumber].latency = Number(timestamp - telestats.frames[frameNumber].timestamp);
        var latencyMap = getLatencyMap(telestats);
        telestats.averageLatency = calcAverageLatency(latencyMap);
        telestats.frames[frameNumber].jitter = calcJitter(telestats, latencyMap);
        return telestats;
    };
    var updateTelestatsElems = function (telestats, frameNumber) {
        var latencyCountElem = document.getElementById("latency");
        var avgLatencyCountElem = document.getElementById("avg-latency");
        var jitterCountElem = document.getElementById("jitter");
        latencyCountElem.innerHTML = "Latency: ".concat(telestats.frames[frameNumber].latency);
        avgLatencyCountElem.innerHTML = "Average Latency: ".concat(telestats.averageLatency.toFixed(2));
        jitterCountElem.innerHTML = "Jitter: ".concat(telestats.frames[frameNumber].jitter.toFixed(2));
    };
    var updateDroppedFramesElem = function (telestats) {
        var droppedFrameCountElem = document.getElementById("dropped-frames");
        droppedFrameCountElem.innerHTML = "Dropped Frames ".concat(telestats.droppedFrames);
    };
    var downloadString = function (text, fileType, fileName) {
        var blob = new Blob([text], { type: fileType });
        var a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
    };
    var checkdroppedFrames = function (telestats, frameNumber) {
        if (frameNumber > telestats.currentFrameNum) {
            var droppedFramesOld = telestats.droppedFrames;
            for (var i = telestats.currentFrameNum; i < frameNumber; i++) {
                if (i == 0) {
                    continue;
                }
                if (telestats.frames[i] === undefined) {
                    telestats.droppedFrames++;
                }
                else if (telestats.frames[i].dropped) {
                    telestats.droppedFrames++;
                }
                else if (telestats.frames[i].segments.size < telestats.frames[i].segmentCount) {
                    telestats.frames[i].dropped = true;
                    telestats.droppedFrames++;
                }
            }
            var framesDropped = telestats.droppedFrames - droppedFramesOld;
            if (framesDropped) {
                updateDroppedFramesElem(telestats);
                telestats = doLog(telestats, console.info, "Frame #: ".concat(frameNumber, " | Dropped ").concat(framesDropped, " frame(s) | Total Dropped Frames ").concat(telestats.droppedFrames));
            }
        }
        return telestats;
    };
    var setStartTime = function (telestats, startTime) {
        telestats.startTime = startTime;
        return telestats;
    };
    var trySetFirstFrame = function (telestats, firstFrame) {
        if (!telestats.foundFirstFrame) {
            telestats.currentFrameNum = firstFrame;
            telestats.foundFirstFrame = true;
        }
        return telestats;
    };
    var gatherMetrics = function (telestats, frameNumber, segmentNumber, segmentCount, fileSize, timestamp) {
        var foundFrame = false;
        var now = BigInt(Date.now());
        telestats.frames = telestats.frames.map(function (frame, index) {
            foundFrame = index === frameNumber;
            return frame;
        });
        if (foundFrame) {
            telestats = updateMetrics(telestats, frameNumber, now);
            telestats.frames[frameNumber].segments.set(segmentNumber, (telestats.frames[frameNumber].segments.get(segmentNumber) || 0) + 1);
            var segments_received = Array
                .from(telestats.frames[frameNumber].segments.values())
                .reduce(function (acc, value) { return acc + value; }, 0);
            telestats.frames[frameNumber].segmentsReceived = segments_received;
            telestats.duplicateSegments += (segments_received > segmentCount) ? 1 : 0;
        }
        else {
            var segments = new Map();
            segments.set(segmentNumber, 1);
            var latency = Number(now - timestamp);
            var newFrame = {
                latency: latency,
                jitter: 0,
                segments: segments,
                segmentCount: segmentCount,
                segmentsReceived: 1,
                dropped: true,
                fileSize: formatBytes(fileSize),
                timestamp: timestamp,
            };
            telestats.frames[frameNumber] = newFrame;
            var latencyMap = getLatencyMap(telestats);
            calcAverageLatency(latencyMap);
            telestats.frames[frameNumber].jitter = calcJitter(telestats, latencyMap);
            telestats.framesReceived++;
        }
        return telestats;
    };
    var adjustForRenderTime = function (telestats, frameNumber, timestamp) {
        telestats.frames[frameNumber].dropped = false;
        telestats = updateMetrics(telestats, frameNumber, timestamp);
        checkdroppedFrames(telestats, frameNumber);
        telestats.currentFrameNum = frameNumber;
        updateTelestatsElems(telestats, frameNumber);
        return telestats;
    };
    var toCsv = function (telestats) {
        var headers = "Frame Count,Frames Rendered,Frame Number,Latency,Jitter,Segment Count,Segments Received,Frame Dropped,File Size,Timestamp,Dropped Frames,Duplicate Frames,Avg. Latency";
        var latencyMap = getLatencyMap(telestats);
        var i = 0;
        var framesRendered = 0;
        var body = latencyMap
            .map(function (latency, index) {
            var frame = telestats.frames[index];
            if (!frame.dropped)
                framesRendered++;
            console.log("Frame Dropped?: ".concat(frame.dropped, " | Frames Rendered: ").concat(framesRendered));
            var date = new Date(Number(frame.timestamp));
            var iso_timestamp = date.toISOString().replace(/[TZ]/g, " ");
            if (i++ == 0) {
                return "".concat(i, ",").concat(framesRendered, ",").concat(index, ",").concat(latency, ",").concat(frame.jitter.toFixed(2), ",").concat(frame.segmentCount, ",").concat(frame.segmentsReceived, ",").concat(frame.dropped, ",").concat(frame.fileSize, ",").concat(iso_timestamp, ",").concat(telestats.droppedFrames, ",").concat(telestats.duplicateSegments, ",").concat(telestats.averageLatency);
            }
            else {
                return "".concat(i, ",").concat(framesRendered, ",").concat(index, ",").concat(latency, ",").concat(frame.jitter.toFixed(2), ",").concat(frame.segmentCount, ",").concat(frame.segmentsReceived, ",").concat(frame.dropped, ",").concat(frame.fileSize, ",").concat(iso_timestamp, ",,,");
            }
        })
            .reduce(function (acc, v) { return "".concat(acc, "\n").concat(v); }, "");
        var csv = "".concat(headers, "\n").concat(body, "\n---\n").concat(telestats.log);
        downloadString(csv, "text/plain", "test.csv");
    };
    return { createTelestatsObject: createTelestatsObject, dolog: doLog, setStartTime: setStartTime, trySetFirstFrame: trySetFirstFrame, gatherMetrics: gatherMetrics, adjustForRenderTime: adjustForRenderTime, toCsv: toCsv };
})();
