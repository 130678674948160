import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import * as react from "react";
import { label } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Msg } from "./AuthTypes.js";

export function view(model, dispatch) {
    let children_2, children;
    return container(empty(), singleton((children_2 = [h3(empty())(singleton("Change Email")), div(empty(), ofArray([(children = [toText(printf("This will change your account email from %s to %s"))(model.EmailChangeVm.currentEmail)(model.EmailChangeVm.newEmail)], react.createElement("p", {}, ...children)), label(empty(), singleton("Please click the button to confirm the Email change")), button(singleton(new Option(18, (_arg1) => {
        dispatch(new Msg(16));
    })), singleton("Confirm Email Change"))]))], react.createElement("div", {}, ...children_2))));
}

