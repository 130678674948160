import { Union, Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { AdjustedTimeZoneViewModel$reflection } from "../../../Optiscan.Models/View/Session.js";
import { union_type, record_type, int32_type, class_type, string_type, option_type, list_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ErrorMessage$reflection } from "../../../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { CrudEditMsg$2$reflection } from "../../../Optiscan.SharedUI/Crud.js";

export class Model extends Record {
    constructor(timeZones, sessionName, date, time, timeZone) {
        super();
        this.timeZones = timeZones;
        this.sessionName = sessionName;
        this.date = date;
        this.time = time;
        this.timeZone = timeZone;
    }
}

export function Model$reflection() {
    return record_type("Client.Patients.PatientSessions.Types.Model", [], Model, () => [["timeZones", option_type(list_type(AdjustedTimeZoneViewModel$reflection()))], ["sessionName", string_type], ["date", option_type(class_type("System.DateTimeOffset"))], ["time", option_type(int32_type)], ["timeZone", option_type(AdjustedTimeZoneViewModel$reflection())]]);
}

export function Model_get_sessionName_() {
    return [(m) => m.sessionName, (v) => ((m_1) => (new Model(m_1.timeZones, v, m_1.date, m_1.time, m_1.timeZone)))];
}

export function Model_get_date_() {
    return [(m) => m.date, (v) => ((m_1) => (new Model(m_1.timeZones, m_1.sessionName, v, m_1.time, m_1.timeZone)))];
}

export function Model_get_time_() {
    return [(m) => m.time, (v) => ((m_1) => (new Model(m_1.timeZones, m_1.sessionName, m_1.date, v, m_1.timeZone)))];
}

export function Model_get_timeZone_() {
    return [(m) => m.timeZone, (v) => ((m_1) => (new Model(m_1.timeZones, m_1.sessionName, m_1.date, m_1.time, v)))];
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetTimeZones", "GotTimeZonesResult", "CrudEditMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Patients.PatientSessions.Types.Msg", [], Msg, () => [[["Item", class_type("System.DateTime")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AdjustedTimeZoneViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(AdjustedTimeZoneViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", CrudEditMsg$2$reflection(Model$reflection(), ErrorMessage$reflection())]]]);
}

