import { singleton } from "../../../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { sleep } from "../../../fable_modules/fable-library.3.7.12/Async.js";
import { PatientHistoricalModel, PatientHistoricalMsg } from "./HistoricalTypes.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { FlaggedImages_flagFrame, FlaggedImages_tryGetFrameNumber, FlaggedImages_mkFlaggedImageListItemFromSelected, customEvent, Thumbnails_loadThumbnailFromS3, Dicoms_loadDicomFromS3, FlaggedImages_loadFlaggedImagesPromise, Dicoms_loadAndParseFileList, Dicoms_clearDicomCache } from "../HistoricalHelpers.js";
import { Sort_SortInfo$1, Sort_SortDirection, Sort_SortableColumn$1_toSortableColumnView_45D2C9EC } from "../../../Optiscan.SharedUI/Sort.js";
import { toArray as toArray_2, some, bind, flatten, map, defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { SessionAnnotationNoteState, FileMetadata, defaultFrameFile, LoadingState, SelectedSequence } from "../HistoricalCommon.js";
import { filter, tryFind, append as append_1, tryFindIndex, removeAt, cons, length, updateAt, tryItem, choose, indexed, map as map_1, toArray, ofArray, item as item_2, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { flaggedImagesTableCard, flaggedImagesTableColumns } from "../FlaggedImagesView.js";
import { sessionAnnotationDeleteModal, sessionAnnotationsEditModal, sessionAnnotationsTableCard, sessionAnnotationsTableColumns } from "../SessionAnnotationsView.js";
import { Cmd_ofSub, Cmd_OfPromise_either, Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_result, Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PatientsOutboundMsg } from "../../PatientSearchTypes.js";
import { columns } from "../../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { ISize, Option, column } from "../../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Color_IColor, Screen } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { iterate, toArray as toArray_1, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { Helpers_nothing } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Option as Option_1, navbar } from "../../../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { Option as Option_2, button } from "../../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import * as react from "react";
import { FormField_ReactMenu_menuItem, FormField_ReactMenu_subMenu, FormField_ReactMenu_menu } from "../../../Optiscan.SharedUI/Forms.js";
import { MenuItemProps, SubMenuProps, MenuProps } from "../../../Optiscan.SharedUI/bindings/ReactMenuBind.js";
import { DOMAttr, HTMLAttr } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { SetFrameEvent, eventNames, IHistoricalViewProps } from "../HistoricalBindings.js";
import HistoricalView from "../../../../../src/Optiscan.Client/Patients/Historical/js/HistoricalView.tsx";
import { max, comparePrimitives, min, uncurry, equals } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { map as map_2, unzip, equalsWith } from "../../../fable_modules/fable-library.3.7.12/Array.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { ErrorMessage_get_describe, ErrorMessage } from "../../../Optiscan.Models/ErrorMessage.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { securedApi } from "../../../Api.js";
import { TelemetryAction, FileOperationContext } from "../../../Optiscan.Models/Api/Api.js";
import { isNullOrWhiteSpace, replace } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { create, match } from "../../../fable_modules/fable-library.3.7.12/RegExp.js";
import { parse } from "../../../fable_modules/fable-library.3.7.12/Int32.js";
import { error } from "../../../Optiscan.SharedUI/Toast.js";
import { Navigation_newUrl } from "../../../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../../../Pages.js";
import { SessionAnnotationViewModel } from "../../../Optiscan.Models/View/SessionAnnotationViewModel.js";

export function playbackTick() {
    return singleton.Delay(() => singleton.Bind(sleep(1000), () => singleton.Return(new StateTreeMsg$4(0, new PatientHistoricalMsg(21)))));
}

export function patientHistoricalView(currentUser) {
    return new StateTreeNode$6((deps) => {
        Dicoms_clearDicomCache();
        const initialSortInfo = (lastColumnClicked) => (new Sort_SortInfo$1(Sort_SortableColumn$1_toSortableColumnView_45D2C9EC(lastColumnClicked), new Sort_SortDirection(0)));
        return [new PatientHistoricalModel(deps.patient, defaultArg(map((s) => s.sessionId, deps.selectedSession), "00000000-0000-0000-0000-000000000000"), defaultArg(map((s_1) => s_1.sessionName, deps.selectedSession), "--"), new SelectedSequence(0, 0, 0), 0, new LoadingState(0), [], new FileMetadata(singleton_1("Loading"), singleton_1(singleton_1("Loading")), singleton_1(singleton_1(singleton_1("Loading"))), singleton_1(singleton_1(singleton_1(singleton_1(defaultFrameFile)))), empty(), empty()), singleton_1(""), initialSortInfo(item_2(0, flaggedImagesTableColumns)), initialSortInfo(item_2(0, sessionAnnotationsTableColumns)), true, empty(), true, false, void 0, new SessionAnnotationNoteState(2)), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(1))), Cmd_OfFunc_result(new StateTreeMsg$4(3, new PatientsOutboundMsg(0, true)))]))];
    }, (model, dispatch) => {
        let children, children_2, children_14, children_12, children_6, children_8, children_10, children_31;
        return columns(empty(), ofArray([column(ofArray([new Option(2, "session-details-column full-page-content"), new Option(0, new Screen(0), new ISize(7))]), toList(delay(() => append(singleton_2(flaggedImagesTableCard(model.fileMetadata, model.flaggedImagesSortInfo, (item, _arg1) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(16, item)));
        }, (sortInfo, _arg2) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(14, sortInfo)));
        })), delay(() => append(singleton_2(sessionAnnotationsTableCard(model.sessionAnnotations, model.selectedAnnotation, model.sessionAnnotationsSortInfo, (_arg3) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(32, new SessionAnnotationNoteState(0))));
        }, (_arg4) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(32, new SessionAnnotationNoteState(1))));
        }, (item_1, _arg5) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(17, item_1)));
        }, (sortInfo_1, _arg6) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(15, sortInfo_1)));
        })), delay(() => {
            const matchValue = [model.sessionAnnotationNoteState, model.selectedAnnotation];
            let pattern_matching_result_1;
            if (matchValue[0].tag === 0) {
                if (matchValue[1] != null) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 2;
                }
            }
            else if (matchValue[0].tag === 1) {
                if (matchValue[1] != null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 2;
                }
            }
            else {
                pattern_matching_result_1 = 2;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return singleton_2(sessionAnnotationsEditModal(matchValue[1], model.savingAnnotations ? true : model.loadingAnnotations, (arg_1) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(31, arg_1)));
                    }, (_arg7) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(33)));
                    }, (_arg8) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(32, new SessionAnnotationNoteState(2))));
                    }));
                }
                case 1: {
                    const annotation_1 = matchValue[1];
                    return singleton_2(sessionAnnotationDeleteModal((_arg9) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(27, annotation_1.annotationId)));
                    }, (_arg10) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(32, new SessionAnnotationNoteState(2))));
                    }));
                }
                case 2: {
                    return singleton_2(Helpers_nothing);
                }
            }
        }))))))), column(ofArray([new Option(0, new Screen(0), new ISize(13)), new Option(2, "full-page-content historical-column")]), ofArray([navbar(singleton_1(new Option_1(7, "columns tele-nav")), ofArray([(children = [button(singleton_1(new Option_2(18, (_arg11) => {
            Dicoms_clearDicomCache();
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(22)));
        })), singleton_1("Back"))], react.createElement("div", {
            className: "tele-nav-back-button navbar-item no-margin",
        }, ...children)), (children_2 = [button(ofArray([new Option_2(18, (_arg12) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(35)));
        }), new Option_2(0, new Color_IColor(6))]), singleton_1("Compare Images"))], react.createElement("div", {
            className: "navbar-item",
        }, ...children_2)), (children_14 = [FormField_ReactMenu_menu(ofArray([new MenuProps(0, "end"), new MenuProps(1, "bottom"), new MenuProps(2, 8)]), singleton_1((children_12 = [react.createElement("div", {
            className: "sequence-menu__label__item",
        }, model.sessionName), (children_6 = [item_2(model.selectedSequence.locationIndex, model.fileMetadata.locationNames)], react.createElement("div", {
            className: "sequence-menu__label__item",
        }, ...children_6)), (children_8 = [item_2(model.selectedSequence.siteIndex, item_2(model.selectedSequence.locationIndex, model.fileMetadata.siteNames))], react.createElement("div", {
            className: "sequence-menu__label__item",
        }, ...children_8)), (children_10 = [item_2(model.selectedSequence.sequenceIndex, item_2(model.selectedSequence.siteIndex, item_2(model.selectedSequence.locationIndex, model.fileMetadata.sequenceNames)))], react.createElement("div", {
            className: "sequence-menu__label__item",
        }, ...children_10))], react.createElement("div", {
            className: "sequence-menu__label",
        }, ...children_12))), singleton_1(toArray(map_1((tupledArg) => {
            const i = tupledArg[0] | 0;
            const location = tupledArg[1];
            return FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, location), new SubMenuProps(0, location)]), singleton_1(toArray(map_1((tupledArg_1) => {
                const j = tupledArg_1[0] | 0;
                const site = tupledArg_1[1];
                return FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, site), new SubMenuProps(0, site)]), singleton_1(toArray(map_1((tupledArg_2) => {
                    const sequence = tupledArg_2[1];
                    return FormField_ReactMenu_menuItem(ofArray([new MenuItemProps(0, sequence), new MenuItemProps(1, () => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(11, new SelectedSequence(i, j, tupledArg_2[0]))));
                    })]), singleton_1(sequence));
                }, indexed(item_2(j, item_2(i, model.fileMetadata.sequenceNames)))))));
            }, indexed(item_2(i, model.fileMetadata.siteNames))))));
        }, indexed(model.fileMetadata.locationNames)))))], react.createElement("div", {
            className: "column is-3 navbar-item",
        }, ...children_14))])), (children_31 = toList(delay(() => {
            let props_30, matchValue_2, children_25, children_29;
            const matchValue_1 = model.loadingState;
            switch (matchValue_1.tag) {
                case 0:
                case 1:
                case 3: {
                    return singleton_2((props_30 = [new HTMLAttr(64, (matchValue_2 = model.loadingState, (matchValue_2.tag === 0) ? "historical-container row-single is-loading" : ((matchValue_2.tag === 1) ? "historical-container row-single is-loading" : "historical-container row-single"))), new DOMAttr(64, (evn) => {
                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(13, evn.deltaY)));
                    })], (children_25 = toList(delay(() => {
                        let props_16, testExpr;
                        const matchValue_3 = [model.loadedDicomFiles, model.loadingAnnotations];
                        let pattern_matching_result_2;
                        if ((testExpr = matchValue_3[0], (!equalsWith(equals, testExpr, null)) && (testExpr.length === 0))) {
                            pattern_matching_result_2 = 0;
                        }
                        else if (matchValue_3[1]) {
                            pattern_matching_result_2 = 0;
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                        switch (pattern_matching_result_2) {
                            case 0: {
                                return singleton_2(Helpers_nothing);
                            }
                            case 1: {
                                const saveSpinnerHidden = (!model.savingAnnotations) ? "is-hidden" : "";
                                return append(singleton_2((props_16 = [new HTMLAttr(64, `save-spinner ${saveSpinnerHidden}`)], react.createElement("div", keyValueList(props_16, 1), "Saving..."))), delay(() => {
                                    let props_18, s_10, props_19;
                                    return append(singleton_2((props_18 = ofArray([new IHistoricalViewProps(0, 0), new IHistoricalViewProps(1, model.frameIndex), new IHistoricalViewProps(2, model.loadedDicomFiles), new IHistoricalViewProps(3, (s_10 = model.selectedSequence, toArray(map_1((frame) => frame.filePath, item_2(s_10.sequenceIndex, item_2(s_10.siteIndex, item_2(s_10.locationIndex, model.fileMetadata.frames))))))), new IHistoricalViewProps(4, () => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(10)));
                                    }), new IHistoricalViewProps(1, model.frameIndex), new IHistoricalViewProps(5, () => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(19)));
                                    }), new IHistoricalViewProps(6, () => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(20)));
                                    }), new IHistoricalViewProps(7, eventNames), new IHistoricalViewProps(8, () => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(18)));
                                    }), new IHistoricalViewProps(9, (a) => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(23, a)));
                                    }), new IHistoricalViewProps(10, (a_1) => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(25, a_1)));
                                    }), new IHistoricalViewProps(13, toArray(choose((x_1) => {
                                        if (((x_1.sequenceIndex === model.selectedSequence.sequenceIndex) && (x_1.siteIndex === model.selectedSequence.siteIndex)) && (x_1.locationIndex === model.selectedSequence.locationIndex)) {
                                            return x_1.annotationData;
                                        }
                                        else {
                                            return void 0;
                                        }
                                    }, model.sessionAnnotations))), new IHistoricalViewProps(12, (a_2) => {
                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(34, a_2)));
                                    }), new IHistoricalViewProps(14, true)]), (props_19 = keyValueList(props_18, 1), react.createElement(HistoricalView, props_19)))), delay(() => {
                                        let children_23, children_21;
                                        return singleton_2((children_23 = [(children_21 = toList(delay(() => (equals(model.loadingState, new LoadingState(3)) ? map_1((tupledArg_3) => {
                                            const index_3 = tupledArg_3[0] | 0;
                                            const children_19 = toList(delay(() => append(tupledArg_3[1].flagged ? singleton_2(react.createElement("img", {
                                                src: "flag.svg",
                                                className: "frame-flag",
                                            })) : empty_1(), delay(() => {
                                                let props_22;
                                                return singleton_2((props_22 = toList(delay(() => append(singleton_2(new HTMLAttr(149, defaultArg(flatten(tryItem(index_3, model.thumbnailUrls)), "no-preview.png"))), delay(() => ((model.frameIndex === index_3) ? singleton_2(new HTMLAttr(64, "selected-frame")) : empty_1()))))), react.createElement("img", keyValueList(props_22, 1))));
                                            }))));
                                            return react.createElement("a", {
                                                onClick: (_arg13) => {
                                                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(12, index_3)));
                                                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalMsg(20)));
                                                },
                                            }, ...children_19);
                                        }, indexed(defaultArg(bind((list_12) => tryItem(model.selectedSequence.sequenceIndex, list_12), bind((list_11) => tryItem(model.selectedSequence.siteIndex, list_11), tryItem(model.selectedSequence.locationIndex, model.fileMetadata.frames))), singleton_1(defaultFrameFile)))) : empty_1()))), react.createElement("div", {
                                            className: "historical-timeline",
                                        }, ...children_21))], react.createElement("div", {
                                            className: "historical-timeline-container",
                                        }, ...children_23)));
                                    }));
                                }));
                            }
                        }
                    })), react.createElement("div", keyValueList(props_30, 1), ...children_25))));
                }
                case 4: {
                    return singleton_2((children_29 = [react.createElement("h4", {
                        className: "title",
                    }, "Failed to load DICOM files")], react.createElement("div", {
                        className: "historical-container",
                    }, ...children_29)));
                }
                default: {
                    throw (new Error("Match failure: Client.Patients.Historical.Common.LoadingState"));
                }
            }
        })), react.createElement("div", {
            className: "historical-grid",
        }, ...children_31))]))]));
    }, uncurry(4, (msg_4) => ((tupledArg_4) => {
        const token = tupledArg_4[0];
        const maybeS3Ctx = tupledArg_4[3];
        return (deps_1) => ((model_1) => {
            let inputRecord, inputRecord_1, matchValue_8;
            let pattern_matching_result_3, e, parsedFileNamesList, err, meta, err_1, fileArray, filePathsArray, err_2, i_2, url_1, filePathsArray_1, selectedSequence, index_4, delta, sortInfo_2, sortInfo_3, image, annotation_2, annotation_3, annotation_4, annotation_5, annotationUID, deletedAnnotationUID, annotations, err_3, state, setter, annotationUID_1;
            if (msg_4.tag === 1) {
                pattern_matching_result_3 = 1;
            }
            else if (msg_4.tag === 2) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_3 = 3;
                    err = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 2;
                    parsedFileNamesList = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 3) {
                pattern_matching_result_3 = 4;
            }
            else if (msg_4.tag === 4) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_3 = 6;
                    err_1 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 5;
                    meta = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 5) {
                pattern_matching_result_3 = 7;
            }
            else if (msg_4.tag === 6) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_3 = 9;
                    err_2 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 8;
                    fileArray = msg_4.fields[0].fields[0][0];
                    filePathsArray = msg_4.fields[0].fields[0][1];
                }
            }
            else if (msg_4.tag === 7) {
                pattern_matching_result_3 = 10;
            }
            else if (msg_4.tag === 8) {
                pattern_matching_result_3 = 11;
                i_2 = msg_4.fields[0];
                url_1 = msg_4.fields[1];
            }
            else if (msg_4.tag === 9) {
                pattern_matching_result_3 = 12;
                filePathsArray_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 10) {
                pattern_matching_result_3 = 13;
            }
            else if (msg_4.tag === 11) {
                pattern_matching_result_3 = 14;
                selectedSequence = msg_4.fields[0];
            }
            else if (msg_4.tag === 12) {
                pattern_matching_result_3 = 15;
                index_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 13) {
                pattern_matching_result_3 = 16;
                delta = msg_4.fields[0];
            }
            else if (msg_4.tag === 14) {
                pattern_matching_result_3 = 17;
                sortInfo_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 15) {
                pattern_matching_result_3 = 18;
                sortInfo_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 16) {
                pattern_matching_result_3 = 19;
                image = msg_4.fields[0];
            }
            else if (msg_4.tag === 17) {
                pattern_matching_result_3 = 20;
                annotation_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 18) {
                pattern_matching_result_3 = 21;
            }
            else if (msg_4.tag === 19) {
                pattern_matching_result_3 = 22;
            }
            else if (msg_4.tag === 20) {
                pattern_matching_result_3 = 23;
            }
            else if (msg_4.tag === 21) {
                pattern_matching_result_3 = 24;
            }
            else if (msg_4.tag === 22) {
                pattern_matching_result_3 = 25;
            }
            else if (msg_4.tag === 23) {
                pattern_matching_result_3 = 26;
                annotation_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 24) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_3 = 34;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 27;
                }
            }
            else if (msg_4.tag === 25) {
                pattern_matching_result_3 = 28;
                annotation_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 26) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_3 = 34;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 29;
                    annotation_5 = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 27) {
                pattern_matching_result_3 = 30;
                annotationUID = msg_4.fields[0];
            }
            else if (msg_4.tag === 28) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_3 = 34;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 31;
                    deletedAnnotationUID = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 29) {
                pattern_matching_result_3 = 32;
            }
            else if (msg_4.tag === 30) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_3 = 34;
                    err_3 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_3 = 33;
                    annotations = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 35) {
                pattern_matching_result_3 = 35;
            }
            else if (msg_4.tag === 32) {
                pattern_matching_result_3 = 36;
                state = msg_4.fields[0];
            }
            else if (msg_4.tag === 31) {
                pattern_matching_result_3 = 37;
                setter = msg_4.fields[0];
            }
            else if (msg_4.tag === 34) {
                pattern_matching_result_3 = 38;
                annotationUID_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 33) {
                pattern_matching_result_3 = 39;
            }
            else {
                pattern_matching_result_3 = 0;
                e = msg_4.fields[0];
            }
            switch (pattern_matching_result_3) {
                case 0: {
                    console.error(some(`Error: ${e}`));
                    return [model_1, Cmd_none()];
                }
                case 1: {
                    return (maybeS3Ctx == null) ? [model_1, Cmd_none()] : [model_1, Cmd_OfPromise_either((tupledArg_5) => Dicoms_loadAndParseFileList(tupledArg_5[0], tupledArg_5[1], tupledArg_5[2]), [model_1.patient.id, model_1.sessionId, maybeS3Ctx], (arg_3) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(2, new FSharpResult$2(0, arg_3)))), (arg_5) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(2, new FSharpResult$2(1, arg_5)))))];
                }
                case 2: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, new LoadingState(1), model_1.loadedDicomFiles, parsedFileNamesList, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(5))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(3)))]))];
                }
                case 3: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, new LoadingState(4), model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(0, err)))];
                }
                case 4: {
                    return [model_1, Cmd_OfAsyncWith_either((x_2) => {
                        Cmd_OfAsync_start(x_2);
                    }, (tupledArg_6) => FlaggedImages_loadFlaggedImagesPromise(tupledArg_6[0], tupledArg_6[1], tupledArg_6[2]), [model_1.sessionId, model_1.fileMetadata, token], (arg_6) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(4, arg_6))), (arg_9) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(4, new FSharpResult$2(1, new ErrorMessage(2, arg_9))))))];
                }
                case 5: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, meta, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 6: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(1, err_1))];
                }
                case 7: {
                    const matchValue_4 = [model_1.loadingState, maybeS3Ctx];
                    let pattern_matching_result_4, s3Ctx_2;
                    if (matchValue_4[0].tag === 1) {
                        if (matchValue_4[1] != null) {
                            pattern_matching_result_4 = 0;
                            s3Ctx_2 = matchValue_4[1];
                        }
                        else {
                            pattern_matching_result_4 = 1;
                        }
                    }
                    else if (matchValue_4[0].tag === 3) {
                        if (matchValue_4[1] != null) {
                            pattern_matching_result_4 = 0;
                            s3Ctx_2 = matchValue_4[1];
                        }
                        else {
                            pattern_matching_result_4 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_4 = 1;
                    }
                    switch (pattern_matching_result_4) {
                        case 0: {
                            return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, new LoadingState(1), model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfPromise_either(() => {
                                let pr_1;
                                const pr = map_1((frame_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                    const fullFilePath = `${model_1.patient.id}/${model_1.sessionId}/${frame_1.filePath}`;
                                    return Dicoms_loadDicomFromS3(s3Ctx_2, fullFilePath).then((_arg1_1) => {
                                        const maybeBlob = _arg1_1;
                                        return Promise.resolve([new File(toArray_1(delay(() => {
                                            if (maybeBlob == null) {
                                                return empty_1();
                                            }
                                            else {
                                                return singleton_2(maybeBlob);
                                            }
                                        })), (`${frame_1.filePath}`)), fullFilePath]);
                                    });
                                })), item_2(model_1.selectedSequence.sequenceIndex, item_2(model_1.selectedSequence.siteIndex, item_2(model_1.selectedSequence.locationIndex, model_1.fileMetadata.frames))));
                                pr_1 = (Promise.all(pr));
                                return pr_1.then(unzip);
                            }, void 0, (arg_12) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(6, new FSharpResult$2(0, arg_12)))), (arg_14) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(6, new FSharpResult$2(1, arg_14)))))];
                        }
                        case 1: {
                            return [model_1, Cmd_none()];
                        }
                    }
                }
                case 8: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, fileArray, model_1.fileMetadata, ofArray(map_2((_arg14) => (void 0), fileArray)), model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_3) => {
                        Cmd_OfAsync_start(x_3);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(0), ofArray(filePathsArray)), (arg_15) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_15)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(7))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(29)))]))];
                }
                case 9: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, new LoadingState(4), model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(0, err_2)))];
                }
                case 10: {
                    if (maybeS3Ctx == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const s3Ctx_3 = maybeS3Ctx;
                        return [model_1, Cmd_ofSub((dispatch_1) => {
                            let pr_3;
                            const pr_2 = map_1((tupledArg_7) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const thumbPath = replace(`${model_1.patient.id}/${model_1.sessionId}/${tupledArg_7[1].filePath}`, ".dcm", "-thumbnail.jpg");
                                return Thumbnails_loadThumbnailFromS3(s3Ctx_3, thumbPath).then((_arg2_1) => {
                                    iterate((url) => {
                                        dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalMsg(8, tupledArg_7[0], url)));
                                    }, toArray_2(_arg2_1));
                                    return Promise.resolve(thumbPath);
                                });
                            })), indexed(item_2(model_1.selectedSequence.sequenceIndex, item_2(model_1.selectedSequence.siteIndex, item_2(model_1.selectedSequence.locationIndex, model_1.fileMetadata.frames)))));
                            pr_3 = (Promise.all(pr_2));
                            void (pr_3.then((arg_18) => {
                                dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalMsg(9, arg_18)));
                            }));
                        })];
                    }
                }
                case 11: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, updateAt(i_2, url_1, model_1.thumbnailUrls), model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 12: {
                    return [model_1, Cmd_OfAsyncWith_attempt((x_4) => {
                        Cmd_OfAsync_start(x_4);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(1), ofArray(filePathsArray_1)), (arg_19) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_19))))];
                }
                case 13: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, new LoadingState(3), model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 14: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, selectedSequence, 0, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, void 0, model_1.sessionAnnotationNoteState), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(5))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(20)))]))];
                }
                case 15: {
                    const clampedIndex = min(comparePrimitives, -1 + length(item_2(model_1.selectedSequence.sequenceIndex, item_2(model_1.selectedSequence.siteIndex, item_2(model_1.selectedSequence.locationIndex, model_1.fileMetadata.frames)))), max(comparePrimitives, 0, index_4)) | 0;
                    if (clampedIndex !== model_1.frameIndex) {
                        customEvent(eventNames.SetFrame, new SetFrameEvent(0, clampedIndex));
                        return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, clampedIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, void 0, model_1.sessionAnnotationNoteState), Cmd_none()];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 16: {
                    return [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(12, model_1.frameIndex + ((delta > 0) ? 1 : -1)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(20)))]))];
                }
                case 17: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, sortInfo_2, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 18: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, sortInfo_3, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 19: {
                    const newSelectedSequence = new SelectedSequence(image.locationIndex, image.siteIndex, image.sequenceIndex);
                    return equals(newSelectedSequence, model_1.selectedSequence) ? [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(12, image.frameIndex)))] : [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, newSelectedSequence, image.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(5)))];
                }
                case 20: {
                    let targetIndex;
                    const matchValue_5 = match(create("\"sliceIndex\":(\\d+)"), annotation_2.annotationData);
                    targetIndex = ((matchValue_5.length === 2) ? parse(matchValue_5[1] || "", 511, false, 32) : 0);
                    customEvent(eventNames.SelectAnnotation, annotation_2.annotationId);
                    const newSelectedSequence_1 = new SelectedSequence(annotation_2.locationIndex, annotation_2.siteIndex, annotation_2.sequenceIndex);
                    if (equals(newSelectedSequence_1, model_1.selectedSequence)) {
                        const clampedIndex_1 = min(comparePrimitives, -1 + length(item_2(model_1.selectedSequence.sequenceIndex, item_2(model_1.selectedSequence.siteIndex, item_2(model_1.selectedSequence.locationIndex, model_1.fileMetadata.frames)))), max(comparePrimitives, 0, targetIndex)) | 0;
                        if (clampedIndex_1 !== model_1.frameIndex) {
                            customEvent(eventNames.SetFrame, new SetFrameEvent(0, clampedIndex_1));
                        }
                        return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, clampedIndex_1, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, annotation_2, model_1.sessionAnnotationNoteState), Cmd_none()];
                    }
                    else {
                        return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, newSelectedSequence_1, targetIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, annotation_2, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(5)))];
                    }
                }
                case 21: {
                    const flaggedImageListItem = FlaggedImages_mkFlaggedImageListItemFromSelected(model_1.fileMetadata, model_1.selectedSequence, model_1.frameIndex);
                    const maybeFrameNumber = FlaggedImages_tryGetFrameNumber(model_1.fileMetadata, flaggedImageListItem, model_1.frameIndex);
                    if (maybeFrameNumber == null) {
                        return [model_1, error("Failed to toggle image flag, something went wrong")];
                    }
                    else {
                        const frameNumber = maybeFrameNumber | 0;
                        return (!item_2(model_1.frameIndex, item_2(model_1.selectedSequence.sequenceIndex, item_2(model_1.selectedSequence.siteIndex, item_2(model_1.selectedSequence.locationIndex, model_1.fileMetadata.frames)))).flagged) ? [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, (inputRecord = model_1.fileMetadata, new FileMetadata(inputRecord.locationNames, inputRecord.siteNames, inputRecord.sequenceNames, FlaggedImages_flagFrame(true, model_1.fileMetadata.frames, model_1.selectedSequence.locationIndex, model_1.selectedSequence.siteIndex, model_1.selectedSequence.sequenceIndex, model_1.frameIndex), cons(flaggedImageListItem, model_1.fileMetadata.flaggedImages), inputRecord.frameToSequenceMap)), model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfAsyncWith_attempt((x_11) => {
                            Cmd_OfAsync_start(x_11);
                        }, securedApi(token).flagSessionImage, [model_1.sessionId, frameNumber], (arg_21) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_21))))] : [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, (inputRecord_1 = model_1.fileMetadata, new FileMetadata(inputRecord_1.locationNames, inputRecord_1.siteNames, inputRecord_1.sequenceNames, FlaggedImages_flagFrame(false, model_1.fileMetadata.frames, model_1.selectedSequence.locationIndex, model_1.selectedSequence.siteIndex, model_1.selectedSequence.sequenceIndex, model_1.frameIndex), defaultArg(map((i_3) => removeAt(i_3, model_1.fileMetadata.flaggedImages), tryFindIndex((f) => {
                            if (((f.locationIndex === model_1.selectedSequence.locationIndex) && (f.siteIndex === model_1.selectedSequence.siteIndex)) && (f.sequenceIndex === model_1.selectedSequence.sequenceIndex)) {
                                return f.frameIndex === model_1.frameIndex;
                            }
                            else {
                                return false;
                            }
                        }, model_1.fileMetadata.flaggedImages)), model_1.fileMetadata.flaggedImages), inputRecord_1.frameToSequenceMap)), model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfAsyncWith_attempt((x_12) => {
                            Cmd_OfAsync_start(x_12);
                        }, securedApi(token).unflagSessionImage, [model_1.sessionId, frameNumber], (arg_23) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_23))))];
                    }
                }
                case 22: {
                    return model_1.isPlaybackPaused ? [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, false, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(21)))] : [model_1, Cmd_none()];
                }
                case 23: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, true, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 24: {
                    const newIndex_1 = (model_1.frameIndex + 1) | 0;
                    return (model_1.isPlaybackPaused ? true : (newIndex_1 > (-1 + length(item_2(model_1.selectedSequence.sequenceIndex, item_2(model_1.selectedSequence.siteIndex, item_2(model_1.selectedSequence.locationIndex, model_1.fileMetadata.frames))))))) ? [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, true, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()] : [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(12, newIndex_1))), Cmd_OfAsyncWith_result((x_14) => {
                        Cmd_OfAsync_start(x_14);
                    }, playbackTick())]))];
                }
                case 25: {
                    return [model_1, Cmd_batch(ofArray([Navigation_newUrl(pageHash(new Page(6, model_1.patient.id, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))]))];
                }
                case 26: {
                    const vm = new SessionAnnotationViewModel(model_1.sessionId, annotation_3.annotationUID, annotation_3.annotationData, model_1.selectedSequence.locationIndex, model_1.selectedSequence.siteIndex, model_1.selectedSequence.sequenceIndex, void 0, annotation_3.toolName);
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, append_1(model_1.sessionAnnotations, singleton_1(vm)), model_1.loadingAnnotations, true, vm, new SessionAnnotationNoteState(0)), Cmd_OfAsyncWith_either((x_15) => {
                        Cmd_OfAsync_start(x_15);
                    }, securedApi(token).upsertSessionAnnotation, vm, (arg_25) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(24, arg_25))), (arg_26) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_26))))];
                }
                case 27: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, false, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 28: {
                    let vm_1;
                    const matchValue_7 = [tryFind((sa) => (sa.annotationId === annotation_4.annotationUID), model_1.sessionAnnotations), model_1.selectedAnnotation];
                    let pattern_matching_result_5, a_7;
                    if (matchValue_7[0] == null) {
                        if (matchValue_7[1] != null) {
                            if (annotation_4.annotationUID === matchValue_7[1].annotationId) {
                                pattern_matching_result_5 = 0;
                                a_7 = matchValue_7[1];
                            }
                            else {
                                pattern_matching_result_5 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_5 = 1;
                        }
                    }
                    else if (annotation_4.annotationUID === matchValue_7[0].annotationId) {
                        pattern_matching_result_5 = 0;
                        a_7 = matchValue_7[0];
                    }
                    else {
                        pattern_matching_result_5 = 1;
                    }
                    switch (pattern_matching_result_5) {
                        case 0: {
                            vm_1 = (new SessionAnnotationViewModel(model_1.sessionId, annotation_4.annotationUID, annotation_4.annotationData, model_1.selectedSequence.locationIndex, model_1.selectedSequence.siteIndex, model_1.selectedSequence.sequenceIndex, a_7.note, a_7.annotationName));
                            break;
                        }
                        case 1: {
                            vm_1 = (new SessionAnnotationViewModel(model_1.sessionId, annotation_4.annotationUID, annotation_4.annotationData, model_1.selectedSequence.locationIndex, model_1.selectedSequence.siteIndex, model_1.selectedSequence.sequenceIndex, void 0, annotation_4.toolName));
                            break;
                        }
                    }
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, true, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_OfAsyncWith_either((x_16) => {
                        Cmd_OfAsync_start(x_16);
                    }, securedApi(token).upsertSessionAnnotation, vm_1, (arg_28) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(26, arg_28))), (arg_29) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_29))))];
                }
                case 29: {
                    return [(matchValue_8 = [tryFind((sa_1) => (sa_1.annotationId === annotation_5.annotationId), model_1.sessionAnnotations), model_1.sessionAnnotationNoteState], (matchValue_8[0] != null) ? (new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, false, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState)) : ((matchValue_8[1].tag === 2) ? (new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, false, annotation_5, model_1.sessionAnnotationNoteState)) : (new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, false, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalMsg(29)))];
                }
                case 30: {
                    return [model_1, Cmd_OfAsyncWith_either((x_17) => {
                        Cmd_OfAsync_start(x_17);
                    }, securedApi(token).deleteSessionAnnotation, [model_1.sessionId, annotationUID], (arg_31) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(28, arg_31))), (arg_32) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_32))))];
                }
                case 31: {
                    customEvent(eventNames.RemoveAnnotation, deletedAnnotationUID);
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, filter((sa_2) => (sa_2.annotationId !== deletedAnnotationUID), model_1.sessionAnnotations), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, new SessionAnnotationNoteState(2)), Cmd_none()];
                }
                case 32: {
                    return [model_1, Cmd_OfAsyncWith_either((x_18) => {
                        Cmd_OfAsync_start(x_18);
                    }, securedApi(token).getAllSessionAnnotationsForSessionId, model_1.sessionId, (arg_34) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(30, arg_34))), (arg_35) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_35))))];
                }
                case 33: {
                    const matchValue_9 = model_1.selectedAnnotation;
                    if (matchValue_9 != null) {
                        const selectedAnnotation_6 = matchValue_9;
                        const node = document.getElementById(selectedAnnotation_6.annotationId);
                        if (equals(node, null)) {
                        }
                        else {
                            node.scrollIntoView();
                        }
                    }
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, annotations, false, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 34: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, false, model_1.savingAnnotations, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState), error(ErrorMessage_get_describe()(err_3))];
                }
                case 35: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(5, "Historical Comparison")))];
                }
                case 36: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, state), Cmd_none()];
                }
                case 37: {
                    return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, map(setter, model_1.selectedAnnotation), model_1.sessionAnnotationNoteState), Cmd_none()];
                }
                case 38: {
                    if (equals(model_1.sessionAnnotationNoteState, new SessionAnnotationNoteState(2))) {
                        const maybeAnnotationID = isNullOrWhiteSpace(annotationUID_1) ? (void 0) : annotationUID_1;
                        const node_1 = document.getElementById(`${annotationUID_1}`);
                        if (equals(node_1, null)) {
                        }
                        else {
                            node_1.scrollIntoView();
                        }
                        return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, tryFind((sa_3) => equals(sa_3.annotationId, maybeAnnotationID), model_1.sessionAnnotations), model_1.sessionAnnotationNoteState), Cmd_none()];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 39: {
                    const matchValue_11 = model_1.selectedAnnotation;
                    if (matchValue_11 == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const annotation_6 = matchValue_11;
                        customEvent(eventNames.SetAnnotationText, {
                            annotationText: defaultArg(annotation_6.note, ""),
                            annotationUID: annotation_6.annotationId,
                        });
                        return [new PatientHistoricalModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.selectedSequence, model_1.frameIndex, model_1.loadingState, model_1.loadedDicomFiles, model_1.fileMetadata, model_1.thumbnailUrls, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.isPlaybackPaused, map_1((sa_4) => {
                            if (sa_4.annotationId === annotation_6.annotationId) {
                                return annotation_6;
                            }
                            else {
                                return sa_4;
                            }
                        }, model_1.sessionAnnotations), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.selectedAnnotation, new SessionAnnotationNoteState(2)), Cmd_OfAsyncWith_either((x_19) => {
                            Cmd_OfAsync_start(x_19);
                        }, securedApi(token).upsertSessionAnnotation, annotation_6, (arg_37) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(24, arg_37))), (arg_38) => (new StateTreeMsg$4(0, new PatientHistoricalMsg(0, arg_38))))];
                    }
                }
            }
        });
    })));
}

