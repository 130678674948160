import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { OptiscanUserData$reflection, UserRole$reflection } from "../Optiscan.Models/Security.js";
import { Security_UserViewModel$2$reflection } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { union_type, lambda_type, class_type, string_type, record_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";

export class InitArgs extends Record {
    constructor(user) {
        super();
        this.user = user;
    }
}

export function InitArgs$reflection() {
    return record_type("Optiscan.Client.EmailChange.Types.InitArgs", [], InitArgs, () => [["user", Security_UserViewModel$2$reflection(UserRole$reflection(), OptiscanUserData$reflection())]]);
}

export class Model extends Record {
    constructor(CurrentEmail, NewEmail) {
        super();
        this.CurrentEmail = CurrentEmail;
        this.NewEmail = NewEmail;
    }
}

export function Model$reflection() {
    return record_type("Optiscan.Client.EmailChange.Types.Model", [], Model, () => [["CurrentEmail", string_type], ["NewEmail", string_type]]);
}

export function Model_get_currentEmail_() {
    return [(m) => m.CurrentEmail, (v) => ((m_1) => (new Model(v, m_1.NewEmail)))];
}

export function Model_get_newEmail_() {
    return [(m) => m.NewEmail, (v) => ((m_1) => (new Model(m_1.CurrentEmail, v)))];
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "SetVm"];
    }
}

export function LocalMsg$reflection() {
    return union_type("Optiscan.Client.EmailChange.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [["Item", lambda_type(Model$reflection(), Model$reflection())]]]);
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg$reflection() {
    return class_type("Optiscan.Client.EmailChange.Types.InboundMsg", void 0, InboundMsg);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CancelEmailChange"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("Optiscan.Client.EmailChange.Types.OutboundMsg", [], OutboundMsg, () => [[]]);
}

