import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Item_Option, Item_div, navbar } from "../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { StateTree$3__getIndexPathFromCursor_1334CEF1, StateTree$3__get_root } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { stateTree } from "./TelepathologyGuestTree.js";
import { Msg } from "./TelepathologyGuestTypes.js";
import { ErasedMsg$2 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";

export function view(model, dispatch) {
    let model_1;
    const matchValue = model.maybeCode;
    if (matchValue == null) {
        return content(empty(), singleton(navbar(empty(), singleton(Item_div(singleton(new Item_Option(6, "tele-nav-string")), singleton("Invalid guest link"))))));
    }
    else {
        const token = matchValue;
        return content(empty(), singleton((model_1 = model.telepathologyGuestTreeModel, StateTree$3__get_root(stateTree).View(StateTree$3__getIndexPathFromCursor_1334CEF1(stateTree, model_1.cursor), (arg) => {
            ((arg_1) => {
                dispatch(new Msg(2, arg_1));
            })(new ErasedMsg$2(0, arg));
        }, model_1.value))));
    }
}

