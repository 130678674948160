import { uncurry, curry, compare, comparePrimitives, int32ToString } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_itemListSortable, Sort_SortableColumn$1, Sort_Compare$1 } from "../../Optiscan.SharedUI/Sort.js";
import { substring } from "../../fable_modules/fable-library.3.7.12/String.js";
import { sortWith, empty, ofArray, singleton } from "../../fable_modules/fable-library.3.7.12/List.js";
import { content, Header_title, header, card } from "../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Common_GenericOption } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty as empty_1 } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";

export const flaggedImagesTableColumns = ofArray([new Sort_SortableColumn$1("Frame", (fi) => int32ToString(fi.frameIndex), new Sort_Compare$1(0, (fi1, fi2) => comparePrimitives(fi1.frameIndex, fi2.frameIndex))), new Sort_SortableColumn$1("Location", (fi_1) => {
    const fullLocation = `${fi_1.locationName}, ${fi_1.siteName}, ${fi_1.sequenceName}`;
    if (fullLocation.length > 55) {
        const cutLocation = substring(fullLocation, 0, 52);
        return `${cutLocation}...`;
    }
    else {
        return fullLocation;
    }
}, new Sort_Compare$1(0, (fi1_1, fi2_1) => compare(singleton([fi1_1.locationIndex, fi1_1.siteIndex, fi1_1.sequenceIndex]), singleton([fi2_1.locationIndex, fi2_1.siteIndex, fi2_1.sequenceIndex]))))]);

export function flaggedImagesTableCard(metadata, sortInfo, selectCallback, sortCallback) {
    let children;
    return card(ofArray([new Common_GenericOption(0, "embed-card"), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "1em",
    }]))]), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Flagged Images")))), content(empty(), singleton((children = [Sort_itemListSortable("FlaggedImages", flaggedImagesTableColumns)(curry(2, selectCallback))(sortWith(uncurry(2, Sort_SortInfo$1_getSortComparison_576B9F7E(sortInfo)), metadata.flaggedImages))((_arg1) => empty_1())(sortInfo)(curry(2, sortCallback))], react.createElement("div", {
        className: "table-container",
    }, ...children))))]));
}

