import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { StateTree$3__getIndexPathFromCursor_1334CEF1, StateTree$3__get_root } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Msg } from "./AccountsTypes.js";
import { ErasedMsg$2 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";

export function view(model, dispatch) {
    return content(empty(), singleton(defaultArg(map((tree) => {
        if (!(model.crudListModel.value == null)) {
            const this$ = tree;
            const model_1 = model.crudListModel;
            return StateTree$3__get_root(this$).View(StateTree$3__getIndexPathFromCursor_1334CEF1(this$, model_1.cursor), (arg) => {
                ((arg_1) => {
                    dispatch(new Msg(3, arg_1));
                })(new ErasedMsg$2(0, arg));
            }, model_1.value);
        }
        else {
            return Helpers_nothing;
        }
    }, model.stateTree), Helpers_nothing)));
}

