import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, int32_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class AnatomicalRegionViewModel extends Record {
    constructor(sessionId, frameNumber, region, site, sequence) {
        super();
        this.sessionId = sessionId;
        this.frameNumber = (frameNumber | 0);
        this.region = region;
        this.site = site;
        this.sequence = sequence;
    }
}

export function AnatomicalRegionViewModel$reflection() {
    return record_type("Optiscan.Models.View.AnatomicalRegion.AnatomicalRegionViewModel", [], AnatomicalRegionViewModel, () => [["sessionId", class_type("System.Guid")], ["frameNumber", int32_type], ["region", string_type], ["site", string_type], ["sequence", string_type]]);
}

