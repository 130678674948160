import { value as value_12, some, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { cons, find, map as map_1, toArray, ofArray, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { TelepathologyMsg, TelepathologyModel, StreamStatus } from "./TelepathologyTypes.js";
import { Cmd_OfAsyncWith_result, Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_ofSub, Cmd_none, Cmd_OfPromise_either, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { error as error_1 } from "../Optiscan.SharedUI/Toast.js";
import { mkKinesisVideoSignalingChannelsClient, mkKinesisVideoClient } from "../Common/AwsCommon.js";
import { KinesisVideo_SignalingClientParams, KinesisVideo_SignalingClientParams_get_create, KinesisVideo_GetIceServerConfigParams, KinesisVideo_GetIceServerConfigParams_get_create, KinesisVideo_GetSignalingChannelEndpointParams, KinesisVideo_SingleMasterChannelEndpointConfigurationParams, KinesisVideo_SingleMasterChannelEndpointConfigurationParams_get_create, KinesisVideo_GetSignalingChannelEndpointParams_get_create, KinesisVideo_DescribeSignalingChannelParams, KinesisVideo_DescribeSignalingChannelParams_get_create } from "../bindings/Aws/Fable.Helpers.Aws.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { toConsole } from "../fable_modules/fable-library.3.7.12/String.js";
import { map as map_2 } from "../fable_modules/fable-library.3.7.12/Array.js";
import { newGuid } from "../fable_modules/fable-library.3.7.12/Guid.js";
import * as SignalingClient from "../../../node_modules/amazon-kinesis-video-streams-webrtc/lib/SignalingClient.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { throwIfCancellationRequested, sleep, createCancellationToken, cancel } from "../fable_modules/fable-library.3.7.12/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.12/AsyncBuilder.js";

export function init(deps) {
    return [new TelepathologyModel(deps.patient, defaultArg(map((s) => s.sessionId, deps.activeSession), "00000000-0000-0000-0000-000000000000"), defaultArg(map((s_1) => s_1.region, deps.activeSession), ""), void 0, void 0, void 0, "", empty(), [], void 0, void 0, new StreamStatus(0), void 0, defaultArg(map((s_2) => s_2.sessionState, deps.activeSession), 3), empty()), Cmd_OfFunc_result(new TelepathologyMsg(2))];
}

export function update(msg, api, maybeCredentials, maybeKvsConfig, model) {
    let copyOfStruct;
    if (msg.tag === 1) {
        console.error(some(`WebRTC Error: ${msg.fields[0].message}`));
        return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new TelepathologyMsg(10, new StreamStatus(4))), error_1("Unable to establish connection")]))];
    }
    else if (msg.tag === 2) {
        return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new TelepathologyMsg(3)), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, api.getCurrentAnatomicalRegion, model.sessionId, (arg0_1) => (new TelepathologyMsg(9, arg0_1)), (arg0_2) => (new TelepathologyMsg(0, arg0_2))), Cmd_OfFunc_result(new TelepathologyMsg(14))]))];
    }
    else if (msg.tag === 3) {
        if (maybeCredentials == null) {
            console.error(some("Missing credentials"));
            return [model, error_1("Missing credentials")];
        }
        else {
            const videoClient = mkKinesisVideoClient(model.sessionRegion, maybeCredentials);
            const describeSignalingChannelParams = KinesisVideo_DescribeSignalingChannelParams_get_create()(new KinesisVideo_DescribeSignalingChannelParams(model.patient.deviceId, void 0));
            const describeSignalingChannelRequest = videoClient.describeSignalingChannel(describeSignalingChannelParams);
            return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, model.lastKnownSessionState, model.dataChannels), Cmd_OfPromise_either(() => describeSignalingChannelRequest.promise(), void 0, (arg0_4) => (new TelepathologyMsg(4, arg0_4)), (arg0_5) => (new TelepathologyMsg(1, arg0_5)))];
        }
    }
    else if (msg.tag === 4) {
        const matchValue = [msg.fields[0].ChannelInfo, model.videoClient];
        let pattern_matching_result, info, videoClient_2;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                pattern_matching_result = 0;
                info = matchValue[0];
                videoClient_2 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                console.info(some(`VIEWER ${info.ChannelARN}`));
                const getSignalingChannelEndpointInput = KinesisVideo_GetSignalingChannelEndpointParams_get_create()(new KinesisVideo_GetSignalingChannelEndpointParams(info.ChannelARN, KinesisVideo_SingleMasterChannelEndpointConfigurationParams_get_create()(new KinesisVideo_SingleMasterChannelEndpointConfigurationParams(toArray(ofArray(["WSS", "HTTPS"])), "VIEWER"))));
                const getSignalingChannelEndpointRequest = videoClient_2.getSignalingChannelEndpoint(getSignalingChannelEndpointInput);
                return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, defaultArg(info.ChannelARN, ""), model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, model.lastKnownSessionState, model.dataChannels), Cmd_OfPromise_either(() => getSignalingChannelEndpointRequest.promise(), void 0, (arg0_6) => (new TelepathologyMsg(5, arg0_6)), (arg0_7) => (new TelepathologyMsg(1, arg0_7)))];
            }
            case 1: {
                return [model, Cmd_none()];
            }
        }
    }
    else if (msg.tag === 5) {
        if (maybeCredentials == null) {
            console.error(some("Missing credentials"));
            return [model, error_1("Missing credentials")];
        }
        else {
            const credentials_1 = maybeCredentials;
            const matchValue_1 = msg.fields[0].ResourceEndpointList;
            if (matchValue_1 == null) {
                return [model, Cmd_none()];
            }
            else {
                const endpoints = matchValue_1;
                const endpointsList = ofArray(endpoints.slice());
                console.info(some(`VIEWER ${map_1((e_2) => [e_2.Protocol, e_2.ResourceEndpoint], endpointsList)}`));
                const httpsEndpointListItem = find((endpoint) => equals(endpoint.Protocol, some("HTTPS")), endpointsList);
                toConsole(`VIEWER: ${httpsEndpointListItem.Protocol} ${httpsEndpointListItem.ResourceEndpoint}`);
                const signalingChannelsClient = mkKinesisVideoSignalingChannelsClient(model.sessionRegion, credentials_1, defaultArg(httpsEndpointListItem.ResourceEndpoint, ""));
                const iceServerConfigParams = KinesisVideo_GetIceServerConfigParams_get_create()(new KinesisVideo_GetIceServerConfigParams(model.channelARN, void 0, void 0, void 0));
                const request = signalingChannelsClient.getIceServerConfig(iceServerConfigParams);
                return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, signalingChannelsClient, model.signalingClient, model.channelARN, endpointsList, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, model.lastKnownSessionState, model.dataChannels), Cmd_OfPromise_either(() => request.promise(), void 0, (arg0_9) => (new TelepathologyMsg(6, arg0_9)), (arg0_10) => (new TelepathologyMsg(1, arg0_10)))];
            }
        }
    }
    else if (msg.tag === 6) {
        if (maybeCredentials == null) {
            console.error(some("Missing credentials"));
            return [model, error_1("Missing credentials")];
        }
        else {
            const credentials_2 = maybeCredentials;
            const matchValue_2 = msg.fields[0].IceServerList;
            if (matchValue_2 == null) {
                return [model, Cmd_none()];
            }
            else {
                const iceServers = matchValue_2;
                console.info(some(`VIEWER: ${map_2((i) => i.Uris, iceServers.slice())}`));
                const iceServerList = map_2((i_1) => {
                    let value_2;
                    return new Object({urls: ((value_2 = [], defaultArg(map((u) => u.slice(), i_1.Uris), value_2))), username: defaultArg(i_1.Username, ""), credential: defaultArg(i_1.Password, ""), credentialType: "password"});
                }, iceServers.slice());
                let endpoint_1;
                const e_4 = find((e_3) => equals(e_3.Protocol, some("WSS")), model.endpoints);
                endpoint_1 = defaultArg(e_4.ResourceEndpoint, "");
                console.debug(`VIEWER: endpoint ${endpoint_1}`);
                const signalingClientConfig = KinesisVideo_SignalingClientParams_get_create()(new KinesisVideo_SignalingClientParams(model.channelARN, endpoint_1, (copyOfStruct = newGuid(), copyOfStruct), credentials_2, model.sessionRegion, "VIEWER", 0));
                const signalingClient = new SignalingClient.SignalingClient(signalingClientConfig);
                let rtcConfiguration;
                const x_1 = new Object({iceServers: iceServerList});
                x_1.iceTransportPolicy = "relay";
                rtcConfiguration = x_1;
                const rtcPeerConnection = new RTCPeerConnection(rtcConfiguration);
                const rtcDataChannel = rtcPeerConnection.createDataChannel("kvsDataChannel");
                rtcDataChannel.onopen = ((event) => {
                    rtcDataChannel.send("Opened data channel by viewer");
                });
                console.info(some(`VIEWER ${rtcConfiguration.iceServers}`));
                signalingClient.on("open", (_arg1) => {
                    console.info(some("VIEWER: Connected to signaling service"));
                    let pr_1;
                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (rtcPeerConnection.createOffer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true,
                    }).then((_arg1_1) => (rtcPeerConnection.setLocalDescription(_arg1_1).then(() => {
                        console.info(some("VIEWER: Set Local Description"));
                        signalingClient.sendSdpOffer(value_12(rtcPeerConnection.localDescription));
                        return Promise.resolve();
                    }))))));
                    pr_1 = (pr.catch((err) => {
                        console.error(some(err));
                    }));
                    void pr_1;
                    console.info(some("VIEWER: Generating ICE candidates"));
                });
                signalingClient.on("sdpAnswer", (answer) => {
                    console.info(some(`VIEWER: Got Answer ${answer.sdp} ${answer.type}`));
                    let pr_3;
                    const pr_2 = rtcPeerConnection.setRemoteDescription(answer).then(() => {
                        console.info(some(`VIEWER: Set Remote Description ${rtcPeerConnection.remoteDescription}`));
                    });
                    pr_3 = (pr_2.catch((err_1) => {
                        console.error(some(err_1));
                    }));
                    void pr_3;
                });
                signalingClient.on("iceCandidate", (candidate) => {
                    console.info(some("VIEWER: Got Ice Candidate"));
                    let pr_5;
                    const pr_4 = rtcPeerConnection.addIceCandidate(candidate);
                    pr_5 = (pr_4.catch((err_2) => {
                        console.error(some(err_2));
                    }));
                    void pr_5;
                });
                signalingClient.on("close", (_arg3) => {
                    console.info(some("VIEWER: Disconnected from signaling channel"));
                });
                signalingClient.on("error", (err_3) => {
                    console.error(some(err_3));
                });
                rtcPeerConnection.onicecandidate = ((candidate_1) => {
                    console.info(some("VIEWER: PeerConnection Generated ICE candidate"));
                    const matchValue_3 = candidate_1.candidate;
                    if (matchValue_3 == null) {
                        console.info(some("VIEWER: All ICE candidates have been generated"));
                    }
                    else {
                        const candidate_2 = matchValue_3;
                        signalingClient.sendIceCandidate(candidate_2);
                    }
                });
                const dataChannelErrorListener = Cmd_ofSub((dispatch) => {
                    rtcDataChannel.onerror = ((error) => {
                        console.error(some(error.message));
                        dispatch(new TelepathologyMsg(13, "System experienced an unexpected issue, please reconnect to the stream"));
                    });
                });
                const peerConnectionDataChannelListener = Cmd_ofSub((dispatch_1) => {
                    rtcPeerConnection.ondatachannel = ((event_1) => {
                        dispatch_1(new TelepathologyMsg(16, event_1.channel));
                    });
                });
                const peerConnectionStateChangeListener = Cmd_ofSub((dispatch_2) => {
                    rtcPeerConnection.onconnectionstatechange = ((event_2) => {
                        dispatch_2(new TelepathologyMsg(17, event_2.target.connectionState));
                    });
                });
                const iceConnectionStateChangeListener = Cmd_ofSub((dispatch_3) => {
                    rtcPeerConnection.oniceconnectionstatechange = ((event_3) => {
                        dispatch_3(new TelepathologyMsg(18, event_3.target.iceConnectionState));
                    });
                });
                const signalingStateChangeListener = Cmd_ofSub((dispatch_4) => {
                    rtcPeerConnection.onsignalingstatechange = ((event_4) => {
                        console.info(some(`VIEWER: signaling state change ${event_4.target.signalingState}`));
                        if (event_4.target.signalingState === "closed") {
                            dispatch_4(new TelepathologyMsg(13, "System experienced an unexpected issue, please reconnect to the stream"));
                        }
                    });
                });
                signalingClient.open();
                return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, signalingClient, model.channelARN, model.endpoints, iceServerList, rtcPeerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, model.lastKnownSessionState, model.dataChannels), Cmd_batch(ofArray([dataChannelErrorListener, peerConnectionDataChannelListener, iceConnectionStateChangeListener, peerConnectionStateChangeListener, signalingStateChangeListener]))];
            }
        }
    }
    else if (msg.tag === 7) {
        console.info(some(`Flagging frame ${msg.fields[0]}`));
        return [model, Cmd_OfAsyncWith_attempt((x_2) => {
            Cmd_OfAsync_start(x_2);
        }, api.flagSessionImage, [model.sessionId, msg.fields[0]], (arg0_18) => (new TelepathologyMsg(0, arg0_18)))];
    }
    else if (msg.tag === 8) {
        const matchValue_5 = msg.fields[0].MessageType | 0;
        switch (matchValue_5) {
            case 0: {
                return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, 1, model.dataChannels), Cmd_batch(ofArray([Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, api.getCurrentAnatomicalRegion, model.sessionId, (arg0_19) => (new TelepathologyMsg(9, arg0_19)), (arg0_20) => (new TelepathologyMsg(0, arg0_20))), Cmd_OfFunc_result(new TelepathologyMsg(10, new StreamStatus(1)))]))];
            }
            case 1: {
                return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, 4, model.dataChannels), Cmd_OfFunc_result(new TelepathologyMsg(10, new StreamStatus(2)))];
            }
            case 2: {
                return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, 2, model.dataChannels), Cmd_OfFunc_result(new TelepathologyMsg(10, new StreamStatus(3)))];
            }
            default: {
                return [model, Cmd_none()];
            }
        }
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            return [model, error_1("Failed to get the current anatomical location")];
        }
        else {
            return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, msg.fields[0].fields[0], model.streamStatus, model.streamStatusCancellationTokenSource, model.lastKnownSessionState, model.dataChannels), Cmd_none()];
        }
    }
    else if (msg.tag === 10) {
        const matchValue_6 = model.streamStatusCancellationTokenSource;
        if (matchValue_6 == null) {
        }
        else {
            cancel(matchValue_6);
        }
        const newCts = createCancellationToken();
        let newStreamStatus;
        const matchValue_7 = [msg.fields[0], model.lastKnownSessionState];
        let pattern_matching_result_1;
        if (matchValue_7[1] === 2) {
            pattern_matching_result_1 = 0;
        }
        else if (matchValue_7[1] === 4) {
            if (matchValue_7[0].tag === 0) {
                pattern_matching_result_1 = 1;
            }
            else if (matchValue_7[0].tag === 4) {
                pattern_matching_result_1 = 1;
            }
            else {
                pattern_matching_result_1 = 2;
            }
        }
        else if (matchValue_7[0].tag === 0) {
            pattern_matching_result_1 = 1;
        }
        else if (matchValue_7[0].tag === 4) {
            pattern_matching_result_1 = 1;
        }
        else {
            pattern_matching_result_1 = 3;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                newStreamStatus = (new StreamStatus(3));
                break;
            }
            case 1: {
                newStreamStatus = msg.fields[0];
                break;
            }
            case 2: {
                newStreamStatus = (new StreamStatus(2));
                break;
            }
            case 3: {
                newStreamStatus = msg.fields[0];
                break;
            }
        }
        return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, newCts, model.lastKnownSessionState, model.dataChannels), Cmd_OfAsyncWith_result((x_4) => {
            Cmd_OfAsync_start(x_4);
        }, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(sleep(2000), () => {
            let copyOfStruct_1 = newCts;
            throwIfCancellationRequested(copyOfStruct_1);
            return singleton.Return(new TelepathologyMsg(11, newStreamStatus));
        })), (_arg4) => {
            throw _arg4;
            return null;
        })))];
    }
    else if (msg.tag === 11) {
        return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, msg.fields[0], void 0, model.lastKnownSessionState, model.dataChannels), Cmd_none()];
    }
    else if (msg.tag === 12) {
        const matchValue_8 = model.streamStatusCancellationTokenSource;
        if (matchValue_8 == null) {
            return [model, Cmd_none()];
        }
        else {
            cancel(matchValue_8);
            return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, void 0, model.lastKnownSessionState, model.dataChannels), Cmd_none()];
        }
    }
    else if (msg.tag === 13) {
        return [model, error_1(msg.fields[0])];
    }
    else if (msg.tag === 14) {
        return [model, Cmd_OfAsyncWith_result((x_5) => {
            Cmd_OfAsync_start(x_5);
        }, singleton.Delay(() => singleton.Bind(sleep(30000), () => singleton.Return(new TelepathologyMsg(15)))))];
    }
    else if (msg.tag === 15) {
        return [model, (model.streamStatus.tag === 0) ? Cmd_OfFunc_result(new TelepathologyMsg(13, "Establishing a connection is taking longer than expected")) : Cmd_none()];
    }
    else if (msg.tag === 16) {
        console.info(some(`VIEWER: PeerConnection Data Channel ${msg.fields[0].label}`));
        return [new TelepathologyModel(model.patient, model.sessionId, model.sessionRegion, model.videoClient, model.signalingChannelsClient, model.signalingClient, model.channelARN, model.endpoints, model.iceServers, model.peerConnection, model.anatomicalRegion, model.streamStatus, model.streamStatusCancellationTokenSource, model.lastKnownSessionState, cons(msg.fields[0], model.dataChannels)), Cmd_none()];
    }
    else if (msg.tag === 18) {
        console.info(some(`VIEWER: iceCandidateConnection state change ${model.streamStatus} ${msg.fields[0]}`));
        return [model, (msg.fields[0] === "closed") ? Cmd_OfFunc_result(new TelepathologyMsg(13, "Stream closed unexpectedly, please reconnect to the stream.  If issue persist please contact Optiscan support")) : ((msg.fields[0] === "disconnected") ? ((model.streamStatus.tag === 1) ? Cmd_OfFunc_result(new TelepathologyMsg(13, "System experienced an unexpected stream disconnection, please reconnect to the stream")) : Cmd_none()) : ((msg.fields[0] === "failed") ? Cmd_OfFunc_result(new TelepathologyMsg(13, "Connection to stream failed, please refresh the page to reconnect")) : Cmd_none()))];
    }
    else if (msg.tag === 17) {
        console.log(some(`VIEWER: peerConnection state change $${msg.fields[0]}`));
        return [model, Cmd_OfFunc_result(new TelepathologyMsg(10, (msg.fields[0] === "connected") ? (new StreamStatus(1)) : ((msg.fields[0] === "connecting") ? (new StreamStatus(0)) : (new StreamStatus(4)))))];
    }
    else {
        console.error(some(`Error: ${msg.fields[0]}`));
        return [model, Cmd_none()];
    }
}

