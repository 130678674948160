import { Common_InputElement_ref, Common_mkId } from "../Common/Common.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { map, ofArray, singleton as singleton_1, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { singleton, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Option as Option_2, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option, label as label_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_1, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Common_EmailAddress_value_Z39D6D3C1, Security_LoginViewModel } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { Msg } from "./AuthTypes.js";
import { Option as Option_3, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import * as react from "react";
import { Page, AuthPage, pageHash } from "../Pages.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { printf, toText, join } from "../fable_modules/fable-library.3.7.12/String.js";
import { UserRole_get_describe } from "../Optiscan.Models/Security.js";

export function mkLoginViewId(label, element) {
    return Common_mkId("Login", label, element);
}

export function view(model, dispatch) {
    return container(empty(), toList(delay(() => {
        let children_6, children_2, props_4, children, arg20, arg10;
        const matchValue = model.UserInfo;
        if (matchValue == null) {
            return singleton((children_6 = [h3(empty())(singleton_1("Login")), (children_2 = [div(empty(), ofArray([label_1(singleton_1(new Option(3, singleton_1(mkLoginViewId("Email", new Naming_UIElement(1, new Naming_UIElement(0)))))), singleton_1("Email")), div_1(empty(), singleton_1(input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(15, ofArray([mkLoginViewId("Email", new Naming_UIElement(0)), new HTMLAttr(54, "email")])), new Option_1(14, (e) => {
                Common_InputElement_ref(model.LoginVM.email, e);
            }), new Option_1(13, (e_1) => {
                dispatch(new Msg(2, (lvm) => (new Security_LoginViewModel(e_1.currentTarget.value, lvm.password))));
            })]))))])), div(empty(), ofArray([label_1(singleton_1(new Option(3, singleton_1(mkLoginViewId("Password", new Naming_UIElement(1, new Naming_UIElement(0)))))), singleton_1("Password")), div_1(empty(), singleton_1(input(ofArray([new Option_1(1, new IInputType(1)), new Option_1(15, ofArray([mkLoginViewId("Password", new Naming_UIElement(0)), new HTMLAttr(54, "current-password")])), new Option_1(14, (e_2) => {
                Common_InputElement_ref(model.LoginVM.password, e_2);
            }), new Option_1(13, (e_3) => {
                dispatch(new Msg(2, (lvm_1) => (new Security_LoginViewModel(lvm_1.email, e_3.currentTarget.value))));
            })]))))])), div(singleton_1(new Option_2(4)), singleton_1(div_1(empty(), singleton_1(button(ofArray([new Option_3(17, singleton_1(mkLoginViewId("Login", new Naming_UIElement(2)))), new Option_3(0, new Color_IColor(4)), new Option_3(18, (_arg2) => {
                dispatch(new Msg(4));
            })]), singleton_1("Login"))))))], react.createElement("form", {}, ...children_2)), (props_4 = [mkLoginViewId("Forgot password", new Naming_UIElement(2)), new HTMLAttr(94, pageHash(new Page(0, new AuthPage(3))))], react.createElement("a", keyValueList(props_4, 1), "Forgot password"))], react.createElement("div", {
                id: "top-level-div",
            }, ...children_6)));
        }
        else {
            const userInfo = matchValue;
            return singleton((children = [h3(empty())(singleton_1((arg20 = join(", ", map(UserRole_get_describe(), userInfo.roles)), (arg10 = Common_EmailAddress_value_Z39D6D3C1(userInfo.email), toText(printf("Logged in as %s (%s)"))(arg10)(arg20))))), button(ofArray([new Option_3(17, singleton_1(mkLoginViewId("logout", new Naming_UIElement(2)))), new Option_3(19, "is-neutral"), new Option_3(18, (_arg1) => {
                dispatch(new Msg(6));
            })]), singleton_1("Log out"))], react.createElement("div", {}, ...children)));
        }
    })));
}

