import { Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, option_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { cons, ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { p, div, Option } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";
import { Option as Option_1, button as button_2 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";

export class ComponentVisibility extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Visible", "Hidden", "Disabled", "Spinner"];
    }
}

export function ComponentVisibility$reflection() {
    return union_type("Optiscan.SharedUI.ComponentVisibility.Visibility.ComponentVisibility", [], ComponentVisibility, () => [[], [], [["tooltip", option_type(string_type)]], [["tooltip", option_type(string_type)]]]);
}

export function Tooltip_tooltip(text, maybeCustomControlStyle, isVisible, element) {
    let customStyle;
    if (maybeCustomControlStyle == null) {
        customStyle = empty();
    }
    else {
        const customControlStyle = maybeCustomControlStyle;
        customStyle = singleton(new Option(5, singleton(["style", keyValueList(customControlStyle, 1)])));
    }
    if (isVisible) {
        return div(toList(delay(() => append(singleton_1(new Option(4, "tooltip")), delay(() => customStyle)))), ofArray([element, react.createElement("span", {
            className: "tooltip-text",
        }, text)]));
    }
    else {
        return element;
    }
}

export function Button_visibilityControlled(maybeCustomControlStyle, visibility, options, children) {
    let customStyle;
    if (maybeCustomControlStyle == null) {
        customStyle = empty();
    }
    else {
        const customControlStyle = maybeCustomControlStyle;
        customStyle = singleton(new Option(5, singleton(["style", keyValueList(customControlStyle, 1)])));
    }
    if (visibility.tag === 2) {
        if (visibility.fields[0] == null) {
            return p(toList(delay(() => customStyle)), singleton(button_2(cons(new Option_1(16, true), options), children)));
        }
        else {
            const tooltip = visibility.fields[0];
            return Tooltip_tooltip(tooltip, maybeCustomControlStyle, true, button_2(cons(new Option_1(16, true), options), children));
        }
    }
    else if (visibility.tag === 3) {
        if (visibility.fields[0] == null) {
            return p(toList(delay(() => customStyle)), singleton(button_2(cons(new Option_1(13, true), options), children)));
        }
        else {
            const tooltip_1 = visibility.fields[0];
            return Tooltip_tooltip(tooltip_1, maybeCustomControlStyle, true, button_2(cons(new Option_1(13, true), options), children));
        }
    }
    else if (visibility.tag === 0) {
        return p(toList(delay(() => customStyle)), singleton(button_2(options, children)));
    }
    else {
        return Helpers_nothing;
    }
}

