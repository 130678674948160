import { tryParse } from "../fable_modules/fable-library.3.7.12/Guid.js";
import { FSharpRef } from "../fable_modules/fable-library.3.7.12/Types.js";
import { append, map, head, isEmpty, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Msg, Model } from "./DashboardTypes.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { error } from "../Optiscan.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../Pages.js";

export function tryParseGuid(uuid) {
    let matchValue;
    let outArg = "00000000-0000-0000-0000-000000000000";
    matchValue = [tryParse(uuid, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function init() {
    return [new Model(void 0, void 0, empty(), empty(), true, empty()), Cmd_none()];
}

export function update(token, userInfo, msg, model) {
    let matchValue;
    let pattern_matching_result, ex, sites, siteId, authSites, sessions, err, setter, scheduledSession;
    if (msg.tag === 1) {
        pattern_matching_result = 1;
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 7;
            err = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
            sites = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 3;
        siteId = msg.fields[0];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 7;
            err = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
            authSites = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 5;
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 7;
            err = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 6;
            sessions = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 8;
        setter = msg.fields[0];
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 9;
        scheduledSession = msg.fields[0];
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1: {
            return [model, (matchValue = userInfo.userData.siteIds, (!isEmpty(matchValue)) ? Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(token).getSitesForAccount, head(matchValue).accountId, (arg0) => (new Msg(3, arg0)), (arg0_1) => (new Msg(0, arg0_1))) : Cmd_none())];
        }
        case 2: {
            if (!isEmpty(sites)) {
                return [new Model(model.maybePatientId, model.maybeSessionId, sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions), Cmd_OfFunc_result(new Msg(4, head(sites).siteId))];
            }
            else {
                return [model, error("User Account has no sites")];
            }
        }
        case 3: {
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).getAllTargetSitesForSite, siteId, (arg0_2) => (new Msg(5, arg0_2)), (arg0_3) => (new Msg(0, arg0_3)))];
        }
        case 4: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, authSites, model.loadingSessions, model.scheduledSessions), Cmd_OfFunc_result(new Msg(6))];
        }
        case 5: {
            const siteIds = map((s) => s.siteId, append(model.sites, model.authorizedSites));
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(token).getAllScheduledSessionsForSites, siteIds, (arg0_4) => (new Msg(7, arg0_4)), (arg0_5) => (new Msg(0, arg0_5)))];
        }
        case 6: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, false, sessions), Cmd_none()];
        }
        case 7: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, false, model.scheduledSessions), error(ErrorMessage_get_describe()(err))];
        }
        case 8: {
            return [setter(model), Cmd_none()];
        }
        case 9: {
            return [model, Navigation_newUrl(pageHash(new Page(6, scheduledSession.patientId, scheduledSession.sessionId)))];
        }
    }
}

