import { SessionManagementMsg, SessionManagement_update, SessionManagement_SessionManagementModel_token__39A60A33, InactivityConfig_create_ZBA264C0, SessionManagement_init } from "../Session.js";
import { Common_EmailAddress_value_Z39D6D3C1, Common_EmailAddress_create_Z721C83C5, Security_ResetPasswordViewModel, Security_LoginViewModel, Security_AuthErrorMessage_get_describe, Security_EmailChangeViewModel_get_empty, Security_ResetPasswordViewModel_get_empty, Security_RegisterViewModel_get_empty, Security_LoginViewModel_get_empty } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { InactivityModel_get_isActive_, Model_get_inactivity_, InactivityModel_get_loggedOut, InactivityModel_get_warning, Msg, Model, InactivityModel_get_init } from "./AuthTypes.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { success, error } from "../Optiscan.SharedUI/Toast.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { securedAuthApi, publicAuthApi } from "../Api.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, AuthPage, pageHash } from "../Pages.js";

export function init() {
    const patternInput = SessionManagement_init(void 0, InactivityConfig_create_ZBA264C0(10 * 60, 60));
    return [new Model(Security_LoginViewModel_get_empty(), Security_RegisterViewModel_get_empty(), Security_ResetPasswordViewModel_get_empty(), Security_EmailChangeViewModel_get_empty(), "", void 0, patternInput[0], false, InactivityModel_get_init()), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(7)), Cmd_map((arg0) => (new Msg(19, arg0)), patternInput[1])]))];
}

const errorToast = (() => {
    const f1 = Security_AuthErrorMessage_get_describe();
    return (arg) => error(f1(arg));
})();

function maybeWithToken(f) {
    let f2_1;
    const value = Cmd_none();
    f2_1 = ((option_1) => defaultArg(option_1, value));
    return (arg_1) => f2_1(map(f, SessionManagement_SessionManagementModel_token__39A60A33(arg_1)));
}

export function update(msg, model) {
    let l, inputRecord_1;
    if (msg.tag === 0) {
        console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
        return [model, Cmd_none()];
    }
    else if (msg.tag === 19) {
        const patternInput = SessionManagement_update(() => false, msg.fields[0], model.SessionManagement);
        const patternInput_1 = (msg.fields[0].tag === 7) ? [InactivityModel_get_warning(), Cmd_OfFunc_result(new Msg(20, true))] : ((msg.fields[0].tag === 8) ? [InactivityModel_get_loggedOut(), Cmd_OfFunc_result(new Msg(6))] : [model.Inactivity, Cmd_none()]);
        return [new Model(model.LoginVM, model.RegisterVM, model.ResetVM, model.EmailChangeVm, model.ResetRequestEmail, model.UserInfo, patternInput[0], model.HideResetEmail, patternInput_1[0]), Cmd_batch(ofArray([Cmd_map((arg0) => (new Msg(19, arg0)), patternInput[1]), patternInput_1[1]]))];
    }
    else if (msg.tag === 20) {
        return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l = Model_get_inactivity_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), InactivityModel_get_isActive_())(l)))(msg.fields[0])(model), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new Model(model.LoginVM, msg.fields[0](model.RegisterVM), model.ResetVM, model.EmailChangeVm, model.ResetRequestEmail, model.UserInfo, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Model(msg.fields[0](model.LoginVM), model.RegisterVM, model.ResetVM, model.EmailChangeVm, model.ResetRequestEmail, model.UserInfo, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Model(model.LoginVM, model.RegisterVM, msg.fields[0](model.ResetVM), model.EmailChangeVm, model.ResetRequestEmail, model.UserInfo, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Model(new Security_LoginViewModel(model.LoginVM.email, ""), model.RegisterVM, model.ResetVM, model.EmailChangeVm, model.ResetRequestEmail, model.UserInfo, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, publicAuthApi.login, model.LoginVM, (arg0_3) => (new Msg(5, arg0_3)), (arg0_4) => (new Msg(0, arg0_4)))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else if (msg.fields[0].fields[0].tag === 1) {
            return [new Model(model.LoginVM, model.RegisterVM, (inputRecord_1 = model.ResetVM, new Security_ResetPasswordViewModel(model.LoginVM.email, msg.fields[0].fields[0].fields[0], inputRecord_1.newPassword, inputRecord_1.confirmPassword)), model.EmailChangeVm, model.ResetRequestEmail, void 0, model.SessionManagement, true, model.Inactivity), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(19, new SessionManagementMsg(2))), Navigation_newUrl(pageHash(new Page(0, new AuthPage(1, void 0))))]))];
        }
        else {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(19, new SessionManagementMsg(1, msg.fields[0].fields[0].fields[0]))), Cmd_OfFunc_result(new Msg(7))]))];
        }
    }
    else if (msg.tag === 7) {
        return [model, maybeWithToken((t_2) => Cmd_OfAsyncWith_either((x_1) => {
            Cmd_OfAsync_start(x_1);
        }, securedAuthApi(t_2).getUserInfo, void 0, (arg0_6) => (new Msg(8, arg0_6)), (arg0_7) => (new Msg(0, arg0_7))))(model.SessionManagement)];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else {
            return [new Model(model.LoginVM, model.RegisterVM, model.ResetVM, model.EmailChangeVm, model.ResetRequestEmail, msg.fields[0].fields[0], model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_OfFunc_result(new Msg(18))];
        }
    }
    else if (msg.tag === 6) {
        return [new Model(model.LoginVM, model.RegisterVM, model.ResetVM, model.EmailChangeVm, model.ResetRequestEmail, void 0, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_batch(ofArray([maybeWithToken((t_3) => Cmd_OfAsyncWith_either((x_2) => {
            Cmd_OfAsync_start(x_2);
        }, securedAuthApi(t_3).logout, void 0, (arg0_8) => (new Msg(9, arg0_8)), (arg0_9) => (new Msg(0, arg0_9))))(model.SessionManagement), Cmd_OfFunc_result(new Msg(19, new SessionManagementMsg(2))), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            return [model, Cmd_none()];
        }
        else {
            return [new Model(model.LoginVM, model.RegisterVM, model.ResetVM, model.EmailChangeVm, model.ResetRequestEmail, void 0, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_none()];
        }
    }
    else if (msg.tag === 10) {
        return [model, Cmd_OfAsyncWith_either((x_3) => {
            Cmd_OfAsync_start(x_3);
        }, publicAuthApi.register, model.RegisterVM, (arg0_10) => (new Msg(11, arg0_10)), (arg0_11) => (new Msg(0, arg0_11)))];
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else {
            return [model, Cmd_batch(ofArray([success("Success"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
        }
    }
    else if (msg.tag === 12) {
        return [model, Cmd_OfAsyncWith_either((x_4) => {
            Cmd_OfAsync_start(x_4);
        }, publicAuthApi.resetPasswordWithToken, model.ResetVM, (arg0_12) => (new Msg(13, arg0_12)), (arg0_13) => (new Msg(0, arg0_13)))];
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            return [model, errorToast(msg.fields[0].fields[0])];
        }
        else {
            return [model, Cmd_batch(ofArray([success("Success, please log in with your new password"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
        }
    }
    else if (msg.tag === 14) {
        return [new Model(model.LoginVM, model.RegisterVM, model.ResetVM, model.EmailChangeVm, msg.fields[0], model.UserInfo, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_none()];
    }
    else if (msg.tag === 15) {
        const matchValue = Common_EmailAddress_create_Z721C83C5(model.ResetRequestEmail);
        if (matchValue.tag === 1) {
            return [model, error("Invalid e-mail address entered")];
        }
        else {
            return [new Model(model.LoginVM, model.RegisterVM, model.ResetVM, model.EmailChangeVm, "", model.UserInfo, model.SessionManagement, model.HideResetEmail, model.Inactivity), Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, publicAuthApi.requestPasswordResetTokenEmail, Common_EmailAddress_value_Z39D6D3C1(matchValue.fields[0]), (arg0_14) => (new Msg(0, arg0_14))), success("An email has been sent to the specified address"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
        }
    }
    else if (msg.tag === 16) {
        const matchValue_1 = [Common_EmailAddress_create_Z721C83C5(model.EmailChangeVm.currentEmail), Common_EmailAddress_create_Z721C83C5(model.EmailChangeVm.newEmail)];
        const copyOfStruct = matchValue_1[0];
        if (copyOfStruct.tag === 1) {
            return [model, error("Invalid current email address")];
        }
        else {
            const copyOfStruct_1 = matchValue_1[1];
            if (copyOfStruct_1.tag === 1) {
                return [model, error("Invalid new email address")];
            }
            else {
                return [model, Cmd_OfAsyncWith_either((x_6) => {
                    Cmd_OfAsync_start(x_6);
                }, publicAuthApi.emailChangeWithToken, model.EmailChangeVm, (arg0_15) => (new Msg(17, arg0_15)), (arg0_16) => (new Msg(0, arg0_16)))];
            }
        }
    }
    else if (msg.tag === 17) {
        return [model, (msg.fields[0].tag === 1) ? errorToast(msg.fields[0].fields[0]) : Cmd_batch(ofArray([success("Email successfully changed"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
    }
    else if (msg.tag === 21) {
        if (msg.fields[0].tag === 4) {
            return [model, Cmd_OfFunc_result(new Msg(6))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else {
        return [model, Cmd_none()];
    }
}

