import { Result_mapError, AsyncResult_map, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotNullOrEmpty } from "../fable_modules/Webbler.Models.1.2.6/Validation.fs.js";
import { crudListState, crudListInbound, CrudListMsg$1, crudEditUpdate, CrudButton_save, CrudEditMsg$2, CrudButton_cancel, CrudButton_nodeMsg, CrudButton_edit, crudModal, crudDetailState } from "../Optiscan.SharedUI/Crud.js";
import { FormField_onChangeSet, FormField_text, FormField_labeledField } from "../Optiscan.SharedUI/Forms.js";
import { tail, head, isEmpty, cons, singleton as singleton_1, empty as empty_1, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Permission, isAllowed } from "../Optiscan.Models/Security.js";
import { AccountsDetailMsg } from "./AccountsTypes.js";
import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../Pages.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Internal_BranchMsgChild$2, Internal_BranchModelChild$2, Internal_BranchMsgChildAndSibling$3, Internal_BranchModelChildAndSibling$3, Internal_StateTreeRoot$6, Internal_StateTreeModel$1, StateTreeConfig$6, defaultConfig, StateTreeNode$6, StateTreeMsg$4, NavigationMsg$1 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { equals, comparePrimitives, partialApply, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { AccountViewModel_get_empty, AccountViewModel_get_contactPhone_, AccountViewModel_get_contactEmail_, AccountViewModel_get_contactName_, AccountViewModel_get_address_, AccountViewModel_get_number_, AccountViewModel_get_name_ } from "../Optiscan.Models/View/Account.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Common_eraseExceptionToError } from "../Common/Common.js";
import { ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { securedApi } from "../Api.js";
import { Sort_SortableColumn$1, Sort_Compare$1 } from "../Optiscan.SharedUI/Sort.js";
import { Internal_combineChildIndexPathFromKeyPathMap, Internal_combineSiblingIndexPathFromKeyPathMap, NavigationMsg$1_destructiveCast_Z33BEA92B, defaultConfig as defaultConfig_1, StateTree$3_$ctor_4E60E31B } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Cmd_map, Cmd_batch as Cmd_batch_1, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { defaultArg, bind, some, map, toArray } from "../fable_modules/fable-library.3.7.12/Option.js";
import { ofList } from "../fable_modules/fable-library.3.7.12/Map.js";
import * as react from "react";

export function canSaveEditedAccount(account) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Account name cannot be empty", account.name), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Account number cannot be empty", account.number), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Account address cannot be empty", account.address), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Account contact name cannot be empty", account.contactName), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result)))))));
}

export function accountDetailState(currentUser) {
    return new StateTreeNode$6(crudDetailState().Init, (model, dispatch) => crudModal("Details", dispatch, ofArray([FormField_labeledField("Account", "Id", model.id, []), FormField_labeledField("Account", "Name", model.name, []), FormField_labeledField("Account", "Number", model.number, []), FormField_labeledField("Account", "Address", model.address, []), FormField_labeledField("Account", "Contact Name", model.contactName, []), FormField_labeledField("Account", "Contact Email", model.contactEmail, []), FormField_labeledField("Account", "Contact Phone", model.contactPhone, [])]), toList(delay(() => append(isAllowed(new Permission(13))(currentUser.roles) ? singleton(CrudButton_edit(dispatch)) : empty(), delay(() => append(singleton(CrudButton_nodeMsg(new AccountsDetailMsg(0), "View Sites", [new Option(0, new Color_IColor(4))], dispatch)), delay(() => singleton(CrudButton_cancel(dispatch))))))))), (msg, tupledArg, _arg1, model_1) => [model_1, Cmd_batch(ofArray([Navigation_newUrl(pageHash(new Page(3, model_1.id))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(3, "list")))]))]);
}

export function accountEditableState(title, initializeAccount, saveItem, saveSuccessToastMessage) {
    return new StateTreeNode$6((deps) => [initializeAccount(deps), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => crudModal(title, dispatch, toList(delay(() => {
        let f, optic;
        const setter = (arg_1) => {
            dispatch(new StateTreeMsg$4(0, new CrudEditMsg$2(0, arg_1)));
        };
        return append(singleton(FormField_text(model.name, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = AccountViewModel_get_name_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "Account", "Name")([])), delay(() => {
            let f_1, optic_2;
            return append(singleton(FormField_text(model.number, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_2 = AccountViewModel_get_number_(), (value_2) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_2)(value_2))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "Account", "Number")([])), delay(() => {
                let f_2, optic_4;
                return append(singleton(FormField_text(model.address, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f_2 = ((optic_4 = AccountViewModel_get_address_(), (value_4) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_4)(value_4))), (b_2) => ((a_2) => Functions_flip(uncurry(2, f_2), b_2, a_2))))), "Account", "Address")([])), delay(() => {
                    let f_3, optic_6;
                    return append(singleton(FormField_text(model.contactName, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f_3 = ((optic_6 = AccountViewModel_get_contactName_(), (value_6) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_6)(value_6))), (b_3) => ((a_3) => Functions_flip(uncurry(2, f_3), b_3, a_3))))), "Account", "Contact Name")([])), delay(() => {
                        let f_4, optic_8;
                        return append(singleton(FormField_text(model.contactEmail, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f_4 = ((optic_8 = AccountViewModel_get_contactEmail_(), (value_8) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_8)(value_8))), (b_4) => ((a_4) => Functions_flip(uncurry(2, f_4), b_4, a_4))))), "Account", "Contact Email (optional)")([])), delay(() => {
                            let f_5, optic_10;
                            return singleton(FormField_text(model.contactPhone, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f_5 = ((optic_10 = AccountViewModel_get_contactPhone_(), (value_10) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_10)(value_10))), (b_5) => ((a_5) => Functions_flip(uncurry(2, f_5), b_5, a_5))))), "Account", "Contact Phone (optional)")([]));
                        }));
                    }));
                }));
            }));
        }));
    })), ofArray([CrudButton_save((x) => x, canSaveEditedAccount(model), dispatch), CrudButton_cancel(dispatch)])), (msg_2, tupledArg, _arg1, newModel) => crudEditUpdate((x_1) => x_1, partialApply(1, saveItem, [tupledArg[0]]), Common_eraseExceptionToError, saveSuccessToastMessage, ErrorMessage_get_describe(), msg_2, newModel));
}

export const accountEditState = accountEditableState("Edit", (x) => x, uncurry(2, (token) => securedApi(token).editAccount), "Account edited.");

export const accountCreateState = accountEditableState("Create", (_arg1) => AccountViewModel_get_empty(), (token, model) => AsyncResult_map((value) => {
}, securedApi(token).createAccount(model)), "Account created.");

export function stateTree(currentUser) {
    let config_5, inputRecord, current_12, keepCachedChildren_3, child_4, current_3, child, config, maybeAutoMessage, key_2, current_2, config_1, current_1_1, config_2, maybeAutoMessage_1, key_6, current_6, config_4, maybeAutoMessage_2, key_11, current_11, current_15, config_6, maybeAutoMessage_3, key_14, current_14;
    const columns = singleton_1(new Sort_SortableColumn$1("Name", (avm) => avm.name, new Sort_Compare$1(0, (avm1, avm2) => comparePrimitives(avm1.name, avm2.name))));
    return StateTree$3_$ctor_4E60E31B((config_5 = ((inputRecord = defaultConfig(), new StateTreeConfig$6(true, inputRecord.ReInitOnNavigation, new StateTreeMsg$4(0, new CrudListMsg$1(0)), inputRecord.KeepCachedChildren, (msg, arg10$0040, model) => crudListInbound(msg, void 0, model)))), (current_12 = crudListState("Accounts", "Account", columns, (tupledArg) => singleton_2.Delay(() => (isAllowed(new Permission(15))(tupledArg[1].roles) ? singleton_2.Bind(securedApi(tupledArg[0]).getAccounts(), (_arg1) => singleton_2.Return(Result_mapError()(ErrorMessage_get_describe())(_arg1))) : singleton_2.Return(new FSharpResult$2(0, empty_1())))), isAllowed(new Permission(12))(currentUser.roles)), (keepCachedChildren_3 = config_5.KeepCachedChildren, (child_4 = ((current_3 = accountDetailState(currentUser), (child = ((config = defaultConfig_1(), (maybeAutoMessage = config.MaybeSendMessageOnNavigation, (key_2 = "edit", (current_2 = accountEditState, new Internal_StateTreeRoot$6((_arg2, deps, maybeCachedModel, _arg1_1) => {
        const matchValue = [maybeCachedModel, config.ReInitOnNavigation, maybeAutoMessage];
        let pattern_matching_result, model_1, model_1_1, msg_1;
        if (matchValue[0] != null) {
            if (matchValue[1]) {
                pattern_matching_result = 2;
            }
            else if (matchValue[2] != null) {
                pattern_matching_result = 1;
                model_1_1 = matchValue[0];
                msg_1 = matchValue[2];
            }
            else {
                pattern_matching_result = 0;
                model_1 = matchValue[0];
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model_1, Cmd_none()];
            }
            case 1: {
                return [model_1_1, Cmd_OfFunc_result(msg_1)];
            }
            case 2: {
                const patternInput = current_2.Init(deps);
                return [new Internal_StateTreeModel$1(patternInput[0], false), Cmd_batch_1(toList(delay(() => append(singleton(patternInput[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage))))))))];
            }
        }
    }, (_arg3, dispatch, model_2) => current_2.View(model_2.NodeModel, dispatch), (_arg4, msg_3_1, pass, deps_1, model_3) => {
        switch (msg_3_1.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_1.fields[0]}`));
                return [model_3, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3, Cmd_none(), void 0, msg_3_1.fields[0]];
            }
            case 4: {
                return [model_3, Cmd_none(), msg_3_1.fields[0], void 0];
            }
            default: {
                const patternInput_1 = current_2.Update(msg_3_1.fields[0], pass, deps_1, model_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1[0], model_3.Initialized), patternInput_1[1], void 0, void 0];
            }
        }
    }, (msg_4, deps_2, model_4) => {
        const patternInput_2 = config.UpdateInbound(msg_4, deps_2, model_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2[0], model_4.Initialized), patternInput_2[1]];
    }, key_2, ofList(singleton_1([singleton_1(key_2), singleton_1(0)])))))))), (config_1 = defaultConfig_1(), (current_1_1 = ((config_2 = config_1, (maybeAutoMessage_1 = config_2.MaybeSendMessageOnNavigation, (key_6 = "detail", (current_6 = current_3, new Internal_StateTreeRoot$6((_arg2_1, deps_3, maybeCachedModel_1, _arg1_2) => {
        const matchValue_1 = [maybeCachedModel_1, config_2.ReInitOnNavigation, maybeAutoMessage_1];
        let pattern_matching_result_1, model_5, model_1_2, msg_5;
        if (matchValue_1[0] != null) {
            if (matchValue_1[1]) {
                pattern_matching_result_1 = 2;
            }
            else if (matchValue_1[2] != null) {
                pattern_matching_result_1 = 1;
                model_1_2 = matchValue_1[0];
                msg_5 = matchValue_1[2];
            }
            else {
                pattern_matching_result_1 = 0;
                model_5 = matchValue_1[0];
            }
        }
        else {
            pattern_matching_result_1 = 2;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return [model_5, Cmd_none()];
            }
            case 1: {
                return [model_1_2, Cmd_OfFunc_result(msg_5)];
            }
            case 2: {
                const patternInput_3 = current_6.Init(deps_3);
                return [new Internal_StateTreeModel$1(patternInput_3[0], false), Cmd_batch_1(toList(delay(() => append(singleton(patternInput_3[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_1))))))))];
            }
        }
    }, (_arg3_1, dispatch_1, model_2_1) => current_6.View(model_2_1.NodeModel, dispatch_1), (_arg4_1, msg_3_2, pass_1, deps_1_1, model_3_1) => {
        switch (msg_3_2.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_2.fields[0]}`));
                return [model_3_1, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_1.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3_1, Cmd_none(), void 0, msg_3_2.fields[0]];
            }
            case 4: {
                return [model_3_1, Cmd_none(), msg_3_2.fields[0], void 0];
            }
            default: {
                const patternInput_1_1 = current_6.Update(msg_3_2.fields[0], pass_1, deps_1_1, model_3_1.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_1[0], model_3_1.Initialized), patternInput_1_1[1], void 0, void 0];
            }
        }
    }, (msg_4_1, deps_2_1, model_4_1) => {
        const patternInput_2_1 = config_2.UpdateInbound(msg_4_1, deps_2_1, model_4_1.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_1[0], model_4_1.Initialized), patternInput_2_1[1]];
    }, key_6, ofList(singleton_1([singleton_1(key_6), singleton_1(0)])))))))), (sibling) => {
        const keepCachedChildren_1 = config_1.KeepCachedChildren;
        const child_2 = child;
        const current_7 = current_1_1;
        const sibling_1 = sibling;
        return new Internal_StateTreeRoot$6((indexPath, deps_4, maybeCachedModel_2, keepCachedSiblings) => {
            const maybeCachedCurrentModel = bind((cachedModel) => cachedModel.MaybeCurrentModel, maybeCachedModel_2);
            const maybeCachedSiblingModel = bind((cachedModel_1) => cachedModel_1.MaybeSiblingModel, maybeCachedModel_2);
            const maybeCachedChildModel = bind((cachedModel_2) => cachedModel_2.MaybeChildModel, maybeCachedModel_2);
            const matchValue_2 = [indexPath, maybeCachedCurrentModel];
            let pattern_matching_result_2, currentModel_1, xs, x, xs_1;
            if (isEmpty(matchValue_2[0])) {
                pattern_matching_result_2 = 3;
            }
            else if (head(matchValue_2[0]) === 0) {
                if (isEmpty(tail(matchValue_2[0]))) {
                    pattern_matching_result_2 = 0;
                }
                else if (matchValue_2[1] != null) {
                    pattern_matching_result_2 = 1;
                    currentModel_1 = matchValue_2[1];
                    xs = tail(matchValue_2[0]);
                }
                else {
                    pattern_matching_result_2 = 0;
                }
            }
            else {
                pattern_matching_result_2 = 2;
                x = head(matchValue_2[0]);
                xs_1 = tail(matchValue_2[0]);
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    const patternInput_4 = current_7.Init(indexPath, deps_4, maybeCachedCurrentModel, false);
                    return [new Internal_BranchModelChildAndSibling$3(patternInput_4[0], keepCachedSiblings ? maybeCachedSiblingModel : (void 0), keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_2) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_2)), patternInput_4[1])];
                }
                case 1: {
                    const patternInput_1_2 = child_2.Init(xs, currentModel_1.NodeModel, maybeCachedChildModel, keepCachedChildren_1);
                    return [new Internal_BranchModelChildAndSibling$3(currentModel_1, keepCachedSiblings ? maybeCachedSiblingModel : (void 0), patternInput_1_2[0]), Cmd_map((arg0_2_1) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_2_1)), patternInput_1_2[1])];
                }
                case 2: {
                    const patternInput_2_2 = sibling_1.Init(cons(x - 1, xs_1), deps_4, maybeCachedSiblingModel, keepCachedSiblings);
                    return [new Internal_BranchModelChildAndSibling$3(keepCachedSiblings ? maybeCachedCurrentModel : (void 0), patternInput_2_2[0], keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_3) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_3)), patternInput_2_2[1])];
                }
                case 3: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_2, new Internal_BranchModelChildAndSibling$3(void 0, void 0, void 0)), Cmd_none()];
                }
            }
        }, (indexPath_1, dispatch_2, model_6) => {
            if (isEmpty(indexPath_1)) {
                return "Woops, looks like you took a wrong turn...";
            }
            else if (head(indexPath_1) === 0) {
                if (isEmpty(tail(indexPath_1))) {
                    return defaultArg(map(partialApply(1, current_7.View, [indexPath_1, (arg) => {
                        dispatch_2(new Internal_BranchMsgChildAndSibling$3(0, arg));
                    }]), model_6.MaybeCurrentModel), "Loading...");
                }
                else {
                    const children = toList(delay(() => append(config_1.VisibleToChildren ? singleton(defaultArg(map(partialApply(1, current_7.View, [indexPath_1, (arg_1) => {
                        dispatch_2(new Internal_BranchMsgChildAndSibling$3(0, arg_1));
                    }]), model_6.MaybeCurrentModel), "Loading...")) : empty(), delay(() => singleton(defaultArg(map(partialApply(1, child_2.View, [tail(indexPath_1), (arg_2) => {
                        dispatch_2(new Internal_BranchMsgChildAndSibling$3(2, arg_2));
                    }]), model_6.MaybeChildModel), "Loading..."))))));
                    return react.createElement("div", {}, ...children);
                }
            }
            else {
                return defaultArg(map(partialApply(1, sibling_1.View, [cons(head(indexPath_1) - 1, tail(indexPath_1)), (arg_3) => {
                    dispatch_2(new Internal_BranchMsgChildAndSibling$3(1, arg_3));
                }]), model_6.MaybeSiblingModel), "Loading...");
            }
        }, (indexPath_2, branchMsg, pass_2, deps_1_2, model_1_3) => {
            let newNodeModel;
            const matchValue_1_1 = [branchMsg, indexPath_2];
            let pattern_matching_result_3, msg_1_3, xs_5, msg_2_3, xs_6, msg_3_3, x_3, xs_7;
            if (matchValue_1_1[0].tag === 2) {
                if (!isEmpty(matchValue_1_1[1])) {
                    if (head(matchValue_1_1[1]) === 0) {
                        pattern_matching_result_3 = 1;
                        msg_2_3 = matchValue_1_1[0].fields[0];
                        xs_6 = tail(matchValue_1_1[1]);
                    }
                    else {
                        pattern_matching_result_3 = 3;
                    }
                }
                else {
                    pattern_matching_result_3 = 3;
                }
            }
            else if (matchValue_1_1[0].tag === 1) {
                if (!isEmpty(matchValue_1_1[1])) {
                    if (head(matchValue_1_1[1]) > 0) {
                        pattern_matching_result_3 = 2;
                        msg_3_3 = matchValue_1_1[0].fields[0];
                        x_3 = head(matchValue_1_1[1]);
                        xs_7 = tail(matchValue_1_1[1]);
                    }
                    else {
                        pattern_matching_result_3 = 3;
                    }
                }
                else {
                    pattern_matching_result_3 = 3;
                }
            }
            else if (!isEmpty(matchValue_1_1[1])) {
                if (head(matchValue_1_1[1]) === 0) {
                    pattern_matching_result_3 = 0;
                    msg_1_3 = matchValue_1_1[0].fields[0];
                    xs_5 = tail(matchValue_1_1[1]);
                }
                else {
                    pattern_matching_result_3 = 3;
                }
            }
            else {
                pattern_matching_result_3 = 3;
            }
            switch (pattern_matching_result_3) {
                case 0: {
                    const matchValue_2_1 = model_1_3.MaybeCurrentModel;
                    if (matchValue_2_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_3, Cmd_none(), void 0, void 0];
                    }
                    else {
                        const currentModel_2 = matchValue_2_1;
                        const patternInput_3_1 = current_7.Update(indexPath_2, msg_1_3, pass_2, deps_1_2, currentModel_2);
                        const newCurrentModel = patternInput_3_1[0];
                        const maybeOutboundMsg = patternInput_3_1[3];
                        const maybeNavMsg = patternInput_3_1[2];
                        const currentCmd_1 = patternInput_3_1[1];
                        let pattern_matching_result_4;
                        if (msg_1_3.tag === 2) {
                            if (!equals(xs_5, empty_1())) {
                                pattern_matching_result_4 = 0;
                            }
                            else {
                                pattern_matching_result_4 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_4 = 1;
                        }
                        switch (pattern_matching_result_4) {
                            case 0: {
                                const patternInput_4_1 = child_2.Init(xs_5, currentModel_2.NodeModel, model_1_3.MaybeChildModel, false);
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel, model_1_3.MaybeSiblingModel, patternInput_4_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_10) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10)), currentCmd_1), Cmd_map((arg0_11) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_11)), patternInput_4_1[1])])), maybeNavMsg, maybeOutboundMsg];
                            }
                            case 1: {
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel, model_1_3.MaybeSiblingModel, model_1_3.MaybeChildModel), Cmd_map((arg0_13) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_13)), currentCmd_1), maybeNavMsg, maybeOutboundMsg];
                            }
                        }
                    }
                }
                case 1: {
                    const matchValue_3 = [model_1_3.MaybeCurrentModel, model_1_3.MaybeChildModel];
                    let pattern_matching_result_5, childModel_1, currentModel_3;
                    if (matchValue_3[0] != null) {
                        if (matchValue_3[1] != null) {
                            pattern_matching_result_5 = 0;
                            childModel_1 = matchValue_3[1];
                            currentModel_3 = matchValue_3[0];
                        }
                        else {
                            pattern_matching_result_5 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_5 = 1;
                    }
                    switch (pattern_matching_result_5) {
                        case 0: {
                            const patternInput_5 = child_2.Update(xs_6, msg_2_3, pass_2, currentModel_3.NodeModel, childModel_1);
                            const maybeNavMsg_1 = patternInput_5[2];
                            return [new Internal_BranchModelChildAndSibling$3((maybeNavMsg_1 != null) ? ((maybeNavMsg_1.tag === 2) ? ((newNodeModel = maybeNavMsg_1.fields[0], map((model_2_2) => (new Internal_StateTreeModel$1(newNodeModel, model_2_2.Initialized)), model_1_3.MaybeCurrentModel))) : model_1_3.MaybeCurrentModel) : model_1_3.MaybeCurrentModel, model_1_3.MaybeSiblingModel, patternInput_5[0]), Cmd_map((arg0_15) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_15)), patternInput_5[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1), patternInput_5[3]];
                        }
                        case 1: {
                            console.error(some("Failed to update state, parent not initialized"));
                            return [model_1_3, Cmd_none(), void 0, void 0];
                        }
                    }
                }
                case 2: {
                    const matchValue_4 = model_1_3.MaybeSiblingModel;
                    if (matchValue_4 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_3, Cmd_none(), void 0, void 0];
                    }
                    else {
                        const patternInput_6 = sibling_1.Update(cons(x_3 - 1, xs_7), msg_3_3, pass_2, deps_1_2, matchValue_4);
                        return [new Internal_BranchModelChildAndSibling$3(model_1_3.MaybeCurrentModel, patternInput_6[0], model_1_3.MaybeChildModel), Cmd_map((arg0_17) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_17)), patternInput_6[1]), patternInput_6[2], patternInput_6[3]];
                    }
                }
                case 3: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_3, Cmd_none(), void 0, void 0];
                }
            }
        }, (msg_4_2, deps_2_2, model_3_2) => {
            let patternInput_8;
            const matchValue_5 = model_3_2.MaybeCurrentModel;
            if (matchValue_5 == null) {
                patternInput_8 = [model_3_2.MaybeCurrentModel, Cmd_none()];
            }
            else {
                const patternInput_7 = current_7.UpdateInbound(msg_4_2, deps_2_2, matchValue_5);
                patternInput_8 = [patternInput_7[0], patternInput_7[1]];
            }
            let patternInput_10;
            const matchValue_6 = [model_3_2.MaybeChildModel, model_3_2.MaybeCurrentModel];
            let pattern_matching_result_6, childModel_2, currentModel_5;
            if (matchValue_6[0] != null) {
                if (matchValue_6[1] != null) {
                    pattern_matching_result_6 = 0;
                    childModel_2 = matchValue_6[0];
                    currentModel_5 = matchValue_6[1];
                }
                else {
                    pattern_matching_result_6 = 1;
                }
            }
            else {
                pattern_matching_result_6 = 1;
            }
            switch (pattern_matching_result_6) {
                case 0: {
                    const patternInput_9 = child_2.UpdateInbound(msg_4_2, currentModel_5.NodeModel, childModel_2);
                    patternInput_10 = [patternInput_9[0], patternInput_9[1]];
                    break;
                }
                case 1: {
                    patternInput_10 = [model_3_2.MaybeChildModel, Cmd_none()];
                    break;
                }
            }
            let patternInput_12;
            const matchValue_7 = model_3_2.MaybeSiblingModel;
            if (matchValue_7 == null) {
                patternInput_12 = [model_3_2.MaybeSiblingModel, Cmd_none()];
            }
            else {
                const patternInput_11 = sibling_1.UpdateInbound(msg_4_2, deps_2_2, matchValue_7);
                patternInput_12 = [patternInput_11[0], patternInput_11[1]];
            }
            return [new Internal_BranchModelChildAndSibling$3(patternInput_8[0], patternInput_12[0], patternInput_10[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_18) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_18)), patternInput_8[1]), Cmd_map((arg0_19) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_19)), patternInput_10[1]), Cmd_map((arg0_20) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_20)), patternInput_12[1])]))];
        }, current_7.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_1.IndexPathFromKeyPath, Internal_combineChildIndexPathFromKeyPathMap(current_7.LastKey, child_2.IndexPathFromKeyPath, current_7.IndexPathFromKeyPath)));
    })))))((config_4 = defaultConfig_1(), (maybeAutoMessage_2 = config_4.MaybeSendMessageOnNavigation, (key_11 = "create", (current_11 = accountCreateState, new Internal_StateTreeRoot$6((_arg2_2, deps_5, maybeCachedModel_3, _arg1_3) => {
        const matchValue_8 = [maybeCachedModel_3, config_4.ReInitOnNavigation, maybeAutoMessage_2];
        let pattern_matching_result_7, model_7, model_1_4, msg_9;
        if (matchValue_8[0] != null) {
            if (matchValue_8[1]) {
                pattern_matching_result_7 = 2;
            }
            else if (matchValue_8[2] != null) {
                pattern_matching_result_7 = 1;
                model_1_4 = matchValue_8[0];
                msg_9 = matchValue_8[2];
            }
            else {
                pattern_matching_result_7 = 0;
                model_7 = matchValue_8[0];
            }
        }
        else {
            pattern_matching_result_7 = 2;
        }
        switch (pattern_matching_result_7) {
            case 0: {
                return [model_7, Cmd_none()];
            }
            case 1: {
                return [model_1_4, Cmd_OfFunc_result(msg_9)];
            }
            case 2: {
                const patternInput_13 = current_11.Init(deps_5);
                return [new Internal_StateTreeModel$1(patternInput_13[0], false), Cmd_batch_1(toList(delay(() => append(singleton(patternInput_13[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_2))))))))];
            }
        }
    }, (_arg3_2, dispatch_3, model_2_3) => current_11.View(model_2_3.NodeModel, dispatch_3), (_arg4_2, msg_3_4, pass_3, deps_1_3, model_3_3) => {
        switch (msg_3_4.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_4.fields[0]}`));
                return [model_3_3, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_3.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3_3, Cmd_none(), void 0, msg_3_4.fields[0]];
            }
            case 4: {
                return [model_3_3, Cmd_none(), msg_3_4.fields[0], void 0];
            }
            default: {
                const patternInput_1_3 = current_11.Update(msg_3_4.fields[0], pass_3, deps_1_3, model_3_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_3[0], model_3_3.Initialized), patternInput_1_3[1], void 0, void 0];
            }
        }
    }, (msg_4_3, deps_2_3, model_4_2) => {
        const patternInput_2_3 = config_4.UpdateInbound(msg_4_3, deps_2_3, model_4_2.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_3[0], model_4_2.Initialized), patternInput_2_3[1]];
    }, key_11, ofList(singleton_1([singleton_1(key_11), singleton_1(0)])))))))), (current_15 = ((config_6 = config_5, (maybeAutoMessage_3 = config_6.MaybeSendMessageOnNavigation, (key_14 = "list", (current_14 = current_12, new Internal_StateTreeRoot$6((_arg2_3, deps_6, maybeCachedModel_4, _arg1_4) => {
        const matchValue_9 = [maybeCachedModel_4, config_6.ReInitOnNavigation, maybeAutoMessage_3];
        let pattern_matching_result_8, model_8, model_1_5, msg_12;
        if (matchValue_9[0] != null) {
            if (matchValue_9[1]) {
                pattern_matching_result_8 = 2;
            }
            else if (matchValue_9[2] != null) {
                pattern_matching_result_8 = 1;
                model_1_5 = matchValue_9[0];
                msg_12 = matchValue_9[2];
            }
            else {
                pattern_matching_result_8 = 0;
                model_8 = matchValue_9[0];
            }
        }
        else {
            pattern_matching_result_8 = 2;
        }
        switch (pattern_matching_result_8) {
            case 0: {
                return [model_8, Cmd_none()];
            }
            case 1: {
                return [model_1_5, Cmd_OfFunc_result(msg_12)];
            }
            case 2: {
                const patternInput_14 = current_14.Init(deps_6);
                return [new Internal_StateTreeModel$1(patternInput_14[0], false), Cmd_batch_1(toList(delay(() => append(singleton(patternInput_14[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_3))))))))];
            }
        }
    }, (_arg3_3, dispatch_4, model_2_4) => current_14.View(model_2_4.NodeModel, dispatch_4), (_arg4_3, msg_3_5, pass_4, deps_1_4, model_3_4) => {
        switch (msg_3_5.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_5.fields[0]}`));
                return [model_3_4, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_4.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3_4, Cmd_none(), void 0, msg_3_5.fields[0]];
            }
            case 4: {
                return [model_3_4, Cmd_none(), msg_3_5.fields[0], void 0];
            }
            default: {
                const patternInput_1_4 = current_14.Update(msg_3_5.fields[0], pass_4, deps_1_4, model_3_4.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_4[0], model_3_4.Initialized), patternInput_1_4[1], void 0, void 0];
            }
        }
    }, (msg_4_4, deps_2_4, model_4_3) => {
        const patternInput_2_4 = config_6.UpdateInbound(msg_4_4, deps_2_4, model_4_3.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_4[0], model_4_3.Initialized), patternInput_2_4[1]];
    }, key_14, ofList(singleton_1([singleton_1(key_14), singleton_1(0)])))))))), new Internal_StateTreeRoot$6((indexPath_3, deps_7, maybeCachedModel_5, _arg1_5) => {
        const maybeCachedCurrentModel_1 = bind((cachedModel_3) => cachedModel_3.MaybeCurrentModel, maybeCachedModel_5);
        const maybeCachedChildModel_1 = bind((cachedModel_1_1) => cachedModel_1_1.MaybeChildModel, maybeCachedModel_5);
        const matchValue_10 = [indexPath_3, maybeCachedCurrentModel_1];
        let pattern_matching_result_9, currentModel_1_1, xs_8;
        if (!isEmpty(matchValue_10[0])) {
            if (head(matchValue_10[0]) === 0) {
                if (isEmpty(tail(matchValue_10[0]))) {
                    pattern_matching_result_9 = 0;
                }
                else if (matchValue_10[1] != null) {
                    pattern_matching_result_9 = 1;
                    currentModel_1_1 = matchValue_10[1];
                    xs_8 = tail(matchValue_10[0]);
                }
                else {
                    pattern_matching_result_9 = 0;
                }
            }
            else {
                pattern_matching_result_9 = 2;
            }
        }
        else {
            pattern_matching_result_9 = 2;
        }
        switch (pattern_matching_result_9) {
            case 0: {
                const patternInput_15 = current_15.Init(indexPath_3, deps_7, maybeCachedCurrentModel_1, false);
                return [new Internal_BranchModelChild$2(patternInput_15[0], keepCachedChildren_3 ? maybeCachedChildModel_1 : (void 0)), Cmd_map((arg0_21) => (new Internal_BranchMsgChild$2(0, arg0_21)), patternInput_15[1])];
            }
            case 1: {
                const patternInput_1_5 = child_4.Init(xs_8, currentModel_1_1.NodeModel, maybeCachedChildModel_1, keepCachedChildren_3);
                return [new Internal_BranchModelChild$2(currentModel_1_1, patternInput_1_5[0]), Cmd_map((arg0_2_2) => (new Internal_BranchMsgChild$2(1, arg0_2_2)), patternInput_1_5[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_5, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none()];
            }
        }
    }, (indexPath_1_1, dispatch_5, model_9) => {
        let pattern_matching_result_10, xs_1_1;
        if (!isEmpty(indexPath_1_1)) {
            if (head(indexPath_1_1) === 0) {
                if (isEmpty(tail(indexPath_1_1))) {
                    pattern_matching_result_10 = 0;
                }
                else {
                    pattern_matching_result_10 = 1;
                    xs_1_1 = tail(indexPath_1_1);
                }
            }
            else {
                pattern_matching_result_10 = 2;
            }
        }
        else {
            pattern_matching_result_10 = 2;
        }
        switch (pattern_matching_result_10) {
            case 0: {
                return defaultArg(map(partialApply(1, current_15.View, [indexPath_1_1, (arg_4) => {
                    dispatch_5(new Internal_BranchMsgChild$2(0, arg_4));
                }]), model_9.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children_2 = toList(delay(() => append(config_5.VisibleToChildren ? singleton(defaultArg(map(partialApply(1, current_15.View, [indexPath_1_1, (arg_5) => {
                    dispatch_5(new Internal_BranchMsgChild$2(0, arg_5));
                }]), model_9.MaybeCurrentModel), "Loading...")) : empty(), delay(() => singleton(defaultArg(map(partialApply(1, child_4.View, [xs_1_1, (arg_6) => {
                    dispatch_5(new Internal_BranchMsgChild$2(1, arg_6));
                }]), model_9.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children_2);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2_1, branchMsg_1, pass_5, deps_1_5, model_1_6) => {
        let newNodeModel_1;
        const matchValue_1_2 = [branchMsg_1, indexPath_2_1];
        let pattern_matching_result_11, msg_15, xs_2_1, msg_1_6, xs_3_1;
        if (matchValue_1_2[0].tag === 1) {
            if (!isEmpty(matchValue_1_2[1])) {
                if (head(matchValue_1_2[1]) === 0) {
                    pattern_matching_result_11 = 1;
                    msg_1_6 = matchValue_1_2[0].fields[0];
                    xs_3_1 = tail(matchValue_1_2[1]);
                }
                else {
                    pattern_matching_result_11 = 2;
                }
            }
            else {
                pattern_matching_result_11 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_2[1])) {
            if (head(matchValue_1_2[1]) === 0) {
                pattern_matching_result_11 = 0;
                msg_15 = matchValue_1_2[0].fields[0];
                xs_2_1 = tail(matchValue_1_2[1]);
            }
            else {
                pattern_matching_result_11 = 2;
            }
        }
        else {
            pattern_matching_result_11 = 2;
        }
        switch (pattern_matching_result_11) {
            case 0: {
                const matchValue_2_2 = model_1_6.MaybeCurrentModel;
                if (matchValue_2_2 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_6, Cmd_none(), void 0, void 0];
                }
                else {
                    const currentModel_2_1 = matchValue_2_2;
                    const patternInput_2_5 = current_15.Update(indexPath_2_1, msg_15, pass_5, deps_1_5, currentModel_2_1);
                    const newCurrentModel_3 = patternInput_2_5[0];
                    const maybeOutboundMsg_3 = patternInput_2_5[3];
                    const maybeNavMsg_3 = patternInput_2_5[2];
                    const currentCmd_1_1 = patternInput_2_5[1];
                    let pattern_matching_result_12;
                    if (msg_15.tag === 2) {
                        if (!equals(xs_2_1, empty_1())) {
                            pattern_matching_result_12 = 0;
                        }
                        else {
                            pattern_matching_result_12 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_12 = 1;
                    }
                    switch (pattern_matching_result_12) {
                        case 0: {
                            const patternInput_3_2 = child_4.Init(xs_2_1, currentModel_2_1.NodeModel, model_1_6.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, patternInput_3_2[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_1) => (new Internal_BranchMsgChild$2(0, arg0_8_1)), currentCmd_1_1), Cmd_map((arg0_9_1) => (new Internal_BranchMsgChild$2(1, arg0_9_1)), patternInput_3_2[1])])), maybeNavMsg_3, maybeOutboundMsg_3];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, model_1_6.MaybeChildModel), Cmd_map((arg0_11_1) => (new Internal_BranchMsgChild$2(0, arg0_11_1)), currentCmd_1_1), maybeNavMsg_3, maybeOutboundMsg_3];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3_1 = [model_1_6.MaybeCurrentModel, model_1_6.MaybeChildModel];
                let pattern_matching_result_13, childModel_1_1, currentModel_3_1;
                if (matchValue_3_1[0] != null) {
                    if (matchValue_3_1[1] != null) {
                        pattern_matching_result_13 = 0;
                        childModel_1_1 = matchValue_3_1[1];
                        currentModel_3_1 = matchValue_3_1[0];
                    }
                    else {
                        pattern_matching_result_13 = 1;
                    }
                }
                else {
                    pattern_matching_result_13 = 1;
                }
                switch (pattern_matching_result_13) {
                    case 0: {
                        const patternInput_4_2 = child_4.Update(xs_3_1, msg_1_6, pass_5, currentModel_3_1.NodeModel, childModel_1_1);
                        const maybeNavMsg_1_1 = patternInput_4_2[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1_1 != null) ? ((maybeNavMsg_1_1.tag === 2) ? ((newNodeModel_1 = maybeNavMsg_1_1.fields[0], map((model_2_5) => (new Internal_StateTreeModel$1(newNodeModel_1, model_2_5.Initialized)), model_1_6.MaybeCurrentModel))) : model_1_6.MaybeCurrentModel) : model_1_6.MaybeCurrentModel, patternInput_4_2[0]), Cmd_map((arg0_13_1) => (new Internal_BranchMsgChild$2(1, arg0_13_1)), patternInput_4_2[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_1), patternInput_4_2[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_6, Cmd_none(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_6, Cmd_none(), void 0, void 0];
            }
        }
    }, (msg_2_6, deps_2_5, model_3_5) => {
        let patternInput_6_1;
        const matchValue_4_1 = model_3_5.MaybeCurrentModel;
        if (matchValue_4_1 == null) {
            patternInput_6_1 = [model_3_5.MaybeCurrentModel, Cmd_none()];
        }
        else {
            const patternInput_5_1 = current_15.UpdateInbound(msg_2_6, deps_2_5, matchValue_4_1);
            patternInput_6_1 = [patternInput_5_1[0], patternInput_5_1[1]];
        }
        let patternInput_8_1;
        const matchValue_5_1 = [model_3_5.MaybeChildModel, model_3_5.MaybeCurrentModel];
        let pattern_matching_result_14, childModel_2_1, currentModel_5_1;
        if (matchValue_5_1[0] != null) {
            if (matchValue_5_1[1] != null) {
                pattern_matching_result_14 = 0;
                childModel_2_1 = matchValue_5_1[0];
                currentModel_5_1 = matchValue_5_1[1];
            }
            else {
                pattern_matching_result_14 = 1;
            }
        }
        else {
            pattern_matching_result_14 = 1;
        }
        switch (pattern_matching_result_14) {
            case 0: {
                const patternInput_7_1 = child_4.UpdateInbound(msg_2_6, currentModel_5_1.NodeModel, childModel_2_1);
                patternInput_8_1 = [patternInput_7_1[0], patternInput_7_1[1]];
                break;
            }
            case 1: {
                patternInput_8_1 = [model_3_5.MaybeChildModel, Cmd_none()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6_1[0], patternInput_8_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14_1) => (new Internal_BranchMsgChild$2(0, arg0_14_1)), patternInput_6_1[1]), Cmd_map((arg0_15_1) => (new Internal_BranchMsgChild$2(1, arg0_15_1)), patternInput_8_1[1])]))];
    }, current_15.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_15.LastKey, child_4.IndexPathFromKeyPath, current_15.IndexPathFromKeyPath))))))));
}

