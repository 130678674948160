import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, int32_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class SessionAnnotationViewModel extends Record {
    constructor(sessionId, annotationId, annotationData, locationIndex, siteIndex, sequenceIndex, note, annotationName) {
        super();
        this.sessionId = sessionId;
        this.annotationId = annotationId;
        this.annotationData = annotationData;
        this.locationIndex = (locationIndex | 0);
        this.siteIndex = (siteIndex | 0);
        this.sequenceIndex = (sequenceIndex | 0);
        this.note = note;
        this.annotationName = annotationName;
    }
}

export function SessionAnnotationViewModel$reflection() {
    return record_type("Optiscan.Models.View.SessionAnnotationViewModel.SessionAnnotationViewModel", [], SessionAnnotationViewModel, () => [["sessionId", class_type("System.Guid")], ["annotationId", string_type], ["annotationData", string_type], ["locationIndex", int32_type], ["siteIndex", int32_type], ["sequenceIndex", int32_type], ["note", option_type(string_type)], ["annotationName", option_type(string_type)]]);
}

export function SessionAnnotationViewModel_get_note_() {
    return [(m) => m.note, (v) => ((m_1) => (new SessionAnnotationViewModel(m_1.sessionId, m_1.annotationId, m_1.annotationData, m_1.locationIndex, m_1.siteIndex, m_1.sequenceIndex, v, m_1.annotationName)))];
}

export function SessionAnnotationViewModel_get_annotationName_() {
    return [(m) => m.annotationName, (v) => ((m_1) => (new SessionAnnotationViewModel(m_1.sessionId, m_1.annotationId, m_1.annotationData, m_1.locationIndex, m_1.siteIndex, m_1.sequenceIndex, m_1.note, v)))];
}

