import { Common_passwordRequirementTracker, Common_mkField, Common_mkId } from "../Common/Common.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { ofArray, cons, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { input as input_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Msg } from "./AuthTypes.js";
import { Option as Option_1, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_2, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Modifier_IModifier, Screen, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import * as react from "react";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_3, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Security_ResetPasswordViewModel } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { ComponentVisibility, Button_visibilityControlled } from "../Optiscan.SharedUI/ComponentVisibility.js";
import { isNullOrEmpty } from "../fable_modules/fable-library.3.7.12/String.js";

export function mkResetViewId(label, element) {
    return Common_mkId("Reset", label, element);
}

export const mkField = (input) => ((inputProps) => ((label) => ((value) => ((onChange) => Common_mkField("Reset", input, inputProps, label, value, onChange)))));

export function viewRequestResetEmail(email, dispatch) {
    let children;
    return container(empty(), singleton((children = [h3(empty())(singleton("Reset Password")), mkField((options) => input_1(cons(new Option(1, new IInputType(0)), options)))(singleton(new HTMLAttr(54, "email")))("Email")(email)((arg) => {
        dispatch(new Msg(14, arg));
    }), div(singleton(new Option_1(4)), singleton(div_1(empty(), singleton(button(ofArray([new Option_2(17, singleton(mkResetViewId("request submit", new Naming_UIElement(2)))), new Option_2(0, new Color_IColor(4)), new Option_2(18, (_arg1) => {
        dispatch(new Msg(15));
    })]), singleton("Submit"))))))], react.createElement("div", {}, ...children))));
}

export function view(model, dispatch) {
    let children_4, children_2;
    const patternInput = Common_passwordRequirementTracker(model.ResetVM.newPassword);
    const passwordTracker = patternInput[0];
    return container(empty(), singleton((children_4 = [h3(empty())(singleton("Reset Password")), columns(empty(), ofArray([column(empty(), singleton((children_2 = toList(delay(() => append((!model.HideResetEmail) ? singleton_1(mkField((options) => input_1(cons(new Option(1, new IInputType(0)), options)))(singleton(new HTMLAttr(54, "email")))("Email")(model.ResetVM.email)((v) => {
        dispatch(new Msg(3, (rvm) => (new Security_ResetPasswordViewModel(v, rvm.resetToken, rvm.newPassword, rvm.confirmPassword))));
    })) : empty_1(), delay(() => append(singleton_1(mkField((options_2) => input_1(cons(new Option(1, new IInputType(1)), options_2)))(singleton(new HTMLAttr(54, "new-password")))("Password")(model.ResetVM.newPassword)((v_1) => {
        dispatch(new Msg(3, (rvm_1) => (new Security_ResetPasswordViewModel(rvm_1.email, rvm_1.resetToken, v_1, rvm_1.confirmPassword))));
    })), delay(() => append(singleton_1(react.createElement("div", {
        className: "is-hidden-desktop",
    }, passwordTracker)), delay(() => append(singleton_1(mkField((options_4) => input_1(cons(new Option(1, new IInputType(1)), options_4)))(singleton(new HTMLAttr(54, "new-password")))("Confirm Password")(model.ResetVM.confirmPassword)((v_2) => {
        dispatch(new Msg(3, (rvm_2) => (new Security_ResetPasswordViewModel(rvm_2.email, rvm_2.resetToken, rvm_2.newPassword, v_2))));
    })), delay(() => singleton_1(div(ofArray([new Option_1(4), new Option_1(11, singleton(["style", {
        justifyContent: "flex-end",
    }]))]), singleton(div_1(empty(), singleton(Button_visibilityControlled(void 0, (isNullOrEmpty(model.ResetVM.newPassword) ? true : (model.ResetVM.confirmPassword !== model.ResetVM.newPassword)) ? (new ComponentVisibility(2, "Passwords entered do not match")) : ((!patternInput[1]) ? (new ComponentVisibility(2, "Password does not meet the requirements")) : (new ComponentVisibility(0))), ofArray([new Option_2(17, singleton(mkResetViewId("Reset Submit", new Naming_UIElement(2)))), new Option_2(0, new Color_IColor(4)), new Option_2(18, (_arg1) => {
        dispatch(new Msg(12));
    })]), singleton("Submit"))))))))))))))))), react.createElement("form", {}, ...children_2)))), column(ofArray([new Option_3(3, singleton(["style", {
        paddingTop: "7rem",
    }])), new Option_3(4, singleton(new Modifier_IModifier(24, new Screen(5), true)))]), singleton(passwordTracker))]))], react.createElement("div", {}, ...children_4))));
}

