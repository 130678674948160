import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, bool_type, option_type, class_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class DeviceViewModel extends Record {
    constructor(name, siteId, accountId, siteName, accountName, region, online) {
        super();
        this.name = name;
        this.siteId = siteId;
        this.accountId = accountId;
        this.siteName = siteName;
        this.accountName = accountName;
        this.region = region;
        this.online = online;
    }
}

export function DeviceViewModel$reflection() {
    return record_type("Optiscan.Models.View.Device.DeviceViewModel", [], DeviceViewModel, () => [["name", string_type], ["siteId", option_type(class_type("System.Guid"))], ["accountId", option_type(class_type("System.Guid"))], ["siteName", option_type(string_type)], ["accountName", option_type(string_type)], ["region", option_type(string_type)], ["online", bool_type]]);
}

export function DeviceViewModel_get_name_() {
    return [(m) => m.name, (v) => ((m_1) => (new DeviceViewModel(v, m_1.siteId, m_1.accountId, m_1.siteName, m_1.accountName, m_1.region, m_1.online)))];
}

export function DeviceViewModel_get_siteId_() {
    return [(m) => m.siteId, (v) => ((m_1) => (new DeviceViewModel(m_1.name, v, m_1.accountId, m_1.siteName, m_1.accountName, m_1.region, m_1.online)))];
}

export function DeviceViewModel_get_accountId_() {
    return [(m) => m.accountId, (v) => ((m_1) => (new DeviceViewModel(m_1.name, m_1.siteId, v, m_1.siteName, m_1.accountName, m_1.region, m_1.online)))];
}

export function DeviceViewModel_get_online_() {
    return [(m) => m.online, (v) => ((m_1) => (new DeviceViewModel(m_1.name, m_1.siteId, m_1.accountId, m_1.siteName, m_1.accountName, m_1.region, v)))];
}

