import { partialApply, uncurry, safeHash, equals, comparePrimitives } from "../fable_modules/fable-library.3.7.12/Util.js";
import { ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemListSortable, ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_SortInfo$1, Sort_SortDirection, Sort_SortableColumn$1_toSortableColumnView_45D2C9EC, Sort_SortableColumn$1, Sort_Compare$1 } from "../Optiscan.SharedUI/Sort.js";
import { tail, head, isEmpty, cons, sortWith, length, filter, tryHead, item as item_2, empty, contains, map, singleton, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single, FormField_labeledField } from "../Optiscan.SharedUI/Forms.js";
import * as react from "react";
import { defaultSearchComponent, buttonTriggerSearchComponent } from "../Optiscan.SharedUI/Search.js";
import { ListModel_get_crudList_, ListMsg, ListModel, SearchModel, DetailModel, ConfirmationMsg, SearchAndAddMsg } from "./AuthorizedSitesTypes.js";
import { Internal_BranchMsgChild$2, Internal_BranchModelChild$2, Internal_BranchMsgChildAndSibling$3, Internal_BranchModelChildAndSibling$3, Internal_StateTreeRoot$6, Internal_StateTreeModel$1, StateTreeConfig$6, defaultConfig, StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Common_GenericOption, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option as Option_1, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { success, error as error_4 } from "../Optiscan.SharedUI/Toast.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { Common_eraseExceptionToAuthError } from "../Common/Common.js";
import { Permission, isAllowed, UserRole } from "../Optiscan.Models/Security.js";
import { bind, some, toArray, defaultArg, map as map_1 } from "../fable_modules/fable-library.3.7.12/Option.js";
import { allColumnSearchModifier, CrudListModel$1_get_SearchInfo_, CrudListModel$1_get_SortInfo_, CrudListModel$1_get_ListModifications_, CrudListModel$1_get_MaybeSelected_, CrudListModel$1_get_Items_, CrudButton_navigateToChild, CrudListModel$1, CrudListModification, crudModal } from "../Optiscan.SharedUI/Crud.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.12/String.js";
import { content, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Option as Option_2, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_3, div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { SelectPropsMulti$1 } from "../Optiscan.SharedUI/bindings/ReactSelectBind.js";
import { AccountViewModel_get_name_ } from "../Optiscan.Models/View/Account.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5, Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { SiteViewModel_get_name_ } from "../Optiscan.Models/View/Site.js";
import { Internal_combineChildIndexPathFromKeyPathMap, Internal_combineSiblingIndexPathFromKeyPathMap, NavigationMsg$1_destructiveCast_Z33BEA92B, defaultConfig as defaultConfig_1, StateTree$3_$ctor_4E60E31B } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Cmd_map, Cmd_batch as Cmd_batch_1, Cmd_none as Cmd_none_1 } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.12/Map.js";

export const columns = ofArray([new Sort_SortableColumn$1("Name", (svm) => svm.name, new Sort_Compare$1(0, (svm1, svm2) => comparePrimitives(svm1.name, svm2.name))), new Sort_SortableColumn$1("Number", (svm_1) => svm_1.number, new Sort_Compare$1(0, (svm1_1, svm2_1) => comparePrimitives(svm1_1.number, svm2_1.number))), new Sort_SortableColumn$1("Address", (svm_2) => svm_2.address, new Sort_Compare$1(0, (svm1_2, svm2_2) => comparePrimitives(svm1_2.address, svm2_2.address)))]);

export function detailView(model) {
    const children = [FormField_labeledField("Site", "Id", model.siteId, []), FormField_labeledField("Site", "Account Id", model.accountId, []), FormField_labeledField("Site", "Name", model.name, []), FormField_labeledField("Site", "Number", model.number, []), FormField_labeledField("Site", "Address", model.address, [])];
    return react.createElement("div", {}, ...children);
}

export function searchResultView(model, dispatch) {
    let children_18, children_16, children_12, children_10, children_14;
    const children_20 = [buttonTriggerSearchComponent("Authorized Sites", model.searchString, void 0, "Site Search", (arg_1) => {
        dispatch(new StateTreeMsg$4(0, new SearchAndAddMsg(0, arg_1)));
    }, () => {
        dispatch(new StateTreeMsg$4(0, new SearchAndAddMsg(1, void 0)));
    }), (children_18 = [(children_16 = [table(ofArray([new TableOption(6, singleton(new HTMLAttr(99, "AuthorizedSitesTable"))), new TableOption(1), new TableOption(2), new TableOption(4)]), ofArray([(children_12 = [(children_10 = toList(delay(() => append(map((column) => react.createElement("td", {}, column.header), columns), delay(() => singleton_1(react.createElement("td", {})))))), react.createElement("tr", {}, ...children_10))], react.createElement("thead", {
        id: "AuthorizedSitesTableHeader",
    }, ...children_12)), (children_14 = map((item) => {
        const children_4 = toList(delay(() => append(map((_arg1) => {
            const children = [_arg1.mkCellContent(item)];
            return react.createElement("td", {}, ...children);
        }, columns), delay(() => {
            let children_2;
            return singleton_1((children_2 = toList(delay(() => singleton_1(button(ofArray([new Option(0, new Color_IColor(6)), new Option(16, contains(item, model.authorizedSites, {
                Equals: equals,
                GetHashCode: safeHash,
            }) ? true : model.addingSite), new Option(18, (_arg2) => {
                dispatch(new StateTreeMsg$4(0, new SearchAndAddMsg(3, item.siteId)));
            })]), singleton("Add"))))), react.createElement("td", {}, ...children_2)));
        }))));
        return react.createElement("tr", {}, ...children_4);
    }, model.searchResults), react.createElement("tbody", {
        id: "AuthorizedSitesTableBody",
    }, ...children_14))]))], react.createElement("div", {}, ...children_16))], react.createElement("div", {
        className: "table-container",
    }, ...children_18))];
    return react.createElement("div", {}, ...children_20);
}

export function confirmationDialogState(currentUser) {
    return new StateTreeNode$6((deps) => [deps, Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => modal(singleton(new Option_1(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Remove Authorized Site")), Card_body(empty(), singleton(`Please click confirm to remove ${model.item.name} as an authorized site`)), Card_foot(empty(), ofArray([button(ofArray([new Option(0, new Color_IColor(8)), new Option(18, (_arg2) => {
        dispatch(new StateTreeMsg$4(0, new ConfirmationMsg(0, model.item.siteId)));
    })]), singleton("Confirm")), button(ofArray([new Option(19, "is-secondary"), new Option(18, (_arg3) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })]), singleton("Cancel"))]))]))])), (msg_2, tupledArg, _arg4, model_1) => {
        if (msg_2.tag === 1) {
            return (msg_2.fields[0].tag === 1) ? [model_1, error_4(ErrorMessage_get_describe()(msg_2.fields[0].fields[0]))] : [model_1, Cmd_batch(ofArray([success("Authorized site successfully removed"), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(0, singleton("list"))))]))];
        }
        else {
            const matchValue = model_1.targetSite;
            if (matchValue == null) {
                return [model_1, error_4("Unable to remove authorized site. Please reload the page and try again")];
            }
            else {
                const siteId = matchValue;
                return [model_1, Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, securedApi(tupledArg[0]).deleteAuthorizedSite, [siteId, msg_2.fields[0]], (arg) => (new StateTreeMsg$4(0, new ConfirmationMsg(1, arg))), (arg_1) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_1))))];
            }
        }
    });
}

export function authSiteDetailState(currentUser) {
    return new StateTreeNode$6((deps) => {
        const matchValue = deps.crudList.MaybeSelected;
        return (matchValue == null) ? [new DetailModel(item_2(0, deps.crudList.Items), void 0), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))] : [new DetailModel(matchValue, contains(new UserRole(2), currentUser.roles, {
            Equals: equals,
            GetHashCode: safeHash,
        }) ? map_1((site) => site.siteId, tryHead(currentUser.userData.siteIds)) : map_1((site_1) => site_1.siteId, deps.selectedSite)), Cmd_OfFunc_result(new StateTreeMsg$4(2))];
    }, (model, dispatch) => crudModal("Details", dispatch, singleton(detailView(model.item)), ofArray([button(ofArray([new Option(0, new Color_IColor(8)), new Option(18, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(4, "Confirmation")));
    })]), singleton("Remove Authorized Site")), button(ofArray([new Option(18, (_arg2) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    }), new Option(19, "is-secondary")]), singleton("Cancel"))])), (msg_4, tupledArg, deps_1, model_1) => [model_1, Cmd_none()]);
}

export function searchAndAddState(currentUser) {
    return new StateTreeNode$6((deps) => [new SearchModel(void 0, empty(), deps.crudList.Items, contains(new UserRole(2), currentUser.roles, {
        Equals: equals,
        GetHashCode: safeHash,
    }) ? map_1((site) => site.siteId, tryHead(currentUser.userData.siteIds)) : map_1((site_1) => site_1.siteId, deps.selectedSite), false), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => modal(singleton(new Option_1(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Create Site Association")), Card_body(empty(), singleton(searchResultView(model, dispatch))), Card_foot(empty(), singleton(button(singleton(new Option(18, (_arg2) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })), singleton("Close"))))]))])), uncurry(4, (msg_2) => ((tupledArg) => {
        const token = tupledArg[0];
        return (_arg3) => ((model_1) => {
            let pattern_matching_result, s_2, sites, authSiteId, err, err_1, siteId_1, sites_1;
            if (msg_2.tag === 1) {
                pattern_matching_result = 1;
            }
            else if (msg_2.tag === 2) {
                if (msg_2.fields[0].tag === 1) {
                    pattern_matching_result = 6;
                    err_1 = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 2;
                    sites = msg_2.fields[0].fields[0];
                }
            }
            else if (msg_2.tag === 3) {
                pattern_matching_result = 3;
                authSiteId = msg_2.fields[0];
            }
            else if (msg_2.tag === 4) {
                if (msg_2.fields[0].tag === 1) {
                    pattern_matching_result = 5;
                    err = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 4;
                }
            }
            else if (msg_2.tag === 6) {
                if (msg_2.fields[0].tag === 0) {
                    pattern_matching_result = 8;
                    sites_1 = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 6;
                    err_1 = msg_2.fields[0].fields[0];
                }
            }
            else if (msg_2.tag === 5) {
                pattern_matching_result = 7;
                siteId_1 = msg_2.fields[0];
            }
            else {
                pattern_matching_result = 0;
                s_2 = msg_2.fields[0];
            }
            switch (pattern_matching_result) {
                case 0: {
                    return [new SearchModel(s_2, model_1.searchResults, model_1.authorizedSites, model_1.targetSite, model_1.addingSite), Cmd_none()];
                }
                case 1: {
                    const matchValue = model_1.searchString;
                    let pattern_matching_result_1, search_1;
                    if (matchValue != null) {
                        if (!isNullOrWhiteSpace(matchValue)) {
                            pattern_matching_result_1 = 0;
                            search_1 = matchValue;
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return [model_1, Cmd_OfAsyncWith_either((x_1) => {
                                Cmd_OfAsync_start(x_1);
                            }, securedApi(token).searchForSitesByName, search_1, (arg) => (new StateTreeMsg$4(0, new SearchAndAddMsg(2, arg))), (arg_1) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_1))))];
                        }
                        case 1: {
                            return [new SearchModel(model_1.searchString, empty(), model_1.authorizedSites, model_1.targetSite, model_1.addingSite), Cmd_none()];
                        }
                    }
                }
                case 2: {
                    const targetSite = defaultArg(model_1.targetSite, "00000000-0000-0000-0000-000000000000");
                    return [new SearchModel(model_1.searchString, filter((s_3) => (targetSite !== s_3.siteId), sites), model_1.authorizedSites, model_1.targetSite, model_1.addingSite), Cmd_none()];
                }
                case 3: {
                    const matchValue_1 = model_1.targetSite;
                    if (matchValue_1 == null) {
                        return [model_1, error_4("Unable to add authorized site. Please reload the page and try again")];
                    }
                    else {
                        const siteId = matchValue_1;
                        return [new SearchModel(model_1.searchString, model_1.searchResults, model_1.authorizedSites, model_1.targetSite, true), Cmd_OfAsyncWith_either((x_2) => {
                            Cmd_OfAsync_start(x_2);
                        }, securedApi(token).createAuthorizedSite, [siteId, authSiteId], (arg_3) => (new StateTreeMsg$4(0, new SearchAndAddMsg(4, arg_3))), (arg_4) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_4))))];
                    }
                }
                case 4: {
                    return [new SearchModel(model_1.searchString, model_1.searchResults, model_1.authorizedSites, model_1.targetSite, false), success("Authorized Site Successfuly added!")];
                }
                case 5: {
                    return [new SearchModel(model_1.searchString, model_1.searchResults, model_1.authorizedSites, model_1.targetSite, false), error_4(ErrorMessage_get_describe()(err))];
                }
                case 6: {
                    return [model_1, error_4(ErrorMessage_get_describe()(err_1))];
                }
                case 7: {
                    return [model_1, Cmd_OfAsyncWith_either((x_3) => {
                        Cmd_OfAsync_start(x_3);
                    }, securedApi(token).getAllAuthorizedSitesForSite, siteId_1, (arg_6) => (new StateTreeMsg$4(0, new SearchAndAddMsg(6, arg_6))), (arg_7) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_7))))];
                }
                case 8: {
                    return [new SearchModel(model_1.searchString, model_1.searchResults, sites_1, model_1.targetSite, model_1.addingSite), Cmd_none()];
                }
            }
        });
    })));
}

export function crudListState(listTitle, itemName, columns_1, getItems, canCreate, currentUser) {
    if (length(columns_1) > 0) {
        return new StateTreeNode$6((_arg1) => {
            let comparer;
            const initialSortInfo = new Sort_SortInfo$1(Sort_SortableColumn$1_toSortableColumnView_45D2C9EC(item_2(0, columns_1)), new Sort_SortDirection(0));
            return [new ListModel(new CrudListModel$1(empty(), void 0, initialSortInfo, void 0, singleton(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer = Sort_SortInfo$1_getSortComparison_576B9F7E(initialSortInfo), (list) => sortWith(uncurry(2, comparer), list))))), empty(), empty(), void 0, void 0, false, false), Cmd_OfFunc_result(new StateTreeMsg$4(0, new ListMsg(0)))];
        }, (model, dispatch) => {
            let children;
            return card(singleton(new Common_GenericOption(0, "table-card")), ofArray([header(empty(), singleton(Header_title(empty(), singleton(listTitle)))), content(empty(), ofArray([div(singleton(new Option_2(0)), toList(delay(() => {
                let optic;
                return append((isAllowed(new Permission(10))(currentUser.roles) && (!equals(currentUser.roles, empty()))) ? singleton_1(div_1(singleton(new Option_3(5, singleton(["style", {
                    width: "25%",
                    marginRight: "1rem",
                }]))), singleton(FormField_ReactSelect_single(model.accounts, [new SelectPropsMulti$1(11, model.loadingAccounts)], (optic = AccountViewModel_get_name_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target)), model.selectedAccount, new FormField_ReactSelect_CallbackSingle$1(0, (arg_1) => {
                    dispatch(new StateTreeMsg$4(0, new ListMsg(10, arg_1)));
                }), "Account", true, "AuthorizedSites", toList(delay(() => (model.loadingAccounts ? singleton_1(new Option_2(10, "is-loading")) : empty_1()))))))) : empty_1(), delay(() => {
                    let optic_2;
                    return append((isAllowed(new Permission(16))(currentUser.roles) && (!equals(currentUser.roles, empty()))) ? singleton_1(div_1(singleton(new Option_3(5, singleton(["style", {
                        width: "25%",
                        marginRight: "1rem",
                    }]))), singleton(FormField_ReactSelect_single(model.sites, [new SelectPropsMulti$1(11, model.loadingSites ? true : (equals(model.accounts, empty()) && contains(new UserRole(0), currentUser.roles, {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })))], (optic_2 = SiteViewModel_get_name_(), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target_2)), model.selectedSite, new FormField_ReactSelect_CallbackSingle$1(0, (arg_3) => {
                        dispatch(new StateTreeMsg$4(0, new ListMsg(11, arg_3)));
                    }), "Site", true, "AuthorizedSites", toList(delay(() => (model.loadingSites ? singleton_1(new Option_2(10, "is-loading")) : empty_1()))))))) : empty_1(), delay(() => singleton_1(div(ofArray([new Option_2(2), new Option_2(11, singleton(["style", {
                        paddingTop: "23px",
                        marginLeft: "auto",
                    }]))]), ofArray([div_1(singleton(new Option_3(5, singleton(["style", {
                        marginRight: "1rem",
                    }]))), singleton(defaultSearchComponent(listTitle, model.crudList.SearchInfo, (arg_5) => {
                        dispatch(new StateTreeMsg$4(0, new ListMsg(4, arg_5)));
                    }, (arg_7) => {
                        dispatch(new StateTreeMsg$4(0, new ListMsg(5, arg_7)));
                    }))), div_1(empty(), toList(delay(() => {
                        const createDisabled = (!contains(new UserRole(2), currentUser.roles, {
                            Equals: equals,
                            GetHashCode: safeHash,
                        })) && (model.selectedSite == null);
                        return canCreate ? singleton_1(CrudButton_navigateToChild("create", `Create ${itemName}`, [new Option(0, new Color_IColor(4)), new Option(16, createDisabled)], dispatch)) : empty_1();
                    })))])))));
                }));
            }))), (children = [Sort_itemListSortable(itemName, columns_1)((item) => ((_arg2) => {
                dispatch(new StateTreeMsg$4(0, new ListMsg(2, item)));
            }))(ListDisplayModification_ListDisplayModification$2_applyListModifications(model.crudList.ListModifications, model.crudList.Items))((_arg3) => empty_1())(model.crudList.SortInfo)((sortInfo) => ((_arg4) => {
                dispatch(new StateTreeMsg$4(0, new ListMsg(3, sortInfo)));
            }))], react.createElement("div", {
                className: "table-container",
            }, ...children))]))]));
        }, uncurry(4, (msg_2) => ((tupledArg) => {
            const token = tupledArg[0];
            const userInfo = tupledArg[1];
            return (_arg5) => ((model_1) => {
                let account, account_1, site, site_1, l, l_2, l_6, l_4, l_8, l_12, l_10;
                let pattern_matching_result, aid, sites_1, accounts_1, siteId, err, maybeAccount, maybeSite, items, err_1, item_1, sortInfo_1, searchInfo, searchInfo_1;
                if (msg_2.tag === 9) {
                    if (msg_2.fields[0].tag === 1) {
                        pattern_matching_result = 4;
                        err = msg_2.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 1;
                        sites_1 = msg_2.fields[0].fields[0];
                    }
                }
                else if (msg_2.tag === 7) {
                    if (msg_2.fields[0].tag === 1) {
                        pattern_matching_result = 4;
                        err = msg_2.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 2;
                        accounts_1 = msg_2.fields[0].fields[0];
                    }
                }
                else if (msg_2.tag === 6) {
                    pattern_matching_result = 3;
                    siteId = msg_2.fields[0];
                }
                else if (msg_2.tag === 10) {
                    pattern_matching_result = 5;
                    maybeAccount = msg_2.fields[0];
                }
                else if (msg_2.tag === 11) {
                    pattern_matching_result = 6;
                    maybeSite = msg_2.fields[0];
                }
                else if (msg_2.tag === 0) {
                    pattern_matching_result = 7;
                }
                else if (msg_2.tag === 1) {
                    if (msg_2.fields[0].tag === 1) {
                        pattern_matching_result = 9;
                        err_1 = msg_2.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 8;
                        items = msg_2.fields[0].fields[0];
                    }
                }
                else if (msg_2.tag === 2) {
                    pattern_matching_result = 10;
                    item_1 = msg_2.fields[0];
                }
                else if (msg_2.tag === 3) {
                    pattern_matching_result = 11;
                    sortInfo_1 = msg_2.fields[0];
                }
                else if (msg_2.tag === 4) {
                    pattern_matching_result = 12;
                    searchInfo = msg_2.fields[0];
                }
                else if (msg_2.tag === 5) {
                    pattern_matching_result = 13;
                    searchInfo_1 = msg_2.fields[0];
                }
                else {
                    pattern_matching_result = 0;
                    aid = msg_2.fields[0];
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return [new ListModel(model_1.crudList, model_1.accounts, model_1.sites, model_1.selectedAccount, model_1.selectedSite, model_1.loadingAccounts, true), Cmd_OfAsyncWith_either((x_2) => {
                            Cmd_OfAsync_start(x_2);
                        }, securedApi(token).getSitesForAccount, aid, (arg_8) => (new StateTreeMsg$4(0, new ListMsg(9, arg_8))), (arg_9) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_9))))];
                    }
                    case 1: {
                        return [new ListModel(model_1.crudList, model_1.accounts, sites_1, model_1.selectedAccount, model_1.selectedSite, model_1.loadingAccounts, false), Cmd_none()];
                    }
                    case 2: {
                        return [new ListModel(model_1.crudList, accounts_1, model_1.sites, model_1.selectedAccount, model_1.selectedSite, false, model_1.loadingSites), Cmd_none()];
                    }
                    case 3: {
                        return [model_1, Cmd_OfAsyncWith_either((x_3) => {
                            Cmd_OfAsync_start(x_3);
                        }, securedApi(token).getAllAuthorizedSitesForSite, siteId, (arg_11) => (new StateTreeMsg$4(0, new ListMsg(1, arg_11))), (arg_12) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_12))))];
                    }
                    case 4: {
                        return [model_1, error_4(ErrorMessage_get_describe()(err))];
                    }
                    case 5: {
                        return [new ListModel(model_1.crudList, model_1.accounts, empty(), maybeAccount, void 0, model_1.loadingAccounts, model_1.loadingSites), (maybeAccount != null) ? (((account = maybeAccount, !equals(model_1.selectedAccount, maybeAccount))) ? ((account_1 = maybeAccount, Cmd_OfFunc_result(new StateTreeMsg$4(0, new ListMsg(8, account_1.id))))) : ((maybeAccount == null) ? Cmd_none() : Cmd_none())) : ((maybeAccount == null) ? Cmd_none() : Cmd_none())];
                    }
                    case 6: {
                        return [new ListModel(model_1.crudList, model_1.accounts, model_1.sites, model_1.selectedAccount, maybeSite, model_1.loadingAccounts, model_1.loadingSites), (maybeSite != null) ? (((site = maybeSite, !equals(model_1.selectedSite, maybeSite))) ? ((site_1 = maybeSite, Cmd_OfAsyncWith_either((x_4) => {
                            Cmd_OfAsync_start(x_4);
                        }, securedApi(token).getAllAuthorizedSitesForSite, site_1.siteId, (arg_14) => (new StateTreeMsg$4(0, new ListMsg(1, arg_14))), (arg_15) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_15)))))) : ((maybeSite == null) ? Cmd_none() : Cmd_none())) : ((maybeSite == null) ? Cmd_none() : Cmd_none())];
                    }
                    case 7: {
                        if (contains(new UserRole(0), userInfo.roles, {
                            Equals: equals,
                            GetHashCode: safeHash,
                        })) {
                            return [new ListModel(model_1.crudList, model_1.accounts, model_1.sites, model_1.selectedAccount, model_1.selectedSite, true, model_1.loadingSites), Cmd_OfAsyncWith_either((x_6) => {
                                Cmd_OfAsync_start(x_6);
                            }, securedApi(token).getAccounts, void 0, (arg_17) => (new StateTreeMsg$4(0, new ListMsg(7, arg_17))), (arg_18) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_18))))];
                        }
                        else if (contains(new UserRole(1), userInfo.roles, {
                            Equals: equals,
                            GetHashCode: safeHash,
                        })) {
                            const matchValue = tryHead(userInfo.userData.accountIds);
                            return (matchValue == null) ? [model_1, Cmd_none()] : [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new ListMsg(8, matchValue)))];
                        }
                        else {
                            return [model_1, Cmd_OfAsyncWith_either((x_8) => {
                                Cmd_OfAsync_start(x_8);
                            }, getItems, [token, userInfo], (arg_20) => (new StateTreeMsg$4(0, new ListMsg(1, arg_20))), (arg_21) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_21))))];
                        }
                    }
                    case 8: {
                        return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l = ListModel_get_crudList_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CrudListModel$1_get_Items_())(l)))(items)(model_1), Cmd_OfFunc_result(new StateTreeMsg$4(2))];
                    }
                    case 9: {
                        return [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(2)), error_4(ErrorMessage_get_describe()(err_1))]))];
                    }
                    case 10: {
                        return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l_2 = ListModel_get_crudList_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CrudListModel$1_get_MaybeSelected_())(l_2)))(item_1)(model_1), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "detail")))];
                    }
                    case 11: {
                        const listModifications = toList(delay(() => append(filter((modification) => (!equals(modification.Source, new CrudListModification(0))), model_1.crudList.ListModifications), delay(() => {
                            let comparer_1;
                            return singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer_1 = Sort_SortInfo$1_getSortComparison_576B9F7E(sortInfo_1), (list_3) => sortWith(uncurry(2, comparer_1), list_3))));
                        }))));
                        return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l_6 = ListModel_get_crudList_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CrudListModel$1_get_ListModifications_())(l_6)))(listModifications)(Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l_4 = ListModel_get_crudList_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CrudListModel$1_get_SortInfo_())(l_4)))(sortInfo_1)(model_1)), Cmd_none()];
                    }
                    case 12: {
                        return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l_8 = ListModel_get_crudList_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CrudListModel$1_get_SearchInfo_())(l_8)))(searchInfo)(model_1), Cmd_none()];
                    }
                    case 13: {
                        const listModifications_1 = toList(delay(() => append(filter((modification_1) => (!equals(modification_1.Source, new CrudListModification(1))), model_1.crudList.ListModifications), delay(() => singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(1), 0, (crudItems) => allColumnSearchModifier(model_1.crudList.SearchInfo, columns_1, crudItems)))))));
                        return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l_12 = ListModel_get_crudList_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CrudListModel$1_get_ListModifications_())(l_12)))(listModifications_1)(Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l_10 = ListModel_get_crudList_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), CrudListModel$1_get_SearchInfo_())(l_10)))(searchInfo_1)(model_1)), Cmd_none()];
                    }
                }
            });
        })));
    }
    else {
        throw (new Error("Tried to create a crud list with no columns"));
    }
}

export function stateTree(currentUser) {
    let config_6, inputRecord, current_12, keepCachedChildren_3, child_4, current_3, child, current, config, maybeAutoMessage, key_2, current_2, config_1, current_1_1, config_2, maybeAutoMessage_1, key_6, current_6, config_5, inputRecord_1, maybeAutoMessage_2, key_10, current_11, current_15, config_7, maybeAutoMessage_3, key_13, current_14;
    return StateTree$3_$ctor_4E60E31B((config_6 = ((inputRecord = defaultConfig(), new StateTreeConfig$6(inputRecord.VisibleToChildren, inputRecord.ReInitOnNavigation, new StateTreeMsg$4(0, new ListMsg(0)), inputRecord.KeepCachedChildren, (msg, _arg1, model) => {
        const matchValue_1 = model.selectedSite;
        let pattern_matching_result, site_1;
        if (matchValue_1 != null) {
            if (msg.fields[0] === matchValue_1.siteId) {
                pattern_matching_result = 0;
                site_1 = matchValue_1;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model, Cmd_OfFunc_result(new StateTreeMsg$4(0, new ListMsg(6, site_1.siteId)))];
            }
            case 1: {
                return [model, Cmd_none()];
            }
        }
    }))), (current_12 = crudListState("Authorized Sites", "Authorized Site", columns, (tupledArg) => {
        const currentUser_1 = tupledArg[1];
        return singleton_2.Delay(() => {
            const matchValue = tryHead(currentUser_1.userData.siteIds);
            if (matchValue == null) {
                return singleton_2.Return(new FSharpResult$2(1, new ErrorMessage(8)));
            }
            else {
                const assignedSite = matchValue;
                return isAllowed(new Permission(22))(currentUser_1.roles) ? singleton_2.ReturnFrom(securedApi(tupledArg[0]).getAllAuthorizedSitesForSite(assignedSite.siteId)) : singleton_2.Return(new FSharpResult$2(0, empty()));
            }
        });
    }, isAllowed(new Permission(29))(currentUser.roles), currentUser), (keepCachedChildren_3 = config_6.KeepCachedChildren, (child_4 = ((current_3 = authSiteDetailState(currentUser), (child = ((current = confirmationDialogState(currentUser), (config = defaultConfig_1(), (maybeAutoMessage = config.MaybeSendMessageOnNavigation, (key_2 = "Confirmation", (current_2 = current, new Internal_StateTreeRoot$6((_arg2_1, deps, maybeCachedModel, _arg1_1) => {
        const matchValue_3 = [maybeCachedModel, config.ReInitOnNavigation, maybeAutoMessage];
        let pattern_matching_result_1, model_2, model_1_1, msg_6;
        if (matchValue_3[0] != null) {
            if (matchValue_3[1]) {
                pattern_matching_result_1 = 2;
            }
            else if (matchValue_3[2] != null) {
                pattern_matching_result_1 = 1;
                model_1_1 = matchValue_3[0];
                msg_6 = matchValue_3[2];
            }
            else {
                pattern_matching_result_1 = 0;
                model_2 = matchValue_3[0];
            }
        }
        else {
            pattern_matching_result_1 = 2;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return [model_2, Cmd_none_1()];
            }
            case 1: {
                return [model_1_1, Cmd_OfFunc_result(msg_6)];
            }
            case 2: {
                const patternInput = current_2.Init(deps);
                return [new Internal_StateTreeModel$1(patternInput[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput[1]), delay(() => ofArray(toArray(map_1(Cmd_OfFunc_result, maybeAutoMessage))))))))];
            }
        }
    }, (_arg3, dispatch, model_2_1) => current_2.View(model_2_1.NodeModel, dispatch), (_arg4, msg_3_1, pass, deps_1, model_3) => {
        switch (msg_3_1.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_1.fields[0]}`));
                return [model_3, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3, Cmd_none_1(), void 0, some(msg_3_1.fields[0])];
            }
            case 4: {
                return [model_3, Cmd_none_1(), msg_3_1.fields[0], void 0];
            }
            default: {
                const patternInput_1 = current_2.Update(msg_3_1.fields[0], pass, deps_1, model_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1[0], model_3.Initialized), patternInput_1[1], void 0, void 0];
            }
        }
    }, (msg_4_1, deps_2, model_4) => {
        const patternInput_2 = config.UpdateInbound(msg_4_1, deps_2, model_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2[0], model_4.Initialized), patternInput_2[1]];
    }, key_2, ofList(singleton([singleton(key_2), singleton(0)]))))))))), (config_1 = defaultConfig_1(), (current_1_1 = ((config_2 = config_1, (maybeAutoMessage_1 = config_2.MaybeSendMessageOnNavigation, (key_6 = "detail", (current_6 = current_3, new Internal_StateTreeRoot$6((_arg2_2, deps_3, maybeCachedModel_1, _arg1_2) => {
        const matchValue_4 = [maybeCachedModel_1, config_2.ReInitOnNavigation, maybeAutoMessage_1];
        let pattern_matching_result_2, model_5, model_1_2, msg_9;
        if (matchValue_4[0] != null) {
            if (matchValue_4[1]) {
                pattern_matching_result_2 = 2;
            }
            else if (matchValue_4[2] != null) {
                pattern_matching_result_2 = 1;
                model_1_2 = matchValue_4[0];
                msg_9 = matchValue_4[2];
            }
            else {
                pattern_matching_result_2 = 0;
                model_5 = matchValue_4[0];
            }
        }
        else {
            pattern_matching_result_2 = 2;
        }
        switch (pattern_matching_result_2) {
            case 0: {
                return [model_5, Cmd_none_1()];
            }
            case 1: {
                return [model_1_2, Cmd_OfFunc_result(msg_9)];
            }
            case 2: {
                const patternInput_3 = current_6.Init(deps_3);
                return [new Internal_StateTreeModel$1(patternInput_3[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_3[1]), delay(() => ofArray(toArray(map_1(Cmd_OfFunc_result, maybeAutoMessage_1))))))))];
            }
        }
    }, (_arg3_1, dispatch_1, model_2_2) => current_6.View(model_2_2.NodeModel, dispatch_1), (_arg4_1, msg_3_2, pass_1, deps_1_1, model_3_1) => {
        switch (msg_3_2.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_2.fields[0]}`));
                return [model_3_1, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_1.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_1, Cmd_none_1(), void 0, some(msg_3_2.fields[0])];
            }
            case 4: {
                return [model_3_1, Cmd_none_1(), msg_3_2.fields[0], void 0];
            }
            default: {
                const patternInput_1_1 = current_6.Update(msg_3_2.fields[0], pass_1, deps_1_1, model_3_1.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_1[0], model_3_1.Initialized), patternInput_1_1[1], void 0, void 0];
            }
        }
    }, (msg_4_2, deps_2_1, model_4_1) => {
        const patternInput_2_1 = config_2.UpdateInbound(msg_4_2, deps_2_1, model_4_1.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_1[0], model_4_1.Initialized), patternInput_2_1[1]];
    }, key_6, ofList(singleton([singleton(key_6), singleton(0)])))))))), (sibling) => {
        const keepCachedChildren_1 = config_1.KeepCachedChildren;
        const child_2 = child;
        const current_7 = current_1_1;
        const sibling_1 = sibling;
        return new Internal_StateTreeRoot$6((indexPath, deps_4, maybeCachedModel_2, keepCachedSiblings) => {
            const maybeCachedCurrentModel = bind((cachedModel) => cachedModel.MaybeCurrentModel, maybeCachedModel_2);
            const maybeCachedSiblingModel = bind((cachedModel_1) => cachedModel_1.MaybeSiblingModel, maybeCachedModel_2);
            const maybeCachedChildModel = bind((cachedModel_2) => cachedModel_2.MaybeChildModel, maybeCachedModel_2);
            const matchValue_5 = [indexPath, maybeCachedCurrentModel];
            let pattern_matching_result_3, currentModel_1, xs, x, xs_1;
            if (isEmpty(matchValue_5[0])) {
                pattern_matching_result_3 = 3;
            }
            else if (head(matchValue_5[0]) === 0) {
                if (isEmpty(tail(matchValue_5[0]))) {
                    pattern_matching_result_3 = 0;
                }
                else if (matchValue_5[1] != null) {
                    pattern_matching_result_3 = 1;
                    currentModel_1 = matchValue_5[1];
                    xs = tail(matchValue_5[0]);
                }
                else {
                    pattern_matching_result_3 = 0;
                }
            }
            else {
                pattern_matching_result_3 = 2;
                x = head(matchValue_5[0]);
                xs_1 = tail(matchValue_5[0]);
            }
            switch (pattern_matching_result_3) {
                case 0: {
                    const patternInput_4 = current_7.Init(indexPath, deps_4, maybeCachedCurrentModel, false);
                    return [new Internal_BranchModelChildAndSibling$3(patternInput_4[0], keepCachedSiblings ? maybeCachedSiblingModel : (void 0), keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_4) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_4)), patternInput_4[1])];
                }
                case 1: {
                    const patternInput_1_2 = child_2.Init(xs, currentModel_1.NodeModel, maybeCachedChildModel, keepCachedChildren_1);
                    return [new Internal_BranchModelChildAndSibling$3(currentModel_1, keepCachedSiblings ? maybeCachedSiblingModel : (void 0), patternInput_1_2[0]), Cmd_map((arg0_2_1) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_2_1)), patternInput_1_2[1])];
                }
                case 2: {
                    const patternInput_2_2 = sibling_1.Init(cons(x - 1, xs_1), deps_4, maybeCachedSiblingModel, keepCachedSiblings);
                    return [new Internal_BranchModelChildAndSibling$3(keepCachedSiblings ? maybeCachedCurrentModel : (void 0), patternInput_2_2[0], keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_3_1) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_3_1)), patternInput_2_2[1])];
                }
                case 3: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_2, new Internal_BranchModelChildAndSibling$3(void 0, void 0, void 0)), Cmd_none_1()];
                }
            }
        }, (indexPath_1, dispatch_2, model_6) => {
            if (isEmpty(indexPath_1)) {
                return "Woops, looks like you took a wrong turn...";
            }
            else if (head(indexPath_1) === 0) {
                if (isEmpty(tail(indexPath_1))) {
                    return defaultArg(map_1(partialApply(1, current_7.View, [indexPath_1, (arg) => {
                        dispatch_2(new Internal_BranchMsgChildAndSibling$3(0, arg));
                    }]), model_6.MaybeCurrentModel), "Loading...");
                }
                else {
                    const children = toList(delay(() => append(config_1.VisibleToChildren ? singleton_1(defaultArg(map_1(partialApply(1, current_7.View, [indexPath_1, (arg_1) => {
                        dispatch_2(new Internal_BranchMsgChildAndSibling$3(0, arg_1));
                    }]), model_6.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map_1(partialApply(1, child_2.View, [tail(indexPath_1), (arg_2) => {
                        dispatch_2(new Internal_BranchMsgChildAndSibling$3(2, arg_2));
                    }]), model_6.MaybeChildModel), "Loading..."))))));
                    return react.createElement("div", {}, ...children);
                }
            }
            else {
                return defaultArg(map_1(partialApply(1, sibling_1.View, [cons(head(indexPath_1) - 1, tail(indexPath_1)), (arg_3) => {
                    dispatch_2(new Internal_BranchMsgChildAndSibling$3(1, arg_3));
                }]), model_6.MaybeSiblingModel), "Loading...");
            }
        }, (indexPath_2, branchMsg, pass_2, deps_1_2, model_1_3) => {
            let newNodeModel;
            const matchValue_1_1 = [branchMsg, indexPath_2];
            let pattern_matching_result_4, msg_1_3, xs_5, msg_2_3, xs_6, msg_3_3, x_3, xs_7;
            if (matchValue_1_1[0].tag === 2) {
                if (!isEmpty(matchValue_1_1[1])) {
                    if (head(matchValue_1_1[1]) === 0) {
                        pattern_matching_result_4 = 1;
                        msg_2_3 = matchValue_1_1[0].fields[0];
                        xs_6 = tail(matchValue_1_1[1]);
                    }
                    else {
                        pattern_matching_result_4 = 3;
                    }
                }
                else {
                    pattern_matching_result_4 = 3;
                }
            }
            else if (matchValue_1_1[0].tag === 1) {
                if (!isEmpty(matchValue_1_1[1])) {
                    if (head(matchValue_1_1[1]) > 0) {
                        pattern_matching_result_4 = 2;
                        msg_3_3 = matchValue_1_1[0].fields[0];
                        x_3 = head(matchValue_1_1[1]);
                        xs_7 = tail(matchValue_1_1[1]);
                    }
                    else {
                        pattern_matching_result_4 = 3;
                    }
                }
                else {
                    pattern_matching_result_4 = 3;
                }
            }
            else if (!isEmpty(matchValue_1_1[1])) {
                if (head(matchValue_1_1[1]) === 0) {
                    pattern_matching_result_4 = 0;
                    msg_1_3 = matchValue_1_1[0].fields[0];
                    xs_5 = tail(matchValue_1_1[1]);
                }
                else {
                    pattern_matching_result_4 = 3;
                }
            }
            else {
                pattern_matching_result_4 = 3;
            }
            switch (pattern_matching_result_4) {
                case 0: {
                    const matchValue_2_1 = model_1_3.MaybeCurrentModel;
                    if (matchValue_2_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_3, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const currentModel_2 = matchValue_2_1;
                        const patternInput_3_1 = current_7.Update(indexPath_2, msg_1_3, pass_2, deps_1_2, currentModel_2);
                        const newCurrentModel = patternInput_3_1[0];
                        const maybeOutboundMsg = patternInput_3_1[3];
                        const maybeNavMsg = patternInput_3_1[2];
                        const currentCmd_1 = patternInput_3_1[1];
                        let pattern_matching_result_5;
                        if (msg_1_3.tag === 2) {
                            if (!equals(xs_5, empty())) {
                                pattern_matching_result_5 = 0;
                            }
                            else {
                                pattern_matching_result_5 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_5 = 1;
                        }
                        switch (pattern_matching_result_5) {
                            case 0: {
                                const patternInput_4_1 = child_2.Init(xs_5, currentModel_2.NodeModel, model_1_3.MaybeChildModel, false);
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel, model_1_3.MaybeSiblingModel, patternInput_4_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_10) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10)), currentCmd_1), Cmd_map((arg0_11) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_11)), patternInput_4_1[1])])), maybeNavMsg, maybeOutboundMsg];
                            }
                            case 1: {
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel, model_1_3.MaybeSiblingModel, model_1_3.MaybeChildModel), Cmd_map((arg0_13) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_13)), currentCmd_1), maybeNavMsg, maybeOutboundMsg];
                            }
                        }
                    }
                }
                case 1: {
                    const matchValue_3_1 = [model_1_3.MaybeCurrentModel, model_1_3.MaybeChildModel];
                    let pattern_matching_result_6, childModel_1, currentModel_3;
                    if (matchValue_3_1[0] != null) {
                        if (matchValue_3_1[1] != null) {
                            pattern_matching_result_6 = 0;
                            childModel_1 = matchValue_3_1[1];
                            currentModel_3 = matchValue_3_1[0];
                        }
                        else {
                            pattern_matching_result_6 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_6 = 1;
                    }
                    switch (pattern_matching_result_6) {
                        case 0: {
                            const patternInput_5 = child_2.Update(xs_6, msg_2_3, pass_2, currentModel_3.NodeModel, childModel_1);
                            const maybeNavMsg_1 = patternInput_5[2];
                            return [new Internal_BranchModelChildAndSibling$3((maybeNavMsg_1 != null) ? ((maybeNavMsg_1.tag === 2) ? ((newNodeModel = maybeNavMsg_1.fields[0], map_1((model_2_3) => (new Internal_StateTreeModel$1(newNodeModel, model_2_3.Initialized)), model_1_3.MaybeCurrentModel))) : model_1_3.MaybeCurrentModel) : model_1_3.MaybeCurrentModel, model_1_3.MaybeSiblingModel, patternInput_5[0]), Cmd_map((arg0_15) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_15)), patternInput_5[1]), map_1(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1), patternInput_5[3]];
                        }
                        case 1: {
                            console.error(some("Failed to update state, parent not initialized"));
                            return [model_1_3, Cmd_none_1(), void 0, void 0];
                        }
                    }
                }
                case 2: {
                    const matchValue_4_1 = model_1_3.MaybeSiblingModel;
                    if (matchValue_4_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_3, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_6 = sibling_1.Update(cons(x_3 - 1, xs_7), msg_3_3, pass_2, deps_1_2, matchValue_4_1);
                        return [new Internal_BranchModelChildAndSibling$3(model_1_3.MaybeCurrentModel, patternInput_6[0], model_1_3.MaybeChildModel), Cmd_map((arg0_17) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_17)), patternInput_6[1]), patternInput_6[2], patternInput_6[3]];
                    }
                }
                case 3: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_3, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_4_3, deps_2_2, model_3_2) => {
            let patternInput_8;
            const matchValue_5_1 = model_3_2.MaybeCurrentModel;
            if (matchValue_5_1 == null) {
                patternInput_8 = [model_3_2.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_7 = current_7.UpdateInbound(msg_4_3, deps_2_2, matchValue_5_1);
                patternInput_8 = [patternInput_7[0], patternInput_7[1]];
            }
            let patternInput_10;
            const matchValue_6 = [model_3_2.MaybeChildModel, model_3_2.MaybeCurrentModel];
            let pattern_matching_result_7, childModel_2, currentModel_5;
            if (matchValue_6[0] != null) {
                if (matchValue_6[1] != null) {
                    pattern_matching_result_7 = 0;
                    childModel_2 = matchValue_6[0];
                    currentModel_5 = matchValue_6[1];
                }
                else {
                    pattern_matching_result_7 = 1;
                }
            }
            else {
                pattern_matching_result_7 = 1;
            }
            switch (pattern_matching_result_7) {
                case 0: {
                    const patternInput_9 = child_2.UpdateInbound(msg_4_3, currentModel_5.NodeModel, childModel_2);
                    patternInput_10 = [patternInput_9[0], patternInput_9[1]];
                    break;
                }
                case 1: {
                    patternInput_10 = [model_3_2.MaybeChildModel, Cmd_none_1()];
                    break;
                }
            }
            let patternInput_12;
            const matchValue_7 = model_3_2.MaybeSiblingModel;
            if (matchValue_7 == null) {
                patternInput_12 = [model_3_2.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_11 = sibling_1.UpdateInbound(msg_4_3, deps_2_2, matchValue_7);
                patternInput_12 = [patternInput_11[0], patternInput_11[1]];
            }
            return [new Internal_BranchModelChildAndSibling$3(patternInput_8[0], patternInput_12[0], patternInput_10[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_18) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_18)), patternInput_8[1]), Cmd_map((arg0_19) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_19)), patternInput_10[1]), Cmd_map((arg0_20) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_20)), patternInput_12[1])]))];
        }, current_7.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_1.IndexPathFromKeyPath, Internal_combineChildIndexPathFromKeyPathMap(current_7.LastKey, child_2.IndexPathFromKeyPath, current_7.IndexPathFromKeyPath)));
    })))))((config_5 = ((inputRecord_1 = defaultConfig(), new StateTreeConfig$6(inputRecord_1.VisibleToChildren, inputRecord_1.ReInitOnNavigation, inputRecord_1.MaybeSendMessageOnNavigation, inputRecord_1.KeepCachedChildren, (msg_3, _arg2, model_1) => {
        const matchValue_2 = model_1.targetSite;
        let pattern_matching_result_8, targetSiteId_1;
        if (matchValue_2 != null) {
            if (matchValue_2 === msg_3.fields[0]) {
                pattern_matching_result_8 = 0;
                targetSiteId_1 = matchValue_2;
            }
            else {
                pattern_matching_result_8 = 1;
            }
        }
        else {
            pattern_matching_result_8 = 1;
        }
        switch (pattern_matching_result_8) {
            case 0: {
                return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new SearchAndAddMsg(5, targetSiteId_1)))];
            }
            case 1: {
                return [model_1, Cmd_none()];
            }
        }
    }))), (maybeAutoMessage_2 = config_5.MaybeSendMessageOnNavigation, (key_10 = "create", (current_11 = searchAndAddState(currentUser), new Internal_StateTreeRoot$6((_arg2_3, deps_5, maybeCachedModel_3, _arg1_3) => {
        const matchValue_8 = [maybeCachedModel_3, config_5.ReInitOnNavigation, maybeAutoMessage_2];
        let pattern_matching_result_9, model_7, model_1_4, msg_13;
        if (matchValue_8[0] != null) {
            if (matchValue_8[1]) {
                pattern_matching_result_9 = 2;
            }
            else if (matchValue_8[2] != null) {
                pattern_matching_result_9 = 1;
                model_1_4 = matchValue_8[0];
                msg_13 = matchValue_8[2];
            }
            else {
                pattern_matching_result_9 = 0;
                model_7 = matchValue_8[0];
            }
        }
        else {
            pattern_matching_result_9 = 2;
        }
        switch (pattern_matching_result_9) {
            case 0: {
                return [model_7, Cmd_none_1()];
            }
            case 1: {
                return [model_1_4, Cmd_OfFunc_result(msg_13)];
            }
            case 2: {
                const patternInput_13 = current_11.Init(deps_5);
                return [new Internal_StateTreeModel$1(patternInput_13[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_13[1]), delay(() => ofArray(toArray(map_1(Cmd_OfFunc_result, maybeAutoMessage_2))))))))];
            }
        }
    }, (_arg3_2, dispatch_3, model_2_4) => current_11.View(model_2_4.NodeModel, dispatch_3), (_arg4_2, msg_3_4, pass_3, deps_1_3, model_3_3) => {
        switch (msg_3_4.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_4.fields[0]}`));
                return [model_3_3, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_3.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_3, Cmd_none_1(), void 0, some(msg_3_4.fields[0])];
            }
            case 4: {
                return [model_3_3, Cmd_none_1(), msg_3_4.fields[0], void 0];
            }
            default: {
                const patternInput_1_3 = current_11.Update(msg_3_4.fields[0], pass_3, deps_1_3, model_3_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_3[0], model_3_3.Initialized), patternInput_1_3[1], void 0, void 0];
            }
        }
    }, (msg_4_4, deps_2_3, model_4_2) => {
        const patternInput_2_3 = config_5.UpdateInbound(msg_4_4, deps_2_3, model_4_2.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_3[0], model_4_2.Initialized), patternInput_2_3[1]];
    }, key_10, ofList(singleton([singleton(key_10), singleton(0)])))))))), (current_15 = ((config_7 = config_6, (maybeAutoMessage_3 = config_7.MaybeSendMessageOnNavigation, (key_13 = "list", (current_14 = current_12, new Internal_StateTreeRoot$6((_arg2_4, deps_6, maybeCachedModel_4, _arg1_4) => {
        const matchValue_9 = [maybeCachedModel_4, config_7.ReInitOnNavigation, maybeAutoMessage_3];
        let pattern_matching_result_10, model_8, model_1_5, msg_16;
        if (matchValue_9[0] != null) {
            if (matchValue_9[1]) {
                pattern_matching_result_10 = 2;
            }
            else if (matchValue_9[2] != null) {
                pattern_matching_result_10 = 1;
                model_1_5 = matchValue_9[0];
                msg_16 = matchValue_9[2];
            }
            else {
                pattern_matching_result_10 = 0;
                model_8 = matchValue_9[0];
            }
        }
        else {
            pattern_matching_result_10 = 2;
        }
        switch (pattern_matching_result_10) {
            case 0: {
                return [model_8, Cmd_none_1()];
            }
            case 1: {
                return [model_1_5, Cmd_OfFunc_result(msg_16)];
            }
            case 2: {
                const patternInput_14 = current_14.Init(deps_6);
                return [new Internal_StateTreeModel$1(patternInput_14[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_14[1]), delay(() => ofArray(toArray(map_1(Cmd_OfFunc_result, maybeAutoMessage_3))))))))];
            }
        }
    }, (_arg3_3, dispatch_4, model_2_5) => current_14.View(model_2_5.NodeModel, dispatch_4), (_arg4_3, msg_3_5, pass_4, deps_1_4, model_3_4) => {
        switch (msg_3_5.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_5.fields[0]}`));
                return [model_3_4, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_4.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_4, Cmd_none_1(), void 0, some(msg_3_5.fields[0])];
            }
            case 4: {
                return [model_3_4, Cmd_none_1(), msg_3_5.fields[0], void 0];
            }
            default: {
                const patternInput_1_4 = current_14.Update(msg_3_5.fields[0], pass_4, deps_1_4, model_3_4.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_4[0], model_3_4.Initialized), patternInput_1_4[1], void 0, void 0];
            }
        }
    }, (msg_4_5, deps_2_4, model_4_3) => {
        const patternInput_2_4 = config_7.UpdateInbound(msg_4_5, deps_2_4, model_4_3.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_4[0], model_4_3.Initialized), patternInput_2_4[1]];
    }, key_13, ofList(singleton([singleton(key_13), singleton(0)])))))))), new Internal_StateTreeRoot$6((indexPath_3, deps_7, maybeCachedModel_5, _arg1_5) => {
        const maybeCachedCurrentModel_1 = bind((cachedModel_3) => cachedModel_3.MaybeCurrentModel, maybeCachedModel_5);
        const maybeCachedChildModel_1 = bind((cachedModel_1_1) => cachedModel_1_1.MaybeChildModel, maybeCachedModel_5);
        const matchValue_10 = [indexPath_3, maybeCachedCurrentModel_1];
        let pattern_matching_result_11, currentModel_1_1, xs_8;
        if (!isEmpty(matchValue_10[0])) {
            if (head(matchValue_10[0]) === 0) {
                if (isEmpty(tail(matchValue_10[0]))) {
                    pattern_matching_result_11 = 0;
                }
                else if (matchValue_10[1] != null) {
                    pattern_matching_result_11 = 1;
                    currentModel_1_1 = matchValue_10[1];
                    xs_8 = tail(matchValue_10[0]);
                }
                else {
                    pattern_matching_result_11 = 0;
                }
            }
            else {
                pattern_matching_result_11 = 2;
            }
        }
        else {
            pattern_matching_result_11 = 2;
        }
        switch (pattern_matching_result_11) {
            case 0: {
                const patternInput_15 = current_15.Init(indexPath_3, deps_7, maybeCachedCurrentModel_1, false);
                return [new Internal_BranchModelChild$2(patternInput_15[0], keepCachedChildren_3 ? maybeCachedChildModel_1 : (void 0)), Cmd_map((arg0_21) => (new Internal_BranchMsgChild$2(0, arg0_21)), patternInput_15[1])];
            }
            case 1: {
                const patternInput_1_5 = child_4.Init(xs_8, currentModel_1_1.NodeModel, maybeCachedChildModel_1, keepCachedChildren_3);
                return [new Internal_BranchModelChild$2(currentModel_1_1, patternInput_1_5[0]), Cmd_map((arg0_2_2) => (new Internal_BranchMsgChild$2(1, arg0_2_2)), patternInput_1_5[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_5, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1_1, dispatch_5, model_9) => {
        let pattern_matching_result_12, xs_1_1;
        if (!isEmpty(indexPath_1_1)) {
            if (head(indexPath_1_1) === 0) {
                if (isEmpty(tail(indexPath_1_1))) {
                    pattern_matching_result_12 = 0;
                }
                else {
                    pattern_matching_result_12 = 1;
                    xs_1_1 = tail(indexPath_1_1);
                }
            }
            else {
                pattern_matching_result_12 = 2;
            }
        }
        else {
            pattern_matching_result_12 = 2;
        }
        switch (pattern_matching_result_12) {
            case 0: {
                return defaultArg(map_1(partialApply(1, current_15.View, [indexPath_1_1, (arg_4) => {
                    dispatch_5(new Internal_BranchMsgChild$2(0, arg_4));
                }]), model_9.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children_2 = toList(delay(() => append(config_6.VisibleToChildren ? singleton_1(defaultArg(map_1(partialApply(1, current_15.View, [indexPath_1_1, (arg_5) => {
                    dispatch_5(new Internal_BranchMsgChild$2(0, arg_5));
                }]), model_9.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map_1(partialApply(1, child_4.View, [xs_1_1, (arg_6) => {
                    dispatch_5(new Internal_BranchMsgChild$2(1, arg_6));
                }]), model_9.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children_2);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2_1, branchMsg_1, pass_5, deps_1_5, model_1_6) => {
        let newNodeModel_1;
        const matchValue_1_2 = [branchMsg_1, indexPath_2_1];
        let pattern_matching_result_13, msg_19, xs_2_1, msg_1_6, xs_3_1;
        if (matchValue_1_2[0].tag === 1) {
            if (!isEmpty(matchValue_1_2[1])) {
                if (head(matchValue_1_2[1]) === 0) {
                    pattern_matching_result_13 = 1;
                    msg_1_6 = matchValue_1_2[0].fields[0];
                    xs_3_1 = tail(matchValue_1_2[1]);
                }
                else {
                    pattern_matching_result_13 = 2;
                }
            }
            else {
                pattern_matching_result_13 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_2[1])) {
            if (head(matchValue_1_2[1]) === 0) {
                pattern_matching_result_13 = 0;
                msg_19 = matchValue_1_2[0].fields[0];
                xs_2_1 = tail(matchValue_1_2[1]);
            }
            else {
                pattern_matching_result_13 = 2;
            }
        }
        else {
            pattern_matching_result_13 = 2;
        }
        switch (pattern_matching_result_13) {
            case 0: {
                const matchValue_2_2 = model_1_6.MaybeCurrentModel;
                if (matchValue_2_2 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_6, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2_1 = matchValue_2_2;
                    const patternInput_2_5 = current_15.Update(indexPath_2_1, msg_19, pass_5, deps_1_5, currentModel_2_1);
                    const newCurrentModel_3 = patternInput_2_5[0];
                    const maybeOutboundMsg_3 = patternInput_2_5[3];
                    const maybeNavMsg_3 = patternInput_2_5[2];
                    const currentCmd_1_1 = patternInput_2_5[1];
                    let pattern_matching_result_14;
                    if (msg_19.tag === 2) {
                        if (!equals(xs_2_1, empty())) {
                            pattern_matching_result_14 = 0;
                        }
                        else {
                            pattern_matching_result_14 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_14 = 1;
                    }
                    switch (pattern_matching_result_14) {
                        case 0: {
                            const patternInput_3_2 = child_4.Init(xs_2_1, currentModel_2_1.NodeModel, model_1_6.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, patternInput_3_2[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_1) => (new Internal_BranchMsgChild$2(0, arg0_8_1)), currentCmd_1_1), Cmd_map((arg0_9_1) => (new Internal_BranchMsgChild$2(1, arg0_9_1)), patternInput_3_2[1])])), maybeNavMsg_3, maybeOutboundMsg_3];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, model_1_6.MaybeChildModel), Cmd_map((arg0_11_1) => (new Internal_BranchMsgChild$2(0, arg0_11_1)), currentCmd_1_1), maybeNavMsg_3, maybeOutboundMsg_3];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3_2 = [model_1_6.MaybeCurrentModel, model_1_6.MaybeChildModel];
                let pattern_matching_result_15, childModel_1_1, currentModel_3_1;
                if (matchValue_3_2[0] != null) {
                    if (matchValue_3_2[1] != null) {
                        pattern_matching_result_15 = 0;
                        childModel_1_1 = matchValue_3_2[1];
                        currentModel_3_1 = matchValue_3_2[0];
                    }
                    else {
                        pattern_matching_result_15 = 1;
                    }
                }
                else {
                    pattern_matching_result_15 = 1;
                }
                switch (pattern_matching_result_15) {
                    case 0: {
                        const patternInput_4_2 = child_4.Update(xs_3_1, msg_1_6, pass_5, currentModel_3_1.NodeModel, childModel_1_1);
                        const maybeNavMsg_1_1 = patternInput_4_2[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1_1 != null) ? ((maybeNavMsg_1_1.tag === 2) ? ((newNodeModel_1 = maybeNavMsg_1_1.fields[0], map_1((model_2_6) => (new Internal_StateTreeModel$1(newNodeModel_1, model_2_6.Initialized)), model_1_6.MaybeCurrentModel))) : model_1_6.MaybeCurrentModel) : model_1_6.MaybeCurrentModel, patternInput_4_2[0]), Cmd_map((arg0_13_1) => (new Internal_BranchMsgChild$2(1, arg0_13_1)), patternInput_4_2[1]), map_1(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_1), patternInput_4_2[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_6, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_6, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_6, deps_2_5, model_3_5) => {
        let patternInput_6_1;
        const matchValue_4_2 = model_3_5.MaybeCurrentModel;
        if (matchValue_4_2 == null) {
            patternInput_6_1 = [model_3_5.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5_1 = current_15.UpdateInbound(msg_2_6, deps_2_5, matchValue_4_2);
            patternInput_6_1 = [patternInput_5_1[0], patternInput_5_1[1]];
        }
        let patternInput_8_1;
        const matchValue_5_2 = [model_3_5.MaybeChildModel, model_3_5.MaybeCurrentModel];
        let pattern_matching_result_16, childModel_2_1, currentModel_5_1;
        if (matchValue_5_2[0] != null) {
            if (matchValue_5_2[1] != null) {
                pattern_matching_result_16 = 0;
                childModel_2_1 = matchValue_5_2[0];
                currentModel_5_1 = matchValue_5_2[1];
            }
            else {
                pattern_matching_result_16 = 1;
            }
        }
        else {
            pattern_matching_result_16 = 1;
        }
        switch (pattern_matching_result_16) {
            case 0: {
                const patternInput_7_1 = child_4.UpdateInbound(msg_2_6, currentModel_5_1.NodeModel, childModel_2_1);
                patternInput_8_1 = [patternInput_7_1[0], patternInput_7_1[1]];
                break;
            }
            case 1: {
                patternInput_8_1 = [model_3_5.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6_1[0], patternInput_8_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14_1) => (new Internal_BranchMsgChild$2(0, arg0_14_1)), patternInput_6_1[1]), Cmd_map((arg0_15_1) => (new Internal_BranchMsgChild$2(1, arg0_15_1)), patternInput_8_1[1])]))];
    }, current_15.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_15.LastKey, child_4.IndexPathFromKeyPath, current_15.IndexPathFromKeyPath))))))));
}

