import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class SiteViewModel extends Record {
    constructor(siteId, accountId, name, number, address) {
        super();
        this.siteId = siteId;
        this.accountId = accountId;
        this.name = name;
        this.number = number;
        this.address = address;
    }
}

export function SiteViewModel$reflection() {
    return record_type("Optiscan.Models.View.Site.SiteViewModel", [], SiteViewModel, () => [["siteId", class_type("System.Guid")], ["accountId", class_type("System.Guid")], ["name", string_type], ["number", string_type], ["address", string_type]]);
}

export function SiteViewModel_get_empty() {
    return new SiteViewModel("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "", "", "");
}

export function SiteViewModel_get_siteId_() {
    return [(m) => m.siteId, (v) => ((m_1) => (new SiteViewModel(v, m_1.accountId, m_1.name, m_1.number, m_1.address)))];
}

export function SiteViewModel_get_accountId_() {
    return [(m) => m.accountId, (v) => ((m_1) => (new SiteViewModel(m_1.siteId, v, m_1.name, m_1.number, m_1.address)))];
}

export function SiteViewModel_get_name_() {
    return [(m) => m.name, (v) => ((m_1) => (new SiteViewModel(m_1.siteId, m_1.accountId, v, m_1.number, m_1.address)))];
}

export function SiteViewModel_get_number_() {
    return [(m) => m.number, (v) => ((m_1) => (new SiteViewModel(m_1.siteId, m_1.accountId, m_1.name, v, m_1.address)))];
}

export function SiteViewModel_get_address_() {
    return [(m) => m.address, (v) => ((m_1) => (new SiteViewModel(m_1.siteId, m_1.accountId, m_1.name, m_1.number, v)))];
}

