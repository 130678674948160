import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { lambda_type, union_type, record_type, bool_type, list_type, option_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { SiteViewModel$reflection } from "../Optiscan.Models/View/Site.js";
import { ScheduledSessionViewModel$reflection } from "../Optiscan.Models/View/Session.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";

export class Model extends Record {
    constructor(maybePatientId, maybeSessionId, sites, authorizedSites, loadingSessions, scheduledSessions) {
        super();
        this.maybePatientId = maybePatientId;
        this.maybeSessionId = maybeSessionId;
        this.sites = sites;
        this.authorizedSites = authorizedSites;
        this.loadingSessions = loadingSessions;
        this.scheduledSessions = scheduledSessions;
    }
}

export function Model$reflection() {
    return record_type("Client.Dashboard.Types.Model", [], Model, () => [["maybePatientId", option_type(class_type("System.Guid"))], ["maybeSessionId", option_type(class_type("System.Guid"))], ["sites", list_type(SiteViewModel$reflection())], ["authorizedSites", list_type(SiteViewModel$reflection())], ["loadingSessions", bool_type], ["scheduledSessions", list_type(ScheduledSessionViewModel$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "NavigateToPatient", "GetSitesResult", "GetAuthorizedSites", "GetAuthorizedSitesResult", "GetScheduledSessions", "GetScheduledSessionsResult", "SetValue"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Dashboard.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", ScheduledSessionViewModel$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["siteId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ScheduledSessionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(ScheduledSessionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["setter", lambda_type(Model$reflection(), Model$reflection())]]]);
}

