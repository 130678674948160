import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { array_type, record_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class GuestSignInRequest extends Record {
    constructor(guestLinkCode, name, password) {
        super();
        this.guestLinkCode = guestLinkCode;
        this.name = name;
        this.password = password;
    }
}

export function GuestSignInRequest$reflection() {
    return record_type("Optiscan.Models.View.GuestLink.GuestSignInRequest", [], GuestSignInRequest, () => [["guestLinkCode", class_type("System.Guid")], ["name", string_type], ["password", string_type]]);
}

export class GuestLinkRequest extends Record {
    constructor(sessionId, password, guestEmails) {
        super();
        this.sessionId = sessionId;
        this.password = password;
        this.guestEmails = guestEmails;
    }
}

export function GuestLinkRequest$reflection() {
    return record_type("Optiscan.Models.View.GuestLink.GuestLinkRequest", [], GuestLinkRequest, () => [["sessionId", class_type("System.Guid")], ["password", string_type], ["guestEmails", array_type(string_type)]]);
}

export class GuestLinkResponse extends Record {
    constructor(guestLink) {
        super();
        this.guestLink = guestLink;
    }
}

export function GuestLinkResponse$reflection() {
    return record_type("Optiscan.Models.View.GuestLink.GuestLinkResponse", [], GuestLinkResponse, () => [["guestLink", string_type]]);
}

