import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { lambda_type, unit_type, union_type, class_type, int32_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class MenuProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Align", "Direction", "Gap", "MenuButton"];
    }
}

export function MenuProps$reflection() {
    return union_type("Optiscan.SharedUI.Bindings.ReactMenuBind.MenuProps", [], MenuProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", class_type("Fable.React.ReactElement")]]]);
}

export class SubMenuProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Label", "Key"];
    }
}

export function SubMenuProps$reflection() {
    return union_type("Optiscan.SharedUI.Bindings.ReactMenuBind.SubMenuProps", [], SubMenuProps, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class MenuItemProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Key", "OnClick"];
    }
}

export function MenuItemProps$reflection() {
    return union_type("Optiscan.SharedUI.Bindings.ReactMenuBind.MenuItemProps", [], MenuItemProps, () => [[["Item", string_type]], [["Item", lambda_type(unit_type, unit_type)]]]);
}

